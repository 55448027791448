import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  top: 80px;
`;

export const EventsSection = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 2rem 1rem;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 4rem var(--page-lateral-padding);
`;
