import { Star } from 'phosphor-react';
import React from 'react'
import { Divider } from '../../../components/Divider';
import { Button, Container, ImageContainer, Info } from './style';


interface InspiringCardProps {
  picture: string;
  name: string;
  role: string;
  story: string;
  route: string;
}

export function InspiringCard({name, role, picture, story, route}: InspiringCardProps) {
  return (
    <Container>
      <header>
        <ImageContainer image={picture}>
        </ImageContainer>
        <Info>
          <p className="name">{name}</p>
          <p className="role">{role}</p>
        </Info>
      </header>
      <Divider color="orange" />
      <main>
        <p>{story}</p>
        {/* <Button>
          Saiba Mais <Star weight="fill" size={24} />
        </Button> */}
      </main>
    </Container>
  );
}