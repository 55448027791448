import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem var(--page-lateral-padding) 0;

  p {
    display: flex;
    align-items: center;
    color: var(--light-grey);
    font-weight: bold;

    svg {
      margin-right: 5px;
    }
  }

  @media (max-width: 768px) {
    padding: 2rem var(--page-lateral-padding);
  }
`;


export const Options = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  span {
    color: var(--light-grey);
    font-weight: 600;
    letter-spacing: 1px;
    border: 2px solid var(--light-grey);
    border-radius: 99px;
    padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    cursor: pointer;
    
    &.active {
      background: var(--light-grey);
      color: #fff;
    }

  }
`;