import {
  SyCardContent,
  SyCardFooter,
  SyCardHeader,
} from "@sydle/sydle-ui-react";
import { Buildings, Clock, House, MapPin, Money } from "phosphor-react";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import sendRequest from "../../services/sendRequest";
import {
  Container,
  Filters,
  OpportunitiesCards,
  StyledSyCard,
  StyledSyPaginator,
  StyledSySelect,
} from "./style";
import AuthContext from "../../contexts/Auth/AuthContext";
import { toast } from "react-toastify";

interface OpportunitiesDataProps {
  title: string;
  description: string;
  enterprises: string[];
  jobs: string[];
  locations: string[];
}

interface OpportunitiesProps {
  total: number;
  opportunitiesArray: {
    id: string;
    name: string;
    quantity: number;
    location: string;
    company: string;
    isRemote: boolean;
    timeLeft: number;
  }[];
}

const ITEMS_PER_PAGE = 8;

export function Opportunities() {
  const [opportunitiesData, setOpportunitiesData] =
    useState<OpportunitiesDataProps>();
  const [opportunities, setOpportunities] = useState<OpportunitiesProps>(); // * Objeto com total de oportunidades e array com 8 oportunidades
  const [enterprisesFilter, setEnterprisesFilter] = useState<Object>([]);
  const [jobsFilter, setJobsFilter] = useState<Object>([]);
  const [locationsFilter, setLocationsFilter] = useState<Object>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setIsAuthModalOpen } = useContext(AuthContext);
  const isMountedRef = useRef(false);

  const navigate = useNavigate();

  function logged() {
    const isLogged = localStorage.getItem("@bebacknow:accessToken") || false;
    if (!isLogged) {
      navigate("/");
      setIsAuthModalOpen(true);
      toast.error("Para ter acesso as oportunidades por favor faça o login.", {
        toastId: "opportunities-error",
      });
    }
  }

  useEffect(() => {
    logged();
  }, []);

  // garante que a página vá para o topo
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setIsLoading(true);
    async function fetchOpportunitiesData() {
      await sendRequest
        .getOpportunities({})
        .then((response) => {
          setOpportunitiesData(response);
        })
        .catch((err) => {
          console.log(`Error trying to fetch Opportunities Data: ${err}`);
        });
    }
    fetchOpportunitiesData();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    async function fetchOpportunitiesArray() {
      await sendRequest
        .getOpportunitiesArray({
          enterprises: enterprisesFilter,
          jobs: jobsFilter,
          locations: locationsFilter,
          currentPage: currentPage,
          itemsPerPage: ITEMS_PER_PAGE,
        })
        .then((response) => {
          setOpportunities(response);
        })
        .catch((err) => {
          console.log(`Error trying to fetch Opportunities Array: ${err}`);
        });
    }
    fetchOpportunitiesArray();
  }, [enterprisesFilter, jobsFilter, locationsFilter, currentPage]);

  useEffect(() => {
    setCurrentPage(0);
  }, [enterprisesFilter, jobsFilter, locationsFilter]);

  return (
    <Container>
      <header>
        <h1>{opportunitiesData?.title!}</h1>
        <p>{opportunitiesData?.description!}</p>
      </header>
      <Filters>
        <StyledSySelect
          label="Empresa"
          multiple
          clearButton
          onSySelectDidChange={(e) => {
            setEnterprisesFilter((e.target as HTMLSySelectElement).value!);
          }}
        >
          {opportunitiesData?.enterprises.map((company, index) => {
            return (
              <option key={index} value={company}>
                {company}
              </option>
            );
          })}
        </StyledSySelect>
        <StyledSySelect
          label="Cargo"
          multiple
          clearButton
          onSySelectDidChange={(e) => {
            setJobsFilter((e.target as HTMLSySelectElement).value!);
          }}
        >
          {opportunitiesData?.jobs.map((job, index) => {
            return (
              <option key={index} value={job}>
                {job}
              </option>
            );
          })}
        </StyledSySelect>
        <StyledSySelect
          label="Localização"
          multiple
          clearButton
          onSySelectDidChange={(e) => {
            setLocationsFilter((e.target as HTMLSySelectElement).value!);
          }}
        >
          {opportunitiesData?.locations.map((city, index) => {
            return (
              <option key={index} value={city}>
                {city}
              </option>
            );
          })}
        </StyledSySelect>
      </Filters>
      <OpportunitiesCards isEmpty={opportunities?.total}>
        {isLoading ? (
          <StyledSyCard>
            <SyCardHeader>
              <p>carregando</p>
              <small>carregando</small>
            </SyCardHeader>
            <SyCardContent>
              <p>
                <MapPin /> carregando
              </p>
              <p>
                <Buildings /> carregando
              </p>
              <p>
                <Money />{" "}
                {Intl.NumberFormat("pt", {
                  style: "currency",
                  currency: "BRL",
                }).format(3000)}{" "}
                -{" "}
                {Intl.NumberFormat("pt", {
                  style: "currency",
                  currency: "BRL",
                }).format(3000)}
              </p>
            </SyCardContent>
            <SyCardFooter>
              <Clock size="1rem" />
              <span>carregando</span>
            </SyCardFooter>
          </StyledSyCard>
        ) : opportunities?.total === 0 ? (
          <p>Não há oportunidades abertas</p>
        ) : (
          opportunities?.opportunitiesArray.map((opportunity) => {
            return (
              // // * PAGINAÇÃO NO FRONT
              // index >= currentPage * ITEMS_PER_PAGE &&
              // index < currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE &&
              <StyledSyCard
                key={opportunity.id}
                onClick={() => navigate(`/opportunity/${opportunity.id}`)}
              >
                <SyCardHeader>
                  <p>{opportunity.name}</p>
                  <small>
                    (
                    {opportunity.quantity === 1
                      ? `${opportunity.quantity} vaga`
                      : `${opportunity.quantity} vagas`}
                    )
                  </small>
                </SyCardHeader>
                <SyCardContent>
                  <p>
                    <Buildings /> {opportunity.company}
                  </p>
                  <p>
                    <MapPin /> {opportunity.location}
                  </p>
                  <p>
                    <House />{" "}
                    {opportunity.isRemote === true ? "Remoto" : "Presencial"}
                  </p>
                </SyCardContent>
                <SyCardFooter>
                  <Clock size="1rem" />
                  <span>
                    Esta vaga encerra-se em{" "}
                    {opportunity.timeLeft === 1
                      ? `${opportunity.timeLeft} dia`
                      : `${opportunity.timeLeft} dias`}
                  </span>
                </SyCardFooter>
              </StyledSyCard>
            );
          })
        )}
      </OpportunitiesCards>
      <StyledSyPaginator
        length={Math.ceil(opportunities?.total! / ITEMS_PER_PAGE)}
        index={currentPage + 1}
        onSyPaginatorPageDidChange={(e) =>
          setCurrentPage((e.target as HTMLSyPaginatorElement).index - 1)
        }
      />
    </Container>
  );
}
