import axios from 'axios';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_SYDLE_ONE_ENDPOINT}${process.env.REACT_APP_SYDLE_ONE_API}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_SYDLE_ONE_SITE_TOKEN}`,
    "Content-Type": "application/json",
  },
  maxContentLength: Infinity,
  maxBodyLength: Infinity
});