export const htmlData = '' + 
'<h1>Pol&iacute;tica de Privacidade</h1>' + 
'' + 
'<p>Esta Pol&iacute;tica de Privacidade demonstra o compromisso que a Be Back Now tem com a seguran&ccedil;a e a privacidade das informa&ccedil;&otilde;es coletadas dos usu&aacute;rios de nossos servi&ccedil;os. O p&uacute;blico em geral pode visitar este website e conhecer os servi&ccedil;os que oferecemos, obter informa&ccedil;&otilde;es e not&iacute;cias, sem fornecer informa&ccedil;&otilde;es pessoais. Recomenda-se uma verifica&ccedil;&atilde;o peri&oacute;dica desta pol&iacute;tica, que pode estar sujeita a altera&ccedil;&otilde;es sem pr&eacute;vio aviso.</p>' + 
'' + 
'<p>Esta Pol&iacute;tica de Privacidade destina-se a ajudar voc&ecirc; a entender quais informa&ccedil;&otilde;es coletamos, por qual motivo as coletamos, como as utilizamos, se as compartilhamos e com quem, al&eacute;m de informar seus direitos relativos a essas informa&ccedil;&otilde;es e como exerc&ecirc;-los.</p>' + 
'' + 
'<p>Quando voc&ecirc; contrata os nossos servi&ccedil;os, acessa nosso website, ou se candidata para alguma vaga conosco, est&aacute; confiando a n&oacute;s informa&ccedil;&otilde;es a seu respeito. Entendemos que isso &eacute; uma grande responsabilidade e dedicamos os melhores esfor&ccedil;os para proteger essas informa&ccedil;&otilde;es e garantir a sua privacidade.</p>' + 
'' + 
'<p>Esta Pol&iacute;tica de Privacidade aplica-se aos dados pessoais coletados atrav&eacute;s do Website ou com a finalidade de estabelecermos uma rela&ccedil;&atilde;o comercial para presta&ccedil;&atilde;o de nossos servi&ccedil;os, ou ainda para envolv&ecirc;-lo nos processos seletivos como candidatas a uma vaga. Em caso de d&uacute;vidas, disponibilizamos o canal de contato contato@bebacknow.com.</p>' + 
'' + 
'<h2>1. Dados que s&atilde;o protegidos pela LGPD.</h2>' + 
'' + 
'<p>A Lei Geral de Prote&ccedil;&atilde;o de Dados - LGPD (Lei n&ordm; 13.709/2018) veio para proteger os dados pessoais e dados pessoais sens&iacute;veis da pessoa f&iacute;sica no Brasil, tamb&eacute;m chamada de titular de dados.</p>' + 
'' + 
'<p>De acordo com a LGPD, &ldquo;dado pessoal&rdquo; &eacute; qualquer dado que identifique ou torne identific&aacute;vel uma pessoa f&iacute;sica. E o &ldquo;dado pessoal sens&iacute;vel&rdquo; &eacute; aquele que, por sua natureza, diz respeito a informa&ccedil;&otilde;es que merecem um cuidado maior, principalmente para prote&ccedil;&atilde;o contra discrimina&ccedil;&otilde;es.</p>' + 
'' + 
'<p>A LGPD coloca nessa classifica&ccedil;&atilde;o de dados pessoais sens&iacute;veis dados sobre origem racial ou &eacute;tnica, convic&ccedil;&atilde;o religiosa, opini&atilde;o pol&iacute;tica, filia&ccedil;&atilde;o a sindicato ou a organiza&ccedil;&atilde;o de car&aacute;ter religioso, filos&oacute;fico ou pol&iacute;tico, dado referente &agrave; sa&uacute;de ou &agrave; vida sexual e dado gen&eacute;tico ou biom&eacute;trico, quando vinculados a uma pessoa f&iacute;sica.</p>' + 
'' + 
'<p>A Be Back Now utilizar&aacute; informa&ccedil;&otilde;es pessoais de suas clientes de maneira legal e adequada conforme necessidade dos seus neg&oacute;cios. Podemos coletar os seguintes dados, a depender da sua rela&ccedil;&atilde;o conosco:</p>' + 
'' + 
'<ul>' + 
'	<li>Nome, data de nascimento, endere&ccedil;o residencial, CPF; e-mail, telefones de contato;</li>' + 
'	<li>Dados de identifica&ccedil;&atilde;o, de perfil, documentos pessoais e informa&ccedil;&otilde;es de contato;</li>' + 
'	<li>Dados necess&aacute;rios para elabora&ccedil;&atilde;o de curr&iacute;culo profissional;</li>' + 
'	<li>Dados relativos a vagas inclusivas e para PcD;</li>' + 
'	<li>Foto profissional;</li>' + 
'	<li>Links para redes sociais, de relacionamento ou blogs do candidato;</li>' + 
'	<li>Objetivo profissional, inclusive para cria&ccedil;&atilde;o de alertas de vagas;</li>' + 
'	<li>Campos de anota&ccedil;&otilde;es, testes e fichas complementares, preenchidos pelo candidato;</li>' + 
'	<li>Dados de geolocaliza&ccedil;&atilde;o e de identifica&ccedil;&atilde;o do aparelho.</li>' + 
'</ul>' + 
'' + 
'<p>Ao realizar o cadastro, &eacute; importante que voc&ecirc;, ou a pessoa autorizada por voc&ecirc; para preencher os seus dados pessoais, insira apenas informa&ccedil;&otilde;es verdadeiras e atualizadas, j&aacute; que n&atilde;o nos responsabilizamos pela verifica&ccedil;&atilde;o. A responsabilidade, em qualquer caso, civil e/ou criminal, &eacute; sua quanto &agrave; veracidade, exatid&atilde;o e autenticidade das informa&ccedil;&otilde;es inclu&iacute;das em nosso cadastro.</p>' + 
'' + 
'<h2>2. Como coletamos os seus dados pessoais?</h2>' + 
'' + 
'<p>Os dados pessoais que possu&iacute;mos sobre voc&ecirc; podem nos ter sido informados diretamente por voc&ecirc;, por terceiros ou podemos coletar alguns dados que est&atilde;o dispon&iacute;veis publicamente.</p>' + 
'' + 
'<h3>2.1. Informa&ccedil;&otilde;es fornecidas diretamente por voc&ecirc;</h3>' + 
'' + 
'<p>Durante o seu relacionamento conosco, desde a cota&ccedil;&atilde;o de um de nossos servi&ccedil;os at&eacute; a sua contrata&ccedil;&atilde;o, voc&ecirc; compartilha dados pessoais conosco, os quais utilizamos para as finalidades que lhe s&atilde;o informadas.</p>' + 
'' + 
'<p>Os titulares ser&atilde;o avisados sobre quais informa&ccedil;&otilde;es suas estar&atilde;o sendo coletadas antes da coleta, ficando a crit&eacute;rio deste a op&ccedil;&atilde;o de escolha para fornecimento ou n&atilde;o dessas informa&ccedil;&otilde;es, o qual tamb&eacute;m ter&aacute; ci&ecirc;ncia das restri&ccedil;&otilde;es mediante a sua decis&atilde;o.</p>' + 
'' + 
'<h3>2.2. Informa&ccedil;&otilde;es dispon&iacute;veis publicamente</h3>' + 
'' + 
'<p>Tamb&eacute;m coletamos informa&ccedil;&otilde;es suas disponibilizadas publicamente, tais como informa&ccedil;&otilde;es fornecidas por portais de divulga&ccedil;&atilde;o de perfis profissionais.</p>' + 
'' + 
'<h2>3. Como utilizamos os seus dados pessoais?</h2>' + 
'' + 
'<p>O acesso &agrave;s informa&ccedil;&otilde;es coletadas est&aacute; restrito apenas a colaboradores e prepostos da Be Back Now, previamente autorizados para o uso adequado dessas informa&ccedil;&otilde;es. Os colaboradores ou prepostos que se utilizarem indevidamente dessas informa&ccedil;&otilde;es, ferindo esta Pol&iacute;tica de Privacidade, estar&atilde;o sujeitos &agrave;s penalidades do processo disciplinar de nossa Companhia.</p>' + 
'' + 
'<p>Utilizamos os seus dados pessoais para os seguintes prop&oacute;sitos:</p>' + 
'' + 
'<h3>3.1. Atender a finalidade para a qual o dado foi fornecido</h3>' + 
'' + 
'<p>N&oacute;s podemos utilizar os dados que voc&ecirc; nos forneceu para cumprir a finalidade informada no momento da coleta. Podemos usar seus dados de identifica&ccedil;&atilde;o e contato para permitir a execu&ccedil;&atilde;o do contrato que firmamos com nossos clientes, como por exemplo para convid&aacute;-lo a participar de um processo seletivo, ou para realizar sua inscri&ccedil;&atilde;o em um de nossos cursos.</p>' + 
'' + 
'<p>Em particular, no caso dos dados pessoais que s&atilde;o coletados das Candidatas que participam dos processos seletivos que a Be Back Now conduz para seus clientes, incluindo os seus curr&iacute;culos, estes s&atilde;o utilizados &uacute;nica e exclusivamente para dar suporte ao seu processo de sele&ccedil;&atilde;o. Neste caso, os dados pessoais que s&atilde;o compartilhados com o cliente se restringem &agrave;s informa&ccedil;&otilde;es necess&aacute;rias para avalia&ccedil;&atilde;o da Candidata, como nome, endere&ccedil;o de resid&ecirc;ncia, forma&ccedil;&atilde;o acad&ecirc;mica e hist&oacute;rico profissional.</p>' + 
'' + 
'<p>De uma forma mais detalhada, utilizamos as informa&ccedil;&otilde;es e os seus dados pessoais coletados para cumprir as seguintes finalidades:</p>' + 
'' + 
'<ol>' + 
'	<li>Viabilizar sua participa&ccedil;&atilde;o em processos seletivos de trabalho;</li>' + 
'	<li>Incluir seus dados no banco de talentos das empresas em cujos processos seletivos voc&ecirc; se inscreveu;</li>' + 
'	<li>Entrar em contato com voc&ecirc;, em raz&atilde;o de andamento do processo seletivo de que voc&ecirc; esteja participando ou para inform&aacute;-lo sobre novos processos que possam ser de seu interesse, incluindo o envio de alertas de vagas;</li>' + 
'	<li>Permitir que empresas busquem ativamente candidatos aderentes &agrave;s vagas abertas dentro do banco de dados da Be Back Now, de acordo com o n&iacute;vel de privacidade escolhido por voc&ecirc; para o seu curr&iacute;culo;</li>' + 
'	<li>De forma opcional, quando autorizado pelo por voc&ecirc;, para o envio de informativos, comunicados institucionais e newsletter, inclusive sobre produtos e servi&ccedil;os oferecidos pela Be Back Now e seus parceiros.</li>' + 
'</ol>' + 
'' + 
'<h3>3.2. Cumprir com obriga&ccedil;&otilde;es legais ou regulat&oacute;rias</h3>' + 
'' + 
'<p>Seus dados pessoais poder&atilde;o ser utilizados para o atendimento de obriga&ccedil;&otilde;es dispostas em lei, regula&ccedil;&otilde;es de &oacute;rg&atilde;os governamentais, autoridades fiscais, Poder Judici&aacute;rio e/ou outra autoridade competente. Este tratamento poder&aacute; incluir seus dados de identifica&ccedil;&atilde;o, documentos pessoais e dados banc&aacute;rios, por exemplo, quando do envio das comunica&ccedil;&otilde;es obrigat&oacute;rias &agrave; Receita Federal.</p>' + 
'' + 
'<h3>3.3. Permitir o exerc&iacute;cio regular de nossos direitos</h3>' + 
'' + 
'<p>Mesmo ap&oacute;s o t&eacute;rmino da rela&ccedil;&atilde;o contratual, n&oacute;s poderemos tratar alguns de seus dados pessoais para exercer nossos direitos garantidos em lei, inclusive como prova em processos judiciais, administrativos ou arbitrais.</p>' + 
'' + 
'<h3>3.4. Viabilizar as atividades necess&aacute;rias para a nossa opera&ccedil;&atilde;o</h3>' + 
'' + 
'<p>Podemos tratar os dados, tamb&eacute;m, para finalidades leg&iacute;timas envolvendo a continuidade de nossas opera&ccedil;&otilde;es, sempre observando os limites da sua expectativa e nunca em preju&iacute;zo de seus interesses, direitos e liberdades fundamentais.</p>' + 
'' + 
'<p>Seus dados podem ser considerados, por exemplo, em estudos internos sobre a utiliza&ccedil;&atilde;o de nossos produtos ou dos principais motivos de contato, para permitir uma melhor distribui&ccedil;&atilde;o e aloca&ccedil;&atilde;o de recursos internos, ou ainda para mensurar a qualidade de nosso atendimento e seu n&iacute;vel de satisfa&ccedil;&atilde;o com a nossa presta&ccedil;&atilde;o de servi&ccedil;os.</p>' + 
'' + 
'<h3>3.5. Promover nossas atividades e ampliar as ofertas de comercializa&ccedil;&atilde;o de nossos servi&ccedil;os</h3>' + 
'' + 
'<p>Adicionalmente, podemos utilizar suas informa&ccedil;&otilde;es de contato para o envio de comunica&ccedil;&otilde;es publicit&aacute;rias, not&iacute;cias, ofertas e promo&ccedil;&otilde;es que sejam de seu interesse. Neste caso, solicitaremos seu consentimento pr&eacute;vio para uso de suas informa&ccedil;&otilde;es para essa finalidade.</p>' + 
'' + 
'<p>Caso voc&ecirc; se sinta incomodado e n&atilde;o deseje mais receber quaisquer informativos publicit&aacute;rios, voc&ecirc; pode, a qualquer momento, na pr&oacute;pria comunica&ccedil;&atilde;o publicit&aacute;ria escolher op&ccedil;&atilde;o de sair da lista de recebimento.</p>' + 
'' + 
'<h3>3.6. Evitar fraudes e zelar pela sua seguran&ccedil;a</h3>' + 
'' + 
'<p>Eventualmente, quando estritamente necess&aacute;rio, poderemos tratar os seus dados para comprovar que voc&ecirc; &eacute; realmente quem diz ser e evitar fraudes.</p>' + 
'' + 
'<h2>4. Com quem compartilhamos os seus dados?</h2>' + 
'' + 
'<p>A Be Back Now, no contexto de recrutamento e sele&ccedil;&atilde;o, permite que o candidato insira informa&ccedil;&otilde;es e dados pessoais, inclusive considerados sens&iacute;veis, tais como fotos, v&iacute;deos, exames psicol&oacute;gicos, exames psicot&eacute;cnicos, atestados m&eacute;dicos, relat&oacute;rios sobre necessidades especiais para processos seletivos de Pessoas com Defici&ecirc;ncia (PcD), informa&ccedil;&otilde;es para participa&ccedil;&atilde;o em processos seletivos de vagas inclusivas, dentre outros, conforme determinado em lei e previsto no artigo 5&ordm;, II da LGPD.</p>' + 
'' + 
'<p>A inser&ccedil;&atilde;o dessas informa&ccedil;&otilde;es n&atilde;o &eacute; obrigat&oacute;ria e ser&aacute; sempre facultativa ao candidato. O seu compartilhamento com as empresas para processos de sele&ccedil;&atilde;o dever&aacute; ser feito apenas para participa&ccedil;&atilde;o em processos seletivos, mediante autoriza&ccedil;&atilde;o do candidato e nunca para finalidades discriminat&oacute;rias.</p>' + 
'' + 
'<p>N&oacute;s compartilhamos os seus dados pessoais com &oacute;rg&atilde;os governamentais, poder judici&aacute;rio, parceiros de neg&oacute;cio, fornecedores de servi&ccedil;o e/ou infraestrutura, dentre outros.</p>' + 
'' + 
'<p>Ainda, compartilhamentos podem acontecer em caso de opera&ccedil;&otilde;es societ&aacute;rias, como parte das negocia&ccedil;&otilde;es, ou qualquer etapa de uma compra, fus&atilde;o, ou aquisi&ccedil;&atilde;o parcial ou total da Be Back Now.</p>' + 
'' + 
'<p>Quando houver o compartilhamento dos seus dados, isso acontecer&aacute; para cumprir alguma finalidade espec&iacute;fica, que com certeza ser&aacute; informada a voc&ecirc;. N&oacute;s utilizamos de instrumentos contratuais e auditorias para assegurar que qualquer terceiro que receba os seus dados pessoais, garanta a eles a prote&ccedil;&atilde;o adequada.</p>' + 
'' + 
'<p>&Eacute; poss&iacute;vel que, eventualmente, o terceiro se encontre fora do Brasil. Nestes casos, nos certificaremos de que essa transfer&ecirc;ncia ocorra apenas para os pa&iacute;ses que possuem um grau de seguran&ccedil;a similar ao previsto pela legisla&ccedil;&atilde;o brasileira, ou quando a Autoridade Nacional de Prote&ccedil;&atilde;o de Dados Pessoais assim autorizar. Quando utilizamos sistemas de armazenamento em nuvem, seus dados tamb&eacute;m podem ser tratados fora do Brasil. Nessas hip&oacute;teses, buscamos escolher os melhores fornecedores internacionais, assim reconhecidos pelo mercado.</p>' + 
'' + 
'<h2>5. Como mantemos a seguran&ccedil;a dos seus dados pessoais?</h2>' + 
'' + 
'<p>Para proteger os dados pessoais coletados e garantir a seguran&ccedil;a dos nossos sistemas, utilizamos tecnologias e procedimentos adequados de acordo com o n&iacute;vel de risco e o servi&ccedil;o fornecido, possuindo uma equipe respons&aacute;vel por gerenci&aacute;-los em conformidade com as previs&otilde;es legais, requisitos regulat&oacute;rios, mudan&ccedil;as de tecnologia, dentre outros fatores relevantes que possam influenciar a prote&ccedil;&atilde;o de dados.</p>' + 
'' + 
'<p>Em raz&atilde;o da pr&oacute;pria natureza da Internet, existe o risco de que terceiros mal-intencionados acessem indevidamente as informa&ccedil;&otilde;es armazenadas nos nossos sistemas. Caso isso ocorra, n&oacute;s nos responsabilizaremos nos limites previstos na legisla&ccedil;&atilde;o vigente aplic&aacute;vel.</p>' + 
'' + 
'<p>Temos pol&iacute;ticas e procedimentos internos que determinam como os dados pessoais devem ser tratados pela Be Back Now, incluindo os seus. Essas normas internas t&ecirc;m como objetivo que seus dados sejam tratados de forma adequada e em observ&acirc;ncia &agrave; legisla&ccedil;&atilde;o.</p>' + 
'' + 
'<p>Nesse contexto, adotamos medidas t&eacute;cnicas aptas a manter os seus dados pessoais seguros e protegidos de acessos n&atilde;o autorizados e de situa&ccedil;&otilde;es acidentais ou il&iacute;citas de destrui&ccedil;&atilde;o, perda, altera&ccedil;&atilde;o, comunica&ccedil;&atilde;o ou qualquer outra forma de tratamento inadequado ou il&iacute;cito, sempre sob as melhores pr&aacute;ticas aplic&aacute;veis de prote&ccedil;&atilde;o de dados e seguran&ccedil;a da informa&ccedil;&atilde;o.</p>' + 
'' + 
'<h2>6. Quais s&atilde;o seus direitos como Titular de dados pessoais e como exerc&ecirc;-los?</h2>' + 
'' + 
'<p>Voc&ecirc; tem os seguintes direitos relativos aos seus dados pessoais:</p>' + 
'' + 
'<ul>' + 
'	<li>Saber quais dados pessoais seus s&atilde;o tratados por n&oacute;s;</li>' + 
'	<li>Corrigir dados incompletos, inexatos ou desatualizados, pelos meios exigidos pela regulamenta&ccedil;&atilde;o espec&iacute;fica, quando necess&aacute;rio;</li>' + 
'	<li>Solicitar a anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o de dados desnecess&aacute;rios, excessivos ou que, eventualmente, tenham sido tratados em desconformidade com a lei;</li>' + 
'	<li>Solicitar a elimina&ccedil;&atilde;o dos dados tratados com o seu consentimento;</li>' + 
'	<li>Obter informa&ccedil;&otilde;es sobre as entidades p&uacute;blicas ou privadas com as quais compartilhamos os seus dados;</li>' + 
'	<li>Quando a atividade de tratamento necessitar do seu consentimento, voc&ecirc; pode se negar a consentir. Nesse caso, informaremos sobre as consequ&ecirc;ncias da n&atilde;o realiza&ccedil;&atilde;o de tal atividade;</li>' + 
'	<li>Quando a atividade de tratamento necessitar do seu consentimento, a qualquer momento voc&ecirc; poder&aacute; revog&aacute;-lo.</li>' + 
'</ul>' + 
'' + 
'<p>Para solicitar a exclusão da sua conta e dos seus dados pessoais, por favor, envie um email para <a href="mailto:contato@bebacknow.com">contato@bebacknow.com</a> com a solicitação de exclusão da conta. Iremos processar sua solicitação de acordo com os termos da nossa Política de Privacidade.</p>' + 
'' + 
'<h2>7. O que s&atilde;o cookies e como os utilizamos?</h2>' + 
'' + 
'<p>Para fins administrativos, a Be Back Now poder&aacute; utilizar &quot;cookies&quot;(*), sendo que o usu&aacute;rio pode, a qualquer instante, ativar em seu navegador mecanismos para inform&aacute;-lo quando os mesmos estiverem sendo acionados, ou para evitar que sejam acionados.</p>' + 
'' + 
'<p>O nosso site utiliza cookies e outras tecnologias semelhantes para armazenar e gerenciar as suas prefer&ecirc;ncias de navega&ccedil;&atilde;o, habilitar conte&uacute;dos e coletar dados de an&aacute;lise e utiliza&ccedil;&atilde;o do site. O uso dessas tecnologias &eacute; comum em sites e plataformas em geral, consistindo em um pequeno arquivo de texto, colocado em seu dispositivo ou navegador, que permite a sua identifica&ccedil;&atilde;o enquanto usu&aacute;rio e o dispositivo utilizado, bem como para coletar as informa&ccedil;&otilde;es de navega&ccedil;&atilde;o.</p>' + 
'' + 
'<p>Os cookies que utilizamos podem desempenhar diferentes fun&ccedil;&otilde;es. Alguns s&atilde;o necess&aacute;rios e essenciais para a navega&ccedil;&atilde;o e utiliza&ccedil;&atilde;o dos recursos da plataforma da Be Back Now. Outros coletam informa&ccedil;&otilde;es sobre como voc&ecirc; utiliza o site e servem para melhorar o desempenho e a experi&ecirc;ncia de navega&ccedil;&atilde;o. Por fim, os cookies funcionais relembram suas escolhas e prefer&ecirc;ncias, personalizando a sua experi&ecirc;ncia no site.</p>' + 
'' + 
'<p>Voc&ecirc; poder&aacute; desabilitar os cookies atrav&eacute;s das configura&ccedil;&otilde;es de seu navegador, instalando plug-ins dispon&iacute;veis no mercado, ou ainda fazendo uso de outras tecnologias que entenda serem necess&aacute;rias.</p>' + 
'' + 
'<h2>8. Quando esta Pol&iacute;tica &eacute; atualizada?</h2>' + 
'' + 
'<p>Esta Pol&iacute;tica de Privacidade poder&aacute; ser atualizada a qualquer momento, para garantir o nosso compromisso de m&aacute;xima transpar&ecirc;ncia com voc&ecirc;.</p>' + 
'' + 
'<h2>9. Contato para d&uacute;vidas a respeito de Privacidade?</h2>' + 
'' + 
'<p>A Be Back Now tem uma equipe de profissionais dedicada &agrave; prote&ccedil;&atilde;o de dados e privacidade. Caso tenha qualquer d&uacute;vida, entre em contato com o Encarregado da Be Back Now para assuntos relativos &agrave; Prote&ccedil;&atilde;o e Privacidade de Dados Pessoais atrav&eacute;s do endere&ccedil;o de e-mail: contato@bebacknow.com.</p>' + 
'' + 
'<h2>10. Termos utilizados nesta Pol&iacute;tica</h2>' + 
'' + 
'<p>Consentimento: Manifesta&ccedil;&atilde;o livre, informada e inequ&iacute;voca do titular que autoriza a coleta e/ou tratamento dos seus dados pessoais para uma finalidade espec&iacute;fica.</p>' + 
'' + 
'<p>Dado Pessoal: Qualquer informa&ccedil;&atilde;o relacionada a pessoa natural, direta ou indiretamente, identificada ou identific&aacute;vel.</p>' + 
'' + 
'<p>Encarregado pelo Tratamento de Dados Pessoais: pessoa indicada pela Be Back Now para ser o respons&aacute;vel por garantir o atendimento aos seus direitos e esclarecer d&uacute;vidas sobre o tratamento de seus dados pessoais.</p>' + 
'' + 
'<p>Finalidade: Motivo pelo qual o dado pessoal ser&aacute; tratado, ou objetivo que se pretende atingir com o tratamento dos dados.</p>' + 
'' + 
'<p>Terceiro: Refere-se a toda e qualquer pessoa f&iacute;sica ou jur&iacute;dica, que a Be Back Now se relacione ou venha a se relacionar, prestador de servi&ccedil;os, fornecedor, consultor, cliente, parceiro de neg&oacute;cio, terceiro contratado ou subcontratado, locat&aacute;rio, cession&aacute;rio de espa&ccedil;o comercial, independentemente de contrato formal ou n&atilde;o, incluindo aquele que utiliza o nome da Be Back Now para qualquer fim ou que presta servi&ccedil;os, fornece materiais, interage com Funcion&aacute;rio P&uacute;blico, com o Governo ou com outros Terceiros em nome da Be Back Now.</p>' + 
'' + 
'<p>Titular de dados pessoais: Pessoa natural a quem se referem os dados pessoais, tais como clientes, colaboradores, contratados, candidatos e voc&ecirc;.</p>' + 
'' + 
'<p>Tratamento: Toda opera&ccedil;&atilde;o realizada com dados pessoais dentro de seu ciclo de vida, como coleta, produ&ccedil;&atilde;o, recep&ccedil;&atilde;o, classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o, acesso, reprodu&ccedil;&atilde;o, transmiss&atilde;o, distribui&ccedil;&atilde;o, processamento, arquivamento, armazenamento, elimina&ccedil;&atilde;o, avalia&ccedil;&atilde;o ou controle da informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o, comunica&ccedil;&atilde;o, transfer&ecirc;ncia, difus&atilde;o ou extra&ccedil;&atilde;o.</p>' + 
'' + 
'<h2>11. Disposi&ccedil;&otilde;es Gerais</h2>' + 
'' + 
'<p>No momento do cadastro, voc&ecirc; dever&aacute; ler, compreender e aceitar esta Pol&iacute;tica, conforme op&ccedil;&atilde;o espec&iacute;fica disponibilizada no formul&aacute;rio. Por&eacute;m, esta Pol&iacute;tica tem natureza de contrato de ades&atilde;o e passa por revis&otilde;es peri&oacute;dicas, sem que seja necess&aacute;ria &agrave; sua notifica&ccedil;&atilde;o pr&eacute;via. Por isso, &eacute; importante que voc&ecirc; consulte o documento para saber se continua concordando com seus termos antes de seguir com a navega&ccedil;&atilde;o.</p>' + 
'' + 
'<p>A Pol&iacute;tica de Privacidade da Be Back Now est&aacute; em conformidade e dever&aacute; ser interpretada com base nas leis vigentes na Rep&uacute;blica Federativa do Brasil. Para dirimir eventuais d&uacute;vidas ou quest&otilde;es relativas a ela, as partes elegem o Foro da Comarca do Rio de Janeiro/RJ, com exclus&atilde;o de qualquer outro.</p>' + 
'' + 
'' + 
'';