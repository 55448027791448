import { SyButton, SyInput, SySelect } from "@sydle/sydle-ui-react";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  top: 80px;

  display: grid;
  grid-template-columns: 40% 60%;
  background-color: #fff;
  color: #313131;

  height: calc(100vh - 80px);
  overflow: auto;

  p {
    margin: 1rem 0;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftSection = styled.section`
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const RightSection = styled.section`
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  h1 {
    text-align: center;
    font-size: 1.75rem;
    color: var(--light-orange);
  }

  form {
    width: 100%;
    padding: 0 2rem;
    margin-bottom: 2.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 2rem;

    .checkGroup {
      grid-column: 1/3;
      align-self: center;
      justify-self: center;

      .checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.5rem;

        label {
          margin-left: 0.5rem;

          a {
            color: var(--light-orange);
          }
        }
      }

      @media (max-width: 1024px) {
        grid-column: auto;
      }
    }

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 420px) {
    padding: 2rem 1rem;
    grid-template-columns: 1fr;
  }
`;

export const StyledSyInput = styled(SyInput)`
  --sy-input-color: #313131;
  --sy-input-border-color: #313131;

  --sy-input-border-color-hover: #313131;

  --sy-input-border-color-focus: var(--light-orange);
  --sy-input-color-label-focus: var(--light-orange);
`;

export const StyledSySelect = styled(SySelect)`
  --sy-select-border-color: #313131;
  --sy-select-border-color-focus: var(--light-orange);
  --sy-select-color-label-focus: var(--light-orange);
  --sy-select-dropdown-search-highlight-color: var(--light-orange);

  --sy-select-item-background-color: transparent;
  --sy-select-item-background-color-hover: rgba(255, 102, 0, 0.1);
`;

export const StyledSyButton = styled(SyButton)`
  --sy-button-padding: 0.75rem 2rem;
  --sy-button-background-color: var(--light-orange);
  --sy-button-border-color: var(--light-orange);
  --sy-button-color: #fff;
  --sy-button-background-color-hover: var(--light-orange);
  --sy-button-border-color-hover: var(--light-orange);
  --sy-button-color-hover: #fff;
  --sy-button-background-color-active: var(--light-orange);
  --sy-button-border-color-active: var(--light-orange);
  --sy-button-color-active: #fff;
  --sy-button-loading-color: #fff;

  &[loading="true"] {
    pointer-events: none;
  }
`;

export const SocialLogin = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 0 auto;

  span {
    cursor: pointer;
    transition: 0.2s ease all;
    border-radius: 4px;
  }

  .linkedinLogin {
    padding: 0.75rem 4rem;
    background-color: #0077b5;
    border: 1px solid #0077b5;

    &:hover {
      background-color: #005e8f;
    }
  }

  .googleLogin {
    padding: 0.75rem 4rem;
    border: 1px solid #777ea0;
    background-color: #fff;

    &:hover {
      background-color: #ececec;
    }
  }

  svg {
    cursor: pointer;
  }
`;
