import sendRequest from "../../services/sendRequest";
import MoonLoader from "react-spinners/MoonLoader";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import {
	Container, 
	Banner, 
	Intro,
	ImageSection,
	VideoSection,
	Content, 
	Section, 
	RecruitmentSupport, 
	Separator,
	ProgramSupport, 
	Button,
	PartnersSection
} from "./style";

export function Partners() {
	const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const [partnersData, setPartnersData] = useState<any>();
	const apiUrl = process.env.REACT_APP_SYDLE_ONE_ENDPOINT;
	const [loader, setLoader] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchPartnersData = async () => {
			const response = await sendRequest.getPartners();

			if (response) {
				setPartnersData(response.pageContent);
				setLoader(false);
			}
		}

		fetchPartnersData();
		window.scrollTo(0, 0); // Garante que a página irá carregar no topo
	}, []);

	return (
		<Container>
			<MoonLoader
				color={"#FF6600"}
				loading={loader}
				size={80}
				aria-label="Loading Spinner"
				data-testid="loader"
				cssOverride={{
					margin: "200px 0",
				}}
			/>

			{partnersData && (
				<>
					<Banner
						backgroundImageURL={
						!isMobile
							? apiUrl + "site/pages/partners/downloadImage/" + partnersData.headerImageID
							: apiUrl + "site/pages/partners/downloadImage/" + partnersData.headerMobileImageID
						}
					>
						<div className="message">
							<h2>{partnersData.headerTitle}</h2>
							<p>{partnersData.headerText}</p>
						</div>

						<Button onClick={() => navigate("/business-contact")}>
							{partnersData.buttonText}
						</Button>
					</Banner>

					<Intro>
						<div className={partnersData.displayImageOrVideo === "image" ? "image box" : "video box"}>
							<div className="description">
								<h2>{partnersData.introTitle}</h2>
								<div
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(partnersData.introText),
									}}
								/>
							</div>
							{partnersData.displayImageOrVideo === "video" && (
								<VideoSection>
									<div className="iframe-container">
										<iframe
											src={partnersData.introVideoLink}
											title="Home Video"
											allowFullScreen
											loading="lazy"
										></iframe>
									</div>
								</VideoSection>
							)}
							{partnersData.displayImageOrVideo === "image" && (
								<ImageSection>
									<img src={apiUrl + "site/pages/partners/downloadImage/" + partnersData.introImageID} alt=""/>
								</ImageSection>
							)}
						</div>
					</Intro>

					<Content>
						<RecruitmentSupport>
							<Section>
								<div className="recruitment-description">
									<h2>{partnersData.recruitmentSupportTitle}</h2>
									<div
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(
												partnersData.recruitmentSupportDescription
											)
										}}
									/>
								</div>
								<img src={apiUrl + "site/pages/partners/downloadImage/" + partnersData.recruitmentSupportImageID} alt=""/>
							</Section>
						</RecruitmentSupport>
						
						<Separator>
							<hr></hr>
						</Separator>

						<ProgramSupport>
							<Section>
								<img src={apiUrl + "site/pages/partners/downloadImage/" + partnersData.supportProgramImageID } alt=""/>
								<div className="recruitment-description">
									<h2>{partnersData.supportProgramTitle}</h2>
									<div
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(
												partnersData.supportProgramDescription
											)
										}}
									/>
								</div>
							</Section>
						</ProgramSupport>

						<Button onClick={() => navigate("/business-contact")}>
							{partnersData.buttonText}
						</Button>

						<Separator>
							<hr></hr>
						</Separator>

						{partnersData.partnersLogo.length && (
							<PartnersSection>
								<h2>{partnersData.partnersText}</h2>
								<div className="partners-list">
									{partnersData.partnersLogo.map((item: any) => (
										<div className="logo" key={item.name}>
											<img src={item.logoLink} alt={item.name} />
										</div>
									))}
								</div>
							</PartnersSection>
						)}
					</Content>
				</>
			)}
		</Container>
  	);
}