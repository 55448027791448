import { useState, useEffect } from "react";
import { InputLine } from "./InputStyle";
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

Input.propTypes = {
  half: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  type: PropTypes.string,
  disable: PropTypes.bool,
  validation: PropTypes.string,
  validateError: PropTypes.bool,
};

export default function Input({
  half = false,
  label,
  value,
  setValue,
  type = "text",
  disable = false,
  validation = null,
  validateError = false,
}) {
  const [internalValue, setInternalValue] = useState("");
  const [messageError, setMessageError] = useState("");
  const isMobile = useMediaQuery({ query: '(max-width: 426px)' })

  function validationCheck(value) {
    if (!value && validateError) {
      setMessageError(" (Obrigatório)");
    } else if (validateError) {
      switch (validation) {
        case "name":
          const nameParts = value.trim().split(' ');

          if (nameParts.length < 2) {
            setMessageError(" (Nome Inválido)");
          } else {
            setMessageError("");
          }
          break;
        
        case "tel":
          const phoneRegex = /^\(?\d{2}\)?[\s]?\d{4,5}[-\s]?\d{4}$/;

          if (!phoneRegex.test(value)) {
            setMessageError(" (Telefone Inválido)");
          } else {
            setMessageError("");
          }
          break;

        case "email":
          let email = value;
          const emailRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
          let validationEmail = emailRegex.test(email);
          if (validationEmail === false || email === "") {
            setMessageError(" (Email Inválido)");
          } else {
            setMessageError("");
          }
          break;

        // case "career":
        //   let numLetters = value.length;
        //   if (numLetters < 3 || value === "") {
        //     setMessageError(" (Profissão Inválida)");
        //   } else {
        //     setMessageError("");
        //   }
        //   break;

        default:
          break;
      }
    } else {
      setMessageError("");
    }
  }

  useEffect(() => {
    validationCheck(internalValue);
  }, [validateError]);

  const handleInputChange = (value) => {
    // Get the raw input value without the mask
    const rawValue = value.replace(/[^0-9]/g, '');

    // Apply your mask logic
    const maskedValue = formatPhoneNumber(rawValue);

    setValue(maskedValue);
    setInternalValue(maskedValue);
    validationCheck(maskedValue);
  };

  const formatPhoneNumber = (value) => {
    const maskedValue = value.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
    
    return maskedValue;
  };

  const renderPhoneInput = () => (
    <input
      value={value}
      disabled={disable}
      className="input__field"
      type={type}
      maxLength="15"
      placeholder=" "
      onChange={(e) => {
        handleInputChange(e.target.value);
      }}
      style={{ marginBottom: 0 }}
    />
  )

  const renderDefaultInput = () => (
    <input
      value={value}
      disabled={disable}
      className="input__field"
      type={type}
      min={type === "number" ? 1 : null}
      placeholder=" "
      onChange={(e) => {
        validationCheck(e.target.value);
        setValue(e.target.value);
        setInternalValue(e.target.value);
      }}
      style={{ marginBottom: 0 }}
    />
  )

  return (
    <InputLine half={half}>
      <label className="input">
        {type !== "tel" ? renderDefaultInput() : renderPhoneInput()}
        <span className="input__label">
          {label} {!isMobile ? <span style={{ color: "red", fontSize: 12 }}>{messageError}</span> : ""} 
        </span>
      </label>

      {isMobile ? <span style={{ color: "red", fontWeight: 600, backgroundColor: "#fff", padding: 2, borderRadius: 3, fontSize: "0.8rem"}}>{messageError}</span> : ""} 
    </InputLine>
  );

  // return (
  //   <InputLine half={half}>
  //     <label className="input">
  //       <input
  //         value={value}
  //         disabled={disable}
  //         className="input__field"
  //         type={type}
  //         min={type === "number" ? 1 : null}
  //         maxLength={type === "tel" ? "15" : null}
  //         placeholder=" "
  //         onChange={(e) => {
  //           validationCheck(e.target.value);
  //           setValue(e.target.value);
  //           setInternalValue(e.target.value);
  //         }}
  //         style={{ marginBottom: 0 }}
  //       />
  //       <span className="input__label">
  //         {label} {!isMobile ? <span style={{ color: "red", fontSize: 12 }}>{messageError}</span> : ""} 
  //       </span>
  //     </label>

  //     {isMobile ? <span style={{ color: "red", fontWeight: 600, backgroundColor: "#fff", padding: 2, borderRadius: 3, fontSize: "0.8rem"}}>{messageError}</span> : ""} 
  //   </InputLine>
  // );
}
