import {
	Section,
	Row,
	Image
} from "./style";

export function Section01({ path, background, bannerID }) {
	const apiUrl = process.env.REACT_APP_SYDLE_ONE_ENDPOINT;

	return (
		<Section backgroundImageUrl={apiUrl + path + background.id}>
			<Row>
				<Image>
					<img src={apiUrl + path + bannerID} max-width="60%" height="auto" alt="Workshop Banner" />
				</Image>
			</Row>
		</Section>
	);
}