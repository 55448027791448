import { WhatsappButton, Container, MainContainer, DefaultView, Button } from "./style";
import AuthContext from "../../contexts/Auth/AuthContext";
import { useEffect, useState, useContext } from "react";
import sendRequest from "../../services/sendRequest";
import MoonLoader from "react-spinners/MoonLoader";
import { Section01 } from "./sections/Section01";
import { Section02 } from "./sections/Section02";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { WhatsappLogo } from "phosphor-react";
import backgroundMobile from "./assets/banner01_mobile.png";
import background from "./assets/banner01.png";

export function Workshop() {
	const navigate = useNavigate();
	const [data, setData] = useState();
	const [tagID, setTagID] = useState();
	const [workshopTitle, setWorkshopTitle] = useState();
	const [loader, setLoader] = useState(true);
	const [status, setStatus] = useState();
	const { user } = useContext(AuthContext);
	const [message, setMessage] = useState(); // Estado para armazenar a mensagem do WhatsApp
	const [cellphone, setCellphone] = useState(); // Estado para armazenar o número de telefone do WhatsApp
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState("pt");
	const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
	const workshopMethodPath = "site/pages/workshopRegistrationPage/downloadImage/";

	useEffect(() => {
		async function fetchContactData() {
			const contactResult = await sendRequest.getContactWhatsapp({});

			if (contactResult) {
				setCellphone(contactResult.cellphone);
				setMessage(contactResult.message);
			}
		}

		function fetchData() {
			let storedLanguage = localStorage.getItem("selectedLanguage");

			if (!storedLanguage) {
				localStorage.setItem("selectedLanguage", "pt");
				storedLanguage = localStorage.getItem("selectedLanguage");
			}

			setSelectedLanguage(storedLanguage);
		}

		async function fetchWorkshopData() {
            const response = await sendRequest.getWorkshopRegistrationPage();

			if (response) {
				setWorkshopTitle(response.workshopTitle);
				setData(response.pageContent);
				setStatus(response.success);
				setTagID(response.tagID);
				setLoader(false);
            }
        }

		setLoggedIn(!!user);
		fetchData();
		fetchContactData();
		fetchWorkshopData();
	}, [user]);

	return (
		<Container>
			<MoonLoader
				color={"#FF6600"}
				loading={loader}
				size={80}
				aria-label="Loading Spinner"
				data-testid="loader"
				cssOverride={{
					margin: "300px 0",
				}}
			/>
			{(data && status) && (
				<MainContainer>
					<Section01 
						bannerID={data.bannerID} 
						path={workshopMethodPath} 
						background={data.backgrounds[0]}
					/>
					<Section02 
						selectedLanguage={selectedLanguage} 
						path={workshopMethodPath} 
						background={data.backgrounds[1]}
						overlayBackgroundID={data.overlayBackgroundID}
						featuredImageID={data.featuredImageID}
						ebookPromotionalText={data.ebookPromotionalText}
						ebookImages={data.ebookImages}
						linkedinLink={data.linkedinLink}
						instagramLink={data.instagramLink}
						ebookPath={data.ebookPath}
						colors={data.colors}
						tagID={tagID}
						workshopTitle={workshopTitle}
					/>

					{localStorage.getItem("acceptedPrivacyPolicy") ? (
						<WhatsappButton
							href={`https://api.whatsapp.com/send?phone=${cellphone}&text=${encodeURIComponent(
								message
							)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<WhatsappLogo size={40} />
						</WhatsappButton>
					) : null}
				</MainContainer>
			)}

			{(!status && !loader) && (
				<DefaultView backgroundImage={!isMobile ? background : backgroundMobile}>
					{!isLoggedIn && (
						<>
							<div className="message">
								<h2>Ops! Não temos um workshop ativo no momento.</h2>
								<p>Deseja receber um e-mail para participar do próximo workshop e garantir sua vaga?</p>
								<p>É fácil! Clique no botão abaixo para se inscrever!</p>
							</div>

							<Button onClick={() => navigate("/register")}>
								Cadastre-se
							</Button>
						</>
					)}

					{isLoggedIn && (
						<>
							<div className="message">
								<h2>Ops! Não temos um workshop ativo no momento.</h2>
								<p>Que tal conferir as gravações de nossos workshops?</p>
								<p>É fácil! Clique no botão abaixo para acessar!</p>
							</div>

							<Button onClick={() => navigate("/academy#workshops")}>
								Acesse
							</Button>
						</>
					)}
				</DefaultView>
			)}
		</Container>
	)
}