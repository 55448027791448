import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import sendRequest from "../../services/sendRequest";
import { useState, useEffect, useContext } from "react";
import {
  Container,
  EbookSection,
  IntroSection,
  IntroImage,
  IntroTextDiv,
  CardSection,
  Card,
  VideoSection,
  TestimonialSection,
  Button
} from "./style";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { useMediaQuery } from 'react-responsive';
import AuthContext from "../../contexts/Auth/AuthContext";
import MoonLoader from "react-spinners/MoonLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function Home() {
    const [data, setData] = useState<any>();
    const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
    const apiUrl = process.env.REACT_APP_SYDLE_ONE_ENDPOINT;
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
    const { user, setIsAuthModalOpen } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchHomeData() {
            const response = await sendRequest.getHome();

            if (response) {
                setData(response.response);
            }
        }

        fetchHomeData();
        setLoggedIn(!!user);

        window.scrollTo(0, 0); // Garante que a página irá carregar no topo
    }, [user]);

    const renderArrowPrev = (clickHandler: any, hasPrev: any, label: any) => (
        <div
            className="custom-arrow-prev"
            onClick={clickHandler}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
                cursor: hasPrev ? 'pointer' : 'default',
                opacity: hasPrev ? 1 : 0.5,
                zIndex: 1,
                background: '#FF6600',
                color: '#FFF',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                margin: '0 15px'
            }}
        >
            <MdArrowBackIosNew />
        </div>
    );
    
    const renderArrowNext = (clickHandler: any, hasNext: any, label: any) => (
        <div
            className="custom-arrow-next"
            onClick={clickHandler}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                cursor: hasNext ? 'pointer' : 'default',
                opacity: hasNext ? 1 : 0.5,
                zIndex: 1,
                background: '#FF6600',
                color: '#FFF',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                margin: '0 15px'
            }}
        >
          <MdArrowForwardIos/>
        </div>
    );

    const handleRedirect = (isLogged: boolean, route: string) => {
        if (!isLogged || (isLogged && route !== "/register")) {
            navigate(route);
            return;
        }

        navigate("/academy#roteiros");
    }

    const verifyUserAuthenticationBeforeDownloading = async (ebookID: string) => {
        const storageUser = localStorage.getItem("@bebacknow:user");
        const parsedUser = storageUser ? JSON.parse(storageUser) : null;

        if (parsedUser) {
            try {
                const params = {
                    email: parsedUser.login,
                    ebookID
                }
                const { filePath, filename } = await sendRequest.downloadEbook(params);

                if (filePath && filename) {
                    downloadFile(apiUrl + filePath);

                    toast.success("Download realizado", {
                        toastId: "home-01-success",
                    });

                    (window as any).dataLayer.push({
                        event: "ebook_download",
                        ebook_name: filename
                    });
                }
            } catch (error) {
                console.log(error);
                toast.error("Não foi possível realizar o download do ebook. Tente novamente mais tarde.", {
                    toastId: "home-01-error",
                })
            }
        }
        
        setIsAuthModalOpen(!parsedUser);
    }

    const downloadFile = (url: string) => {
        const anchor = document.createElement('a');
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    return (
      <Container>
        <MoonLoader
            color={"#FF6600"}
            loading={!data}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
            cssOverride={{
                margin: "160px 0",
            }}
        />
        {data && (
            <>
                <Carousel
                    showThumbs={false}
                    showArrows={true}
                    showStatus={false}
                    autoPlay={true}
                    interval={10000}
                    infiniteLoop={true}
                    stopOnHover={true}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={30}
                    emulateTouch={true}
                >
                    {data.carousel.map((item: any) => (
                        <div
                            key={item.id}
                            className="carousel-item"
                            onClick={() => handleRedirect(isLoggedIn, item.route)}
                            style={{
                                backgroundImage: !isMobile
                                ? `url(${apiUrl}site/pages/home/downloadImage/${item.id})`
                                : `url(${apiUrl}site/pages/home/downloadImage/${item.idMobile})`,
                            }}
                        >
                        {item.title && item.subtitle && item.buttonText ? (
                            <div className="text-container">
                            <h2>{item.title}</h2>
                            <p>{item.subtitle}</p>

                            <Button
                                onClick={() => handleRedirect(isLoggedIn, item.route)}
                            >
                                {isLoggedIn && item.buttonText === "Cadastre-se"
                                ? "Descubra"
                                : item.buttonText}
                            </Button>
                            </div>
                        ) : (
                            <></>
                        )}
                        </div>
                    ))}
                </Carousel>

                <IntroSection>
                    <IntroImage
                        src={apiUrl + "site/pages/home/downloadImage/" + data.introImageID}
                        alt=""
                    />

                    <IntroTextDiv>
                        <h3>{data.introTitle}</h3>
                        <p>{data.introText}</p>
                        <Button
                            onClick={() => {
                                navigate(data.introRoute);
                            }}
                        >
                            {data.introButtonText}
                        </Button>
                    </IntroTextDiv>
                </IntroSection>

                <EbookSection id="ebookSection">
                    <h2>{data.ebookSectionTitle}</h2>
                    <CardSection>
                        {data.ebooks && data.ebooks.map((ebook: any) => (
                            <Card
                                key={ebook.id}
                                onClick={() => verifyUserAuthenticationBeforeDownloading(ebook.id)}
                                role="button"
                            >
                                <img
                                    src={apiUrl + "site/pages/ebook/downloadImage/" + ebook.id}
                                    alt="Banner do Ebook"
                                />
                                <div>
                                    <p>{ebook.title}</p>
                                    <p>{ebook.subtitle}</p>
                                    <span>Baixar</span>
                                </div>
                            </Card>
                        ))}
                    </CardSection>
                </EbookSection>

                <VideoSection id="video">
                    <h2>{data.videoSectionTitle}</h2>
                    <div className="iframe-container">
                        <iframe
                            src={data.videoLink}
                            title="Home Video"
                            allowFullScreen
                            loading="lazy"
                        ></iframe>
                    </div>
                </VideoSection>

                <TestimonialSection>
                    <h2>{data.storiesSectionTitle}</h2>
                    <Carousel
                        showThumbs={false}
                        showArrows={true}
                        showStatus={false}
                        autoPlay={false}
                        showIndicators={false}
                        renderArrowPrev={renderArrowPrev}
                        renderArrowNext={renderArrowNext}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={30}
                        emulateTouch={true}
                    >
                        {data.stories.map((item: any) => (
                            <div key={item.id} className="item">
                                <q>{item.testimonial}</q>

                                <div className="author">
                                <img
                                    src={apiUrl + "site/site/testimonials/downloadImage/" + item.photoID}
                                    alt=""
                                />
                                <div className="info">
                                    <p>{item.name}</p>
                                    <p>{item.role}</p>
                                </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </TestimonialSection>
            </>
        )}
      </Container>
    );
}