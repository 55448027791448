import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import sendRequest from "../../services/sendRequest";
import { toast } from "react-toastify";
import validate from "../../utils/validator";
import {
  Container,
  LeftSection,
  RightSection,
  SocialLogin,
  StyledSyButton,
  StyledSyInput,
  StyledSySelect,
} from "./style";
import AuthContext from "../../contexts/Auth/AuthContext";
import logoGoogle from "../../assets/logo-google.svg";
import logoLinkedin from "../../assets/logo-linkedin.svg";
import { SyProgressBar } from "@sydle/sydle-ui-react";
import BackgroundImage from "./assets/bg.png";


type Object = {
  id: string;
  name: string;
};

interface formOptions {
  countries: Object[];
  brazilianStates: Object[];
  cities: Object[];
  yearsOfExperience: string[];
  yearsOfCareerBreak: string[];
  whereDidYouFindUs: Object[];
  highestEducationalQualification: Object[];
  reasonForCareerBreak: Object[];
}

interface IsValidProps {
  email: string | undefined;
  phone: string | undefined;
  name: string | undefined;
  country: string | undefined;
  brazilianState: string | undefined;
  city: string | undefined;
  profession: string | undefined;
  yearsOfExperience: string | undefined;
  yearsOfCareerBreak: string | undefined;
  whereDidYouFindUs: string | undefined;
  highestEducationalQualification: string | undefined;
  reasonForCareerBreak: string | undefined;
  password: string | undefined;
  url: string | undefined;
}

interface highestEducationalQualificationList {
  id: string | undefined;
  name: string | undefined;
  order: string | undefined;
}

export function Register() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState<string | string[]>("590b71c2f5ca53091c89ea4e");
  const [brazilianState, setBrazilianState] = useState<string | string[]>("");
  const [city, setCity] = useState<string | string[]>("");
  const [region, setRegion] = useState<string>("");
  const [profession, setProfession] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState<string | string[]>(
    ""
  );
  const [yearsOfCareerBreak, setYearsOfCareerBreak] = useState<
    string | string[]
  >("");
  const [whereDidYouFindUs, setWhereDidYouFindUs] = useState<string | string[]>(
    ""
  );
  const [highestEducationalQualification, setHighestEducationalQualification] =
    useState<string | string[]>("");
  const [reasonForCareerBreak, setReasonForCareerBreak] = useState<
    string | string[]
  >("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");

  const [formOptions, setFormOptions] = useState<formOptions>({
    countries: [],
    brazilianStates: [],
    cities: [],
    yearsOfExperience: ["1-4", "5-9", "10-14", "15+"],
    yearsOfCareerBreak: ["Não", "Sim"],
    whereDidYouFindUs: [],
    highestEducationalQualification: [],
    reasonForCareerBreak: [],
  });

  const [useTerms, setUseTerms] = useState(false);
  const [newsletterSubscription, setNewsletterSubscription] = useState(false);
  const [errors, setErrors] = useState<IsValidProps>({
    email: undefined,
    phone: undefined,
    name: undefined,
    country: undefined,
    brazilianState: undefined,
    city: undefined,
    profession: undefined,
    yearsOfExperience: undefined,
    yearsOfCareerBreak: undefined,
    whereDidYouFindUs: undefined,
    highestEducationalQualification: undefined,
    reasonForCareerBreak: undefined,
    password: undefined,
    url: undefined,
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const [
    highestEducationalQualificationList,
    setHighestEducationalQualificationList,
  ] = useState<highestEducationalQualificationList[]>([]);

  // garante que a página vá para o topo
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const accessToken = searchParams.get("accessToken");
    if (accessToken) {
      authContext.googleLogin(accessToken);
    }
  }, []);

  async function fetchCities() {
    await sendRequest.getCountries({ stateId: brazilianState }).then((res) => {
      setFormOptions({
        ...formOptions,
        cities: res.cities,
        highestEducationalQualification: res.highestEducationalQualification,
      });
    });
  }

  async function getEducationLevels() {
    await sendRequest.getEducationLevels({}).then((response) => {
      setHighestEducationalQualificationList(response);
    });
  }

  async function fetchCountriesData() {
    await sendRequest
      .getCountries({ stateId: "" })
      .then((res) => {
        setFormOptions({
          ...formOptions,
          countries: res.countries.sort((a: Object, b: Object) =>
            a.name.localeCompare(b.name)
          ),
          brazilianStates: res.brazilianStates.sort((a: Object, b: Object) =>
            a.name.localeCompare(b.name)
          ),
          whereDidYouFindUs: res.whereDidYouFindUs,
          reasonForCareerBreak: res.reasonForCareerBreak,
          highestEducationalQualification: res.highestEducationalQualification,
        });
      })
      .catch((err) => {
        console.log(`Error trying to fetch countries: ${err}`);
      });
  }

  async function handleRegisterNewUser() {
    setIsLoading(true);
    let updateErrors = { ...errors };
    updateErrors.email = validate.register.email(email);
    updateErrors.name = validate.register.name(name);
    updateErrors.country = validate.register.country(country);
    updateErrors.brazilianState = validate.register.brazilianState(brazilianState);
    updateErrors.city = validate.register.city(city);
    updateErrors.profession = validate.register.profession(profession);
    updateErrors.whereDidYouFindUs = validate.register.whereDidYouFindUs(whereDidYouFindUs);
    updateErrors.highestEducationalQualification = validate.register.highestEducationalQualification(highestEducationalQualification);
    updateErrors.password = validate.register.password(password);

    if (
      updateErrors.email ||
      updateErrors.name ||
      updateErrors.country ||
      updateErrors.profession ||
      updateErrors.whereDidYouFindUs ||
      updateErrors.highestEducationalQualification ||
      updateErrors.password
    ) {
      setErrors(updateErrors);
      setIsLoading(false);
      return;
    }

    if (!useTerms) {
      toast.error("Você precisa aceitar os Termos de Uso", {
        toastId: "register-error",
      });
      setIsLoading(false);
      return;
    }

    let cellphone = "";
    if (phone) {
      cellphone = phone.replace(/[^0-9]/g, "");
    }

    if (name && email && password && country && profession) {
      const response = await sendRequest
        .registerNewUser({
          email,
          phone: cellphone,
          name,
          country,
          brazilianState,
          city,
          region,
          profession,
          yearsOfExperience,
          yearsOfCareerBreak,
          whereDidYouFindUs,
          highestEducationalQualification,
          reasonForCareerBreak,
          password,
          receiveNewsletter: newsletterSubscription,
          url: String(window.location.href),
        })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(`Erro: ${err}`);
          return null;
        });

      if (response?.person?.name) {
        toast.success(
          `Usuário ${response.person.name} cadastrado com sucesso! Por favor, verifique sua conta com o código enviado para seu email`,
          {
            toastId: "register-01-success",
          }
        );

        navigate("/validate-token");
      } else if (
        response.indexOf(
          "Já existe uma conta pendente de aprovação para o email"
        ) !== -1
      ) {
        navigate("/validate-token");
        toast.warning(`${response}`);
      } else {
        toast.warning(`${response}`);
      }

      setIsLoading(false);
    }
  }

  async function handleGoogleLogin() {
    let redirectUrl = window.location.href
      .split("?")[0]
      .replace("register", "profile");

    window.location.href =
      process.env.REACT_APP_LOGIN_API +
      "googleAuthenticator?one_redirect=" +
      redirectUrl;
  }

  async function handleLinkedinLogin() {
    let redirectUrl = window.location.href
      .split("?")[0]
      .replace("register", "profile");

    window.location.href =
      process.env.REACT_APP_LOGIN_API +
      "linkedinAuthenticator?one_redirect=" +
      redirectUrl;
  }

  useEffect(() => {
    setIsLoading(true);
    fetchCountriesData();
    getEducationLevels();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (brazilianState !== "") {
      fetchCities();
    }
  }, [brazilianState]);

  return (
    <Container>
      <LeftSection style={{ backgroundImage: `url(${BackgroundImage})` }}></LeftSection>
      <RightSection>
        <h1>Registre-se já</h1>

        <form>
          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : (
            <StyledSyInput
              type="email"
              value={email}
              onSyInputDidInput={(e) =>
                setEmail((e.target as HTMLInputElement).value)
              }
              label="E-mail *"
              error={errors.email}
            ></StyledSyInput>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : (
            <StyledSyInput
              label="Senha *"
              type="password"
              value={password}
              onSyInputDidInput={(e) =>
                setPassword((e.target as HTMLInputElement).value)
              }
              error={errors.password}
            ></StyledSyInput>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : (
            <StyledSyInput
              type="text"
              value={name}
              onSyInputDidInput={(e) =>
                setName((e.target as HTMLInputElement).value)
              }
              label="Nome *"
              error={errors.name}
            ></StyledSyInput>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : (
            <StyledSyInput
              type="text"
              value={phone}
              onSyInputDidInput={(e) => {
                setPhone((e.target as HTMLInputElement).value);
              }}
              label="Celular"
              mask="(99) 99999-9999"
              minimum-fraction-digits="11"
              error={errors.phone}
            ></StyledSyInput>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : (
            <StyledSySelect
              label="País *"
              enableSearch
              onSySelectDidChange={(e) => {
                setCountry((e.target as HTMLSySelectElement).value!);
              }}
              error={errors.country}
              size={formOptions.countries.length}
              value={country}
            >
              {formOptions.countries.map((country) => {
                return (
                  <option
                    key={country.id}
                    value={country.id}
                  >
                    {country.name}
                  </option>
                );
              })}
            </StyledSySelect>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : country === "590b71c2f5ca53091c89ea4e" ? ( // brazilian id
            <>
              <StyledSySelect
                label="Estado *"
                enableSearch
                onSySelectDidChange={(e) => {
                  setBrazilianState((e.target as HTMLSySelectElement).value!);
                }}
                error={errors.brazilianState}
                size={formOptions.brazilianStates.length}
              >
                {formOptions.brazilianStates.map((brazilianState) => {
                  return (
                    <option key={brazilianState.id} value={brazilianState.id}>
                      {brazilianState.name}
                    </option>
                  );
                })}
              </StyledSySelect>
              {brazilianState !== "" && (
                <StyledSySelect
                  label="Cidade *"
                  enableSearch
                  onSySelectDidChange={(e) => {
                    setCity((e.target as HTMLSySelectElement).value!);
                  }}
                  error={errors.city}
                >
                  {formOptions.cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    );
                  })}
                </StyledSySelect>
              )}
            </>
          ) : (
            <StyledSyInput
              label="Região"
              value={region}
              onSyInputDidInput={(e) =>
                setRegion((e.target as HTMLInputElement).value)
              }
            />
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : (
            <StyledSyInput
              label="Profissão *"
              type="text"
              value={profession}
              onSyInputDidInput={(e) =>
                setProfession((e.target as HTMLInputElement).value)
              }
              error={errors.profession}
            ></StyledSyInput>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : (
            <StyledSySelect
              label="Maior qualificação educacional *"
              onSySelectDidChange={(e) => {
                setHighestEducationalQualification(
                  (e.target as HTMLSySelectElement).value!
                );
              }}
              error={errors.highestEducationalQualification}
            >
              {highestEducationalQualificationList &&
              highestEducationalQualificationList.length > 0
                ? highestEducationalQualificationList.map((hit) => {
                    return (
                      <option key={hit.id} value={hit.id}>
                        {hit.name}
                      </option>
                    );
                  })
                : null}
            </StyledSySelect>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : (
            <StyledSySelect
              label="Está em pausa na carreira?"
              onSySelectDidChange={(e) => {
                setYearsOfCareerBreak((e.target as HTMLSySelectElement).value! === "Sim" ? "Sim" : "Não");
              }}
              error={errors.yearsOfCareerBreak}
            >
              {formOptions.yearsOfCareerBreak.map((yearsOfCareerBreak, index) => {
                return (
                  <option key={index} value={yearsOfCareerBreak}>
                    {yearsOfCareerBreak}
                  </option>
                );
              })}
            </StyledSySelect>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : yearsOfCareerBreak === "Sim" && ( 
            <StyledSySelect
              label="Anos de experiência antes do intervalo"
              onSySelectDidChange={(e) => {
                setYearsOfExperience((e.target as HTMLSySelectElement).value!);
              }}
              error={errors.yearsOfExperience}
            >
              {formOptions.yearsOfExperience.map((yearsOfExperience, index) => {
                return (
                  <option key={index} value={yearsOfExperience}>
                    {yearsOfExperience}
                  </option>
                );
              })}
            </StyledSySelect>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : yearsOfCareerBreak === "Sim" && (
            <StyledSySelect
              label="Motivo para intervalo de carreira"
              onSySelectDidChange={(e) => {
                setReasonForCareerBreak(
                  (e.target as HTMLSySelectElement).value!
                );
              }}
              error={errors.reasonForCareerBreak}
            >
              {formOptions.reasonForCareerBreak.map((reasonForCareerBreak) => {
                return (
                  <option
                    key={reasonForCareerBreak.id}
                    value={reasonForCareerBreak.id}
                  >
                    {reasonForCareerBreak.name}
                  </option>
                );
              })}
            </StyledSySelect>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : (
            <StyledSySelect
              label="Onde nos encontrou *"
              onSySelectDidChange={(e) => {
                setWhereDidYouFindUs((e.target as HTMLSySelectElement).value!);
              }}
              error={errors.whereDidYouFindUs}
            >
              {formOptions.whereDidYouFindUs.map((whereDidYouFindUs) => {
                return (
                  <option key={whereDidYouFindUs.id} value={whereDidYouFindUs.id}>
                    {whereDidYouFindUs.name}
                  </option>
                );
              })}
            </StyledSySelect>
          )}

          {isLoading ? (
            <SyProgressBar
              has-background=""
              style={{
                width: "100%",
                height: "30px",
                borderRadius: 0,
                marginBottom: 10,
              }}
              type="indeterminate"
              class="hydrated"
            />
          ) : (
            <div className="checkGroup">
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="lgpd"
                  checked={useTerms}
                  onChange={() => setUseTerms((prevState) => !prevState)}
                />
                <label htmlFor="lgpd">
                  {" "}
                  Aceito os{" "}
                  <a
                    href="/politica-de-privacidade"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Termos de Uso
                  </a>
                </label>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="newsletterSubscription"
                  checked={newsletterSubscription}
                  onChange={() =>
                    setNewsletterSubscription((prevState) => !prevState)
                  }
                />
                <label htmlFor="newsletterSubscription">
                  {" "}
                  Aceito receber emails com conteúdo da Be Back Now
                </label>
              </div>
            </div>
          )}
        </form>

        <StyledSyButton onClick={handleRegisterNewUser} loading={isLoading}>
          Registrar
        </StyledSyButton>

        <p>Ou registre-se com rede social</p>

        <SocialLogin>
          <span className="googleLogin" onClick={handleGoogleLogin}>
            <img src={logoGoogle} alt="Google Logo" />
          </span>
          <span className="linkedinLogin" onClick={handleLinkedinLogin}>
            <img src={logoLinkedin} alt="Linkedin Logo" />
          </span>
        </SocialLogin>
      </RightSection>
    </Container>
  );
}