import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 120px 60px 0;

    @media (max-width: 700px) {
        padding: 120px 40px 0;
    }
    @media (max-width: 425px) {
        padding: 120px 30px 0;
    }
`;

export const Content = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;

    @media (min-width: 1441px) {
        width: 90%;
    }
    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const Description = styled.section`
    display: flex;
    flex: 40%;
    flex-direction: column;
    gap: 20px;

    h1 {
        font-size: 2rem;
        font-weight: bolder;
        color: #ff6600;
    }

    span {
        color: #ff6600;
        font-weight: bolder;
        font-size: 1.2rem;
    }

    p { 
        margin: 0;
        font-size: 1.1rem;
    }

    @media (max-width: 1000px) {
        h1 {
            font-size: 1.4rem;
        }
    }
`;

export const VideoSection = styled.section`
    display: flex;
    flex: 60%;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    .iframe-container {
        width: 80%;
        max-width: 640px;
        height: 340px;

        @media (max-width: 700px) {
            width: 100%;
            max-width: none;
            height: auto;
        }
    }
`;