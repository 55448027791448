import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Container, DefaultView, Button } from "./style";
import backgroundMobile from "./assets/banner01_mobile.png";
import background from "./assets/banner01.png";

export function NotFoundPage() {
    const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
    const navigate = useNavigate();

    return (
        <Container>
            <DefaultView backgroundImage={!isMobile ? background : backgroundMobile}>
                <div className="message">
                    <h2>Ops!</h2>
                    <p>A página que você está procurando não existe.</p>
                </div>

                <Button onClick={() => navigate("/")}>
                    Ir para a página inicial
                </Button>
            </DefaultView>
        </Container>
    );
}