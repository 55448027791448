import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 140px 60px 0px;

    a {
        color: #FF7B24;
        text-decoration: underline !important; /* Add or remove decorations as needed */
        font-weight: bold;
    }

    table {
        border-collapse: collapse;
        width: 100%;

        td,
        th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }
    }

    @media (max-width: 700px) {
        padding: 140px 40px 0px;

        h1 {
            font-size: 2rem !important;
        }

        h2 {
            font-size: 1.6rem !important;
        }
    }
`;

export const Intro = styled.div`
    img {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        object-position: top;
        border-radius: 15px;
    }
`;

export const Content = styled.div`
    padding: 40px 220px;

    @media (max-width: 1000px) {
        padding: 40px 60px;
    }
    @media (max-width: 700px) {
        padding: 40px 20px;
    }
`;