import React, { useState } from "react";

import { X } from "phosphor-react";
import {
  Container,
  Content,
  Footer,
  Overlay,
  StyledSyButton,
  StyledSyInput,
} from "./style";
import sendRequest from "../../../services/sendRequest";
import { toast } from "react-toastify";

interface EmailModalProps {
  isEmailModalOpen: boolean;
  setIsEmailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function EmailModal({
  isEmailModalOpen,
  setIsEmailModalOpen,
}: EmailModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState("");

  async function handleSendEmail() {
    setIsLoading(true);
    await sendRequest
      .sendNewEmail({ email })
      .then((res) => {
        toast.success(`Enviamos um novo email para sua caixa de entrada`, {
          toastId: "email-modal-01-success",
        });
      })
      .catch((err) => {
        toast.error(`Não foi possível fazer o reenvio do email`, {
          toastId: "email-modal-error",
        });
      });
    setIsLoading(false);
    setIsEmailModalOpen(false);
  }

  isEmailModalOpen
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  return (
    <Overlay isAuthModalOpen={isEmailModalOpen}>
      <Container isAuthModalOpen={isEmailModalOpen}>
        <div className="close" onClick={() => setIsEmailModalOpen(false)}>
          <X />
        </div>
        <Content>
          <h3>Reenviar email com código</h3>
          <main>
            <p>Lembre-se de conferir sua caixa de spam</p>
            <StyledSyInput
              label="Email"
              value={email}
              onSyInputDidInput={(e) =>
                setEmail((e.target as HTMLInputElement).value)
              }
            />
          </main>
          <Footer>
            <StyledSyButton
              background="var(--light-orange)"
              color="#fff"
              loading={isLoading}
              onClick={handleSendEmail}
            >
              Reenviar email
            </StyledSyButton>
          </Footer>
        </Content>
      </Container>
    </Overlay>
  );
}
