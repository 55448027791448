import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Container, ImageWrapper, Text } from './style'

interface BannerProps {
  title: string;
  description: string;
  image: string;
  button: boolean;
  route: string | null;
}

export function Banner(props: BannerProps) {
  const navigate = useNavigate();

  return (
    <Container>
      <Text>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
        {props.button && (
          <button onClick={() => navigate(props.route!)}>
            {props.button ? props.button : <div><b>Be Back</b> now</div>}
          </button>
        )}
      </Text>
      <ImageWrapper image={props.image}>
        <div className="content"></div>
      </ImageWrapper>
    </Container>
  );
}