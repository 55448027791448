import { SyCard, SyInput, SyPaginator, SySelect } from "@sydle/sydle-ui-react";
import styled from "styled-components";

export const Container = styled.main`
  position: relative;
  top: 80px;
  padding: 4rem var(--page-lateral-padding);

  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      color: var(--light-orange);
    }
  }
`;

export const Filters = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 680px) {
    flex-direction: column;
  }
`;

interface OpportunitiesCardsProps {
  isEmpty: number | undefined;
}

export const OpportunitiesCards = styled.div<OpportunitiesCardsProps>`
  display: ${(props) => (props.isEmpty === 0 ? "flex" : "grid")};
  grid-template-columns: repeat(4, 1fr);
  margin-top: 2rem;
  gap: 1rem;

  > p {
    grid-column: 1/4;
    margin: 2rem auto 0;
  }

  @media (max-width: 1420px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledSyInput = styled(SyInput)`
  --sy-select-border-color-focus: var(--light-orange);
  --sy-select-color-label-focus: var(--light-orange);
  --sy-select-dropdown-search-highlight-color: var(--light-orange);
`;

export const StyledSySelect = styled(SySelect)`
  --sy-select-border-color-focus: var(--light-orange);
  --sy-select-color-label-focus: var(--light-orange);
  --sy-select-dropdown-search-highlight-color: var(--light-orange);
`;

export const StyledSyCard = styled(SyCard)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;

  :hover sy-card-header,
  :hover sy-card-content,
  :hover sy-card-footer {
    background-color: rgba(255, 102, 0, 0.1);
  }

  sy-card-header {
    padding: 1rem;
    background-color: transparent;
    text-align: center;

    > p {
      color: var(--light-orange);
      font-size: 1.1rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    > small {
      font-size: 0.8rem;
    }
  }

  sy-card-content {
    padding: 1rem;
    overflow: auto;
    flex: 1;

    p {
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5rem;
      }
    }
  }

  sy-card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font-size: 0.75rem;

    > span {
      margin-left: 0.25rem;
    }
  }
`;

export const StyledSyPaginator = styled(SyPaginator)`
  margin-top: 1rem;
`;
