import styled from 'styled-components';

interface ContainerProps {
  image: string;
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  background-color: #fff;

  height: 100%;
  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;

  @media (max-width: 1200px) {
    background-position: right;
  }

  @media (max-width: 1000px) {
    background-position: left;
  }

  @media (max-width: 768px) {
    background-color: var(--light-orange);
    background-image: none;
  }
`;


export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem 0 2rem 4rem;
  height: 350px;

  h2,
  p {
    max-width: 50%;
    color: var(--dark-grey);

    @media (max-width: 768px) {
      color: #fff;
    }
  }

  p {
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }

  button {
    grid-column: 2;
    padding: 1rem 1.75rem;
    margin-top: 2rem;
    width: max-content;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 1px;
    border: none;
    color: #fff;
    background-color: var(--light-orange);
    cursor: pointer;
    justify-self: flex-end;
  }

  @media (max-width: 768px) {
    height: 200px;
    padding: 2rem;
  }
`;