import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
`;

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 30px;
    background: white;
    box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin: 80px 20px 0;
    padding: 50px;

    a {
        color: #FF7B24;
    }
`;

export const Form = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;

    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        background: white;
        box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        margin: 80px 20px 0;
        padding: 50px;

        .radio-buttons-label {
            color: #000;
        }

        .btn {
            appearance: none;
            border: none;
            margin: 0;
            padding: 0;
            background: none;
    
            padding: 15px 30px;
            margin-top: 15px;
            background-color: #FF7B24;
            color: white;
            border-radius: 7.29px;
            border: 1.46px white solid;
            width: 150px;
            font-weight: 600;
        }

        .btn-active {
            cursor: pointer;

            &:hover {
                background-color: white;
                color: #FF7B24;
                border: 1.46px #FF7B24 solid;
            }
        }
    }

    @media (max-width: 480px) {
        .radio-button {
            margin: 10px 0;
        }

        .radio-buttons-label {
            text-align: center;
        }
    }
`;