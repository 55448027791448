import styled from "styled-components";

export const WhatsappButton = styled.a`
  	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: #25d366;
	color: #fff;
	border-radius: 50px;
	text-align: center;
	font-size: 30px;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const MainContainer = styled.main`
	font-family: "Poppins", sans-serif !important;
	width: 100%;
	margin: 0;
	padding: 0;
`;

export const DefaultView = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	gap: 20px;

	background-image: url(${props => props.backgroundImage});
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100vh;
	padding: 100px;
	padding-left: 8%;

	.message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		gap: 10px;

		width: 45%;

		p {
			font-size: 1.2rem;
		}

		@media (max-width: 800px) {
			width: 100%;
			align-items: center;

			h2, p {
				text-align: center;
			}
		}
	}

	@media (max-width: 800px) {
		justify-content: end;
		align-items: center;
		gap: 15px;
		background-position: top;
        padding: 20px 40px 110px;
    }
`;

export const Button = styled.button`
    appearance: none;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    cursor: pointer;

    padding: 15px 30px;
    background-color: #FF7B24;
    color: white;
    border-radius: 7.29px;
    border: 1.46px white solid;
    width: 200px;
    font-weight: 600;

    &:hover {
        background-color: white;
        color: #FF7B24;
        border: 1.46px #FF7B24 solid;
    }
`;