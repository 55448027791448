import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, ImageWrapper, Text } from "./style";

interface ContentBannerProps {
  title: string;
  description: string;
  image: string;
  button: boolean;
  route?: string | null;
}

export function ContentBanner(props: ContentBannerProps) {
  const navigate = useNavigate();

  return (
    <Container>
      <Text>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
        {props.button && (
          <button onClick={() => navigate(props.route!)}>
            <b>Be Back</b> Now
          </button>
        )}
      </Text>
      <ImageWrapper image={props.image}>
        <div className="content"></div>
      </ImageWrapper>
    </Container>
  );
}
