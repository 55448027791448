import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 80px;

    .carousel-root {
        div {
            cursor: pointer;
        }

        .carousel .control-dots {
            .dot.selected {
                background-color: #ff7b24;
            }

            .dot {
                width: 12px;
                height: 12px;
            }
        }
    }

    p {
        margin-bottom: 0;
    }
`;

export const Intro = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    gap: 60px;
    padding: 90px 60px;
    background-color: #FF7b24;
    width: 100%;

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: stretch;
        justify-content: start;
    }
    @media (max-width: 480px) {
        padding: 30px;
    }
`;

export const Description = styled.div`
    flex: 40%;

    h2,
    p {
        color: #fff;
    }
`;

export const VideoSection = styled.section`
    flex: 60%;

    .iframe-container {
        height: 400px;
    }
`;

export const ContentSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 90px 60px;
    gap: 90px;

    h2 {
        color: #FF7b24;
    }

    .MuiTabs-flexContainer {
        display: flex;
        justify-content: center;
    }

    @media (max-width: 480px) {
        padding: 30px;

        h2 {
            font-size: 1.5rem;
            margin: 0 !important;
            text-align: center;
        }
    }
`;

export const MainCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 80%;
    background: white;
    box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    img {
        flex: 1;
        width: 100%;
        max-height: 250px;
        object-fit: cover;
        object-position: top;
        border-radius: 15px 15px 0 0;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        gap: 19px;
        padding: 36px;
        text-align: center;
        cursor: pointer;

        p:first-child {
            color: #303030;
            font-size: 1.5rem;
            font-family: Inter;
            font-weight: 600;
            text-transform: uppercase;
            word-wrap: break-word;
        }

        p:nth-child(2) {
            color: #303030;
            font-size: 1.1rem;
            font-family: Inter;
            font-weight: 400;
            line-height: 30px;
            word-wrap: break-word;
        }
    }

    button {
        appearance: none;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        cursor: pointer;

        padding: 15px 30px;
        background-color: #FF7B24;
        color: white;
        border-radius: 7.29px;
        border: 1.46px white solid;
        width: 200px;
        font-weight: 600;

        &:hover {
            background-color: white;
            color: #FF7B24;
            border: 1.46px #FF7B24 solid;
        }
    }

    @media (max-width: 1000px) {
        width: 100%;
    }
`;

export const CardSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(33.33% - 20px);
    align-items: start;
    justify-content: start;
    max-width: 33%;
    background: white;
    box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.02);
    }

    img {
        width: 100%;
        height: 100%;
        max-height: 250px;
        object-fit: cover;
        border-radius: 15px 15px 0 0;
    }

    div {
        display: inline-flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        gap: 19px;
        padding: 36px;

        p:first-child {
            color: #303030;
            font-size: 1.1rem;
            font-family: Inter;
            font-weight: 600;
            text-transform: uppercase;
            word-wrap: break-word;
        }

        p:nth-child(2) {
            color: #303030;
            font-size: 1rem;
            font-family: Inter;
            font-weight: 400;
            line-height: 30px;
            word-wrap: break-word;
        }
    }

    span {
        color: #ff7b24;
        font-weight: bold;
    }

    @media (max-width: 1000px) {
        flex: 1 1 calc(50% - 20px);
        max-width: 50%;
    }
    @media (max-width: 700px) {
        flex-basis: 100%;
        max-width: unset;
    }
`;

export const BlogSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

export const EbookSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;

    ${Card} {
        flex: 1 1 calc(33% - 20px);
        max-width: 33%;

        img {
            height: 250px;
        }

        @media (max-width: 769px) {
            flex: 1 1 calc(50% - 20px);
            max-width: 50%;
        }
        @media (max-width: 600px) {
            flex-basis: 100%;
            max-width: unset;
        }
    }

    @media (max-width: 1000px) {
        width: 100%;
    }
`;

export const WorkshopSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;

    @media (max-width: 1000px) {
        width: 100%;
    }
`;

export const WorkshopMainCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 80%;
    background: white;
    box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    .banner {
        flex: 1;
        width: 100%;
        max-height: 250px;
        object-fit: cover;
        border-radius: 15px 15px 0 0;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 19px;
        padding: 36px;
        text-align: center;
        cursor: pointer;

        .description {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .date {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                gap: 10px;
                flex: 1;

                .dateItem {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    p {
                        white-space: nowrap;
                    }
                }
            }

            .text {
                flex: 1;
            }

            .separator {
                height: 80%;
            }

            @media (max-width: 700px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0;

                .separator {
                    transform: rotate(90deg);
                }
            }
        }

        p:first-child {
            color: #303030;
            font-size: 1.5rem;
            font-family: Inter;
            font-weight: 600;
            text-transform: uppercase;
            word-wrap: break-word;
        }

        p:nth-child(2), p:nth-child(3) {
            color: #303030;
            font-size: 1.1rem;
            font-family: Inter;
            font-weight: 400;
            line-height: 30px;
            word-wrap: break-word;
        }
    }

    button {
        appearance: none;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        cursor: pointer;

        padding: 15px 30px;
        background-color: #FF7B24;
        color: white;
        border-radius: 7.29px;
        border: 1.46px white solid;
        width: 200px;
        font-weight: 600;

        &:hover {
            background-color: white;
            color: #FF7B24;
            border: 1.46px #FF7B24 solid;
        }
    }

    @media (max-width: 1000px) {
        width: 100%;
    }
`;