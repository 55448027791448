import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: start;
`;

export const DefaultView = styled.div<any>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	gap: 20px;

	background-image: url(${props => props.backgroundImage});
	background-size: cover;
	background-position: center;
	width: 100%;
	height: calc(100vh - 80px);
	padding: 100px;
	padding-left: 8%;
	margin: 80px 0 -80px;

	.message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		gap: 10px;

		width: 45%;

		p {
			font-size: 1.2rem;
		}

		@media (max-width: 800px) {
			width: 100%;
			align-items: center;

			h2, p {
				text-align: center;
			}
		}
	}

	@media (max-width: 800px) {
		justify-content: end;
		align-items: center;
		gap: 15px;
		background-position: top;
        padding: 20px 40px 110px;
    }
`;

export const Button = styled.button`
    appearance: none;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    cursor: pointer;

    padding: 15px 30px;
    background-color: #FF7B24;
    color: white;
    border-radius: 7.29px;
    border: 1.46px white solid;
    width: 240px;
    font-weight: 600;

    &:hover {
        background-color: white;
        color: #FF7B24;
        border: 1.46px #FF7B24 solid;
    }
`;