import { SyAccordionItem, SyButton } from "@sydle/sydle-ui-react";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  top: 80px;

  display: grid;
  grid-template-columns: 65% 35%;
  min-height: 60vh;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftSection = styled.main`
  padding: 3rem;

  header {
    h1 {
      font-size: 2rem;
      color: var(--light-orange);

      span {
        font-size: 1rem;
        font-weight: 400;
        color: #000;

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    .infos {
      span {
        display: inline-flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 30px;
        }

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  sy-divider {
    margin: 1.5rem 0;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--light-orange);
  }

  article {
    & + article {
      margin-top: 2rem;
    }
  }

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

export const RightSection = styled.aside`
  padding: 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f6;

  h2 {
    color: var(--dark-orange);
  }

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

export const StyledSyAccordionItem = styled(SyAccordionItem)`
  --sy-accordion-item-content-padding: 1rem;
  --sy-accordion-item-header-background-color: var(--card-unhovered-orange);
  --sy-accordion-item-header-background-color-hover: var(--card-hovered-orange);
  --sy-accordion-item-header-background-color-active: var(
    --card-unhovered-orange
  );

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const StyledSyButton = styled(SyButton)`
  margin: 1rem auto 0;
  --sy-button-padding: 1rem;
  --sy-button-font-weight: 500;
  --sy-button-letter-spacing: 1px;
  --sy-button-border-radius: 5px;
  --sy-button-width: 70%;

  --sy-button-background-color: var(--light-orange);
  --sy-button-background-color-hover: var(--light-orange);
  --sy-button-background-color-active: var(--light-orange);
  --sy-button-border-color: var(--light-orange);
  --sy-button-border-color-hover: var(--light-orange);
  --sy-button-border-color-active: var(--light-orange);
  --sy-button-color: #fff;
  --sy-button-color-hover: #fff;
  --sy-button-color-active: #fff;

  @media (max-width: 900px) {
    --sy-button-width: 100%;
  }

  &:hover {
    opacity: 0.85;
  }
`;
