import React from 'react'
import { Container, TextContainer } from './style'

import * as Icon from 'phosphor-react';
import { useNavigate } from 'react-router-dom';

interface CardProps {
  title: string;
  image: string;
  route: string;
}

export function Card({title, image, route}: CardProps) {
  const navigate = useNavigate();

  function handleCardClick(route: string) {
    navigate(route)
  }

  return (
    <Container onClick={() => handleCardClick(route)}>
      <img src={image} alt="" />
      <TextContainer>
        <p>{title}</p>
        <Icon.ArrowRight size={32} weight="bold" />
      </TextContainer>
    </Container>
  )
}