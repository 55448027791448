import styled from "styled-components";
import background from "./assets/white.png";
import parse from "html-react-parser";
import { htmlData } from "./data";

const PrivacyPolicyContainer = styled.div`
  position: relative;
  top: 80px;
  
  padding: 30px 12%;
  font-family: Inter, sans-serif;
  text-align: justify;

  background-image: url(${background});
  background-color: #fcfcfc;
  background-position: center;
  background-repeat: repeat-y;

  @media (max-width: 769px) {
    padding: 15px 3%;
  }
`;

const Paper = styled.div`
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  border-radius: 4px;
  padding: 3% 6%;
  font-family: Inter, sans-serif;
  color: #1f1f1f;

  h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #1f1f1f;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #1f1f1f;
  }

  h3 {
    font-size: 1em;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #1f1f1f;
  }

  p {
    margin-bottom: 15px;
    color: #1f1f1f;
  }

  ul,
  ol {
    margin-bottom: 15px;
    color: #1f1f1f;
  }

  li {
    margin-bottom: 5px;
    color: #1f1f1f;
  }

  a {
    color: #FF7B24;
  }

  /* Media queries para dispositivos móveis */
  @media (max-width: 769px) {
    h1 {
      font-size: 1.5em;
    }

    h2 {
      font-size: 1.2em;
    }

    h3 {
      font-size: 0.9em;
    }

    p,
    ul,
    ol,
    li {
      font-size: 0.9em;
    }
  }
`;

export const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyContainer>
      <Paper>{parse(htmlData)}</Paper>
    </PrivacyPolicyContainer>
  );
};
