import { useContext, useEffect, useState } from "react";
import sendRequest from "../../services/sendRequest";
import DOMPurify from 'dompurify';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Tabs, Tab, Box } from '@mui/material';
import {
    Container,
    Intro,
    Description,
    VideoSection,
    ContentSection,
    EbookSection,
    WorkshopSection,
    WorkshopMainCard,
    MainCard,
    Card,
    CardSection,
    BlogSection,
} from "./style";
import MoonLoader from "react-spinners/MoonLoader";
import Calendar from "./assets/calendar.png";
import LocationPin from "./assets/location.png";
import Separator from "./assets/separator.png";
import AuthContext from "../../contexts/Auth/AuthContext";
import { toast } from "react-toastify";

export function Academy(props: any) {
    const [data, setData] = useState<any>();
    const [academyContent, setAcademyContent] = useState<any>();
    const [blogMainCards, setBlogMainCards] = useState([]);
    const [blogCards, setBlogCards] = useState([]);
    const [workshopMainCards, setworkshopMainCards] = useState([]);
    const [workshopCards, setWorkshopCards] = useState([]);
    const [loader, setLoader] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const { setIsAuthModalOpen } = useContext(AuthContext);
    const { displayModal } = props;
    const apiUrl = process.env.REACT_APP_SYDLE_ONE_ENDPOINT;

    useEffect(() => {
        async function getData() {
            await sendRequest.getAcademyContent().then((data) => {
                setData(data.response);
                setAcademyContent(data.response.content);
                setBlogMainCards(data.response.articles.filter((item: any) => item.isHighlightArticle));
                setBlogCards(data.response.articles.filter((item: any) => !item.isHighlightArticle));
                setworkshopMainCards(data.response.workshops.filter((item: any) => item.active));
                setWorkshopCards(data.response.workshops.filter((item: any) => !item.active));
                setLoader(false);
            });
        }

        setIsAuthModalOpen(displayModal);
        getData();

        window.scrollTo(0, 0); // Garante que a página irá carregar no topo
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const hash = window.location.hash;

        if (hash) {
            const targetDiv = document.querySelector(hash);
            if (targetDiv) {
                targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
                return;
            }

            handleScrollIntoViewMobile(hash);
        }
    }, [data]);
    
    const handleScrollIntoViewMobile = (hash: string) => {
        switch(hash) {
            case "#ebooks":
                setTabValue(0);
                const ebookDiv = document.getElementById('tabpanel-0');
                if (ebookDiv) {
                    ebookDiv.scrollIntoView({ behavior: 'smooth'});
                }
                break;

            case "#workshops":
                setTabValue(1);
                const workshopDiv = document.getElementById('tabpanel-1');
                if (workshopDiv) {
                    workshopDiv.scrollIntoView({ behavior: 'smooth'});
                }
                break;

            case "#roteiros":
                setTabValue(2);
                const articleDiv = document.getElementById('tabpanel-2');
                if (articleDiv) {
                    articleDiv.scrollIntoView({ behavior: 'smooth'});
                }
                break;
        }
    }

    const verifyUserAuthenticationBeforeRedirecting = (route: string): React.MouseEventHandler<HTMLDivElement> | undefined => {
        const storageUser = localStorage.getItem("@bebacknow:user");
        const parsedUser = storageUser ? JSON.parse(storageUser) : null;

        if (parsedUser) {
            window.open(route, "_blank");
            return;
        }
        
        setIsAuthModalOpen(!parsedUser);
    }

    const handleChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    const verifyUserAuthenticationBeforeDownloading = async (ebookID: string) => {
        const storageUser = localStorage.getItem("@bebacknow:user");
        const parsedUser = storageUser ? JSON.parse(storageUser) : null;

        if (parsedUser) {
            try {
                const params = {
                    email: parsedUser.login,
                    ebookID
                }
                const { filePath, filename } = await sendRequest.downloadEbook(params);

                if (filePath && filename) {
                    downloadFile(apiUrl + filePath);

                    toast.success("Download realizado", {
                        toastId: "academy-01-success",
                    });

                    (window as any).dataLayer.push({
                        event: "ebook_download",
                        ebook_name: filename
                    });
                }
            } catch (error) {
                console.log(error);
                toast.error("Não foi possível realizar o download do ebook. Tente novamente mais tarde.", {
                    toastId: "academy-01-error",
                })
            }
        }
        
        setIsAuthModalOpen(!parsedUser);
    }

    const downloadFile = (url: string) => {
        const anchor = document.createElement('a');
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    return (
        <Container>
            <MoonLoader
                color={"#FF7b24"}
                loading={loader}
                size={80}
                aria-label="Loading Spinner"
                data-testid="loader"
                cssOverride={{
                    margin: "160px 0",
                }}
            />
            {!loader && (
                <>
                    <Carousel
                        showThumbs={false}
                        showArrows={true}
                        showStatus={false}
                        autoPlay={true}
                        interval={10000}
                        infiniteLoop={true}
                        stopOnHover={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={30}
                        emulateTouch={true}
                    >

                        {academyContent.carousel.map((item: any) => (
                            <div 
                                key={item.id}
                                onClick={() => {
                                    window.open(item.route, "_blank");
                                }}
                            >
                                <img src={apiUrl + "site/pages/academy/downloadImage/" + item.id} alt="" />
                            </div>
                        ))}
                    </Carousel>

                    <Intro>
                        <Description>
                            <h2>{academyContent.academyTitle}</h2>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        academyContent.academyIntro
                                    )
                                }}
                            />
                        </Description>
                        <VideoSection id="video">
                            <div className="iframe-container">
                                <iframe
                                    src={academyContent.introVideo}
                                    title="Home Video"
                                    allowFullScreen
                                    loading="lazy"
                                    width="100%"
                                    height="100%"
                                ></iframe>
                            </div>
                        </VideoSection>
                    </Intro>

                    <ContentSection>
                        <Box sx={{ width: '100%' }}>
                            <Tabs value={tabValue} onChange={handleChange} aria-label="Academy Content">
                                <Tab label="Ebooks" sx={{ width: '33.33%' }} />
                                <Tab label="Workshops" sx={{ width: '33.33%' }} />
                                <Tab label="Roteiros" sx={{ width: '33.33%' }} />
                            </Tabs>

                            <div
                                role="tabpanel"
                                hidden={tabValue !== 0}
                                id={`tabpanel-0`}
                                aria-labelledby={`tab-0`}
                            >
                                <Box sx={{ padding: "30px 0", display: "flex", justifyContent: "center" }}>
                                    <EbookSection id="ebookSection">
                                        <h2>{academyContent.ebookSectionTitle}</h2>
                                        <CardSection>
                                            {data.ebooks && data.ebooks.map((ebook: any) => (
                                                <Card
                                                    key={ebook.id}
                                                    onClick={() => verifyUserAuthenticationBeforeDownloading(ebook.id)}
                                                    role="button"
                                                >
                                                    <img
                                                        src={apiUrl + "site/pages/ebook/downloadImage/" + ebook.id}
                                                        alt="Banner do Ebook"
                                                    />
                                                    <div>
                                                        <p>{ebook.title}</p>
                                                        <p>{ebook.subtitle}</p>
                                                        <span>Baixar</span>
                                                    </div>
                                                </Card>
                                            ))}
                                        </CardSection>
                                    </EbookSection>
                                </Box>
                            </div>

                            <div
                                role="tabpanel"
                                hidden={tabValue !== 1}
                                id={`tabpanel-1`}
                                aria-labelledby={`tab-1`}
                            >
                                <Box sx={{ padding: "30px 0px", display: "flex", justifyContent: "center" }}>
                                    <WorkshopSection>
                                        <h2>{academyContent.workshopSectionTitle}</h2>
                                        {workshopMainCards.length > 0 && (
                                            <CardSection>
                                                {workshopMainCards.map((card: any) => (
                                                    <WorkshopMainCard key={card.id}>
                                                        <img
                                                            className="banner"
                                                            src={apiUrl + "site/pages/workshop/downloadImage/" + card.id}
                                                            alt="Workshop em Destaque"
                                                        />
                                                        <div
                                                            className="content"
                                                            onClick={() => {
                                                                window.open("/workshop","_blank");
                                                            }}
                                                        >
                                                            <p>{card.title}</p>
                                                            <div className="description">
                                                                <div className="date">
                                                                    <div className="dateItem">
                                                                        <img
                                                                            src={Calendar}
                                                                            alt="Ícone de Calendário"
                                                                            width={35}
                                                                            height={35}
                                                                        />
                                                                        <p>{card.date}</p>
                                                                    </div>
                                                                    <div className="dateItem">
                                                                        <img
                                                                            src={LocationPin}
                                                                            alt="Ícone de Localização"
                                                                            width={33}
                                                                            height={28}
                                                                        />
                                                                        <p>{card.modality}</p>
                                                                    </div>
                                                                </div>
                                                                <img
                                                                    className="separator"
                                                                    src={Separator}
                                                                    alt="Separator"
                                                                />
                                                                <p className="text">
                                                                    {card.cardDescription}
                                                                </p>
                                                            </div>
                                                            <button
                                                                onClick={() => {
                                                                    window.open("/workshop","_blank");
                                                                }}
                                                            >
                                                                Inscreva-se
                                                            </button>
                                                        </div>
                                                    </WorkshopMainCard>
                                                ))}
                                            </CardSection>
                                        )}

                                        <CardSection>
                                            {workshopCards.length > 0 && workshopCards.map((card: any) => (
                                                <Card
                                                    key={card.id}
                                                    onClick={() => verifyUserAuthenticationBeforeRedirecting("/workshop/" + card.slug)}
                                                    role="button"
                                                >
                                                    <img
                                                        src={apiUrl + "site/pages/workshop/downloadImage/" + card.id}
                                                        alt="Banner do Workshop"
                                                    />
                                                    <div>
                                                        <p>{card.title}</p>
                                                        <p>{card.cardDescription}</p>
                                                        <span>Ver mais</span>
                                                    </div>
                                                </Card>
                                            ))}
                                        </CardSection>
                                    </WorkshopSection>
                                </Box>
                            </div>

                            <div
                                role="tabpanel"
                                hidden={tabValue !== 2}
                                id={`tabpanel-2`}
                                aria-labelledby={`tab-2`}
                            >
                                <Box sx={{ padding: "30px 0px", display: "flex", justifyContent: "center" }}>
                                    <BlogSection>
                                        <h2>{academyContent.articleSectionTitle}</h2>
                                        <CardSection>
                                            {blogMainCards &&
                                                blogMainCards.map((card: any) => (
                                                    <MainCard key={card.id}>
                                                        <img
                                                            src={apiUrl + "site/pages/article/downloadImage/" + card.id}
                                                            alt="Roteiro em Destaque"
                                                        />
                                                        <div
                                                            onClick={() => verifyUserAuthenticationBeforeRedirecting("/artigo/" + card.slug)}
                                                        >
                                                            <p>{card.title}</p>
                                                            <p>{card.caption}</p>
                                                            <button
                                                                onClick={() => verifyUserAuthenticationBeforeRedirecting("/artigo/" + card.slug)}
                                                            >
                                                                Ler mais
                                                            </button>
                                                        </div>
                                                    </MainCard>
                                                ))}
                                        </CardSection>

                                        <CardSection>
                                            {blogCards &&
                                                blogCards.map((card: any) => (
                                                    <Card
                                                        key={card.id}
                                                        onClick={() => verifyUserAuthenticationBeforeRedirecting("/artigo/" + card.slug)}
                                                        role="button"
                                                    >
                                                        <img
                                                            src={apiUrl + "site/pages/article/downloadImage/" + card.id}
                                                            alt="Roteiro"
                                                        />
                                                        <div>
                                                            <p>{card.title}</p>
                                                            <p>{card.caption}</p>
                                                            <span>Ler mais</span>
                                                        </div>
                                                    </Card>
                                                ))}
                                        </CardSection>
                                    </BlogSection>
                                </Box>
                            </div>
                        </Box>
                    </ContentSection>
                </>
            )}
        </Container>
    );
}