import React from 'react'
import { Container, Text } from './style'

interface InspiringBannerProps {
  title: string;
  description: string;
  image: string;
  num: number;
}

export function InspiringBanner(props: InspiringBannerProps) {
  if(props.num > 0){
    return (
      <Container image={props.image}>
        <Text>
          <h2 id="inspiring">{props.title}</h2>
          <p>{props.description}</p>
        </Text>
      </Container>
    );
  } else {
    return (
      <div></div>
    )
  }

}