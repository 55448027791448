import { PlusCircle } from 'phosphor-react';
import { Container } from './style';

interface FileUploaderProps {
  file: string | ArrayBuffer | null;
  onFileSelect: (
    fileAsBase64: string | ArrayBuffer | null,
    fileName: string
  ) => void;
  isEditing: boolean;
}

export function FileUploader({ file, onFileSelect, isEditing }: FileUploaderProps) {
  function handleUploadFile(inputFiles: FileList | null) {
    const fileToLoad = inputFiles![0];
    
    if(fileToLoad) {
      const fileName = fileToLoad.name;

      // FileReader instance
      const fileReader = new FileReader();

      fileReader.onload = function (fileLoadedEvent) {
        const fileAsBase64 = fileLoadedEvent.target!.result;
        
        onFileSelect(fileAsBase64, fileName);
      };

      // convert to base64
      fileReader.readAsDataURL(fileToLoad);
    }
  }

  return isEditing ? (
    <Container>
      <div className="fileInputs">
        <input 
          type="file" 
          accept="application/pdf"
          onChange={(e) => handleUploadFile(e.target.files)} 
        />
        <button>
            <PlusCircle weight='fill' size={24}/> Currículo
        </button>
      </div>

      <p className="main">Anexe aqui seu currículo</p>
      <p className="info"><i>Formatos suportados: PDF</i></p>
    </Container>
  ) : null
}