import React, { Dispatch, SetStateAction, useState } from 'react'
import { Container } from './style'
import * as Icon from 'phosphor-react';

interface EventCardProps {
  id: string;
  type: string;
  title: string;
  description: string;
  date: string;
  time: string;
  location: string;
  soon: boolean;
  setIsAuthModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedEventId: Dispatch<SetStateAction<string>>;
  setSelectedEventName: Dispatch<SetStateAction<string>>;
  setSelectedEventDate: Dispatch<SetStateAction<string>>;
}

export function EventCard({
  id,
  type,
  title,
  description,
  date,
  time,
  location,
  soon,
  setIsAuthModalOpen,
  setIsConfirmationModalOpen,
  setSelectedEventId,
  setSelectedEventName,
  setSelectedEventDate
}: EventCardProps) {
  async function handleSelectEvent(eventId: string) {
    // * Verifica se o usuário está logado
    // * Se estiver logado, realizar inscrição
    // * Caso não esteja, descer o modal de autenticação
    // TODO Validar o token do usuário caso esteja logado
    const accessToken = localStorage.getItem('@bebacknow:accessToken') || false;

    if(!accessToken) {
      setIsAuthModalOpen(true);
    } else {
      setSelectedEventId(eventId);
      setSelectedEventName(title);
      setSelectedEventDate(date);
      setIsConfirmationModalOpen(true);
    }
  }

  return (
    <Container soon={soon} onClick={() => handleSelectEvent(id)}>
      <p>{type === 'seminary' ? "Presencial" : "Webinário"}</p>
      <h3>{title}</h3>
      <p className='description'>
        {description}
        <span className='tooltipText'>{description}</span>
      </p>
      <p className="info">
        <Icon.CalendarBlank weight="fill" size={20} />
        {date} às {time}h
      </p>
      <p className="info">
        <Icon.MapPin weight="fill" size={20} />
        {location}
      </p>
      <div className="circle">
        <Icon.PlusCircle weight="fill" size={40} />
      </div>
    </Container>
  );
}