import styled from "styled-components";

interface ContainerProps {
  soon: boolean;
}


export const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${(props) =>
    props.soon ? "var(--light-orange)" : "#DAE3F0"};
  padding: 2rem 1.5rem 0.5rem;
  width: 500px;
  border-radius: 15px;
  cursor: pointer;

  h3,
  p {
    max-width: 100%;
    color: ${(props) => (props.soon ? "#fff" : "#000")};
  }

  h3 {
    margin-top: 1rem;
    min-height: 5rem;
  }

  .description {
    display: inline-block;
    font-size: 14px;
    margin: 1rem 0;
    overflow: hidden;
    text-overflow: ellipsis; // Adicionando "..." ao final
    display: -webkit-box;
    -webkit-line-clamp: 3; // Quantidade de linhas
    -webkit-box-orient: vertical;
    min-height: 3.3rem;

    .tooltipText {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      width: 90%;
      background-color: var(--dark-grey);
      color: #fff;
      text-align: center;
      padding: 0.25rem;
      border-radius: 5px;

      position: absolute;
      bottom: 50px;
      right: 50%;
      transform: translateX(50%);
      z-index: 1;
    }

    &:hover .tooltipText {
      visibility: visible;
      opacity: 1;
    }
  }

  p.info {
    font-size: 14px;
    font-weight: 600;
    word-spacing: 1px;
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  div.circle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transform: translateX(10px);
    color: ${(props) => (props.soon ? "#fff" : "#000")};
  }

  &::before {
    position: absolute;
    top: 16px;
    right: 16px;
    content: "Em breve";
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-orange);
    font-size: 14px;
    font-weight: 600;
    padding: 0 0.5rem;
    width: 90px;
    height: 25px;
    border-radius: 50px;
    background-color: #fff;
    visibility: ${(props) => !props.soon && "hidden"};
  }
`;