import React from 'react'
import { Line } from './style'

interface DividerProps {
  color: "orange" | "grey";
}

export function Divider({color}: DividerProps) {
  return (
    <Line color={color} />
  )
}