import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { X } from "phosphor-react";
import { Container, Content, InputGroup, Overlay, SocialLogin } from "./style";
import { Button } from "./style";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/Auth/AuthContext";
import logoGoogle from "../../assets/logo-google.svg";
import logoLinkedin from "../../assets/logo-linkedin.svg";
import sendRequest from "../../services/sendRequest";

interface ModalProps {
  	route?: string;
}

export function AuthModal({ route = "/" }: ModalProps) {
	const [recoveringPassword, setRecoveringPassword] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [recoverCode, setRecoverCode] = useState("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { setIsAuthModalOpen, isAuthModalOpen } = useContext(AuthContext);
	const [isRecoveringPassword, setIsRecoveringPassword] = useState(false);
	const [searchParams] = useSearchParams();
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (window.location.href.indexOf("password-recovery") > 0) {
			setIsAuthModalOpen(true);
			setIsRecoveringPassword(true);
		} else {
			setIsRecoveringPassword(false);
		}
	}, [setIsAuthModalOpen, setIsRecoveringPassword, isRecoveringPassword]);

  	useEffect(() => {
		const token = String(searchParams.get("token"));

		if (token) {
			setRecoverCode(token);
		}
	}, []);

	async function handleSubmit() {
		setIsLoading(true);
		const isLogged = await authContext.login(email, password);

		if (isLogged) {
			navigate(route);
			setEmail("");
			setPassword("");
			setIsAuthModalOpen(false);
		}

		setIsLoading(false);
	}

	async function handleSubmitRecoveringPassword() {
		setIsLoading(true);
		let url = window.location.href;

		let response = await sendRequest.sendPasswordRecoveryEmail({
			email: email,
			url: url,
			password: password,
		});

		if (response && response.error) {
			toast.error(response.error, {
				toastId: "auth-modal-error",
			});
		} else {
			toast.success("E-mail de recuperação de senha enviado com sucesso.", {
				toastId: "auth-modal-01-success",
			});
			setIsAuthModalOpen(false);
			setIsRecoveringPassword(false);
			navigate("/");
		}

		setIsLoading(false);
	}

	async function finishingPasswordRecovering() {
		setIsLoading(true);

		if (password === passwordConfirm) {
			let response = await sendRequest.finishingPasswordRecovering({
				password: password,
				token: recoverCode,
			});

			if (response && response.error) {
				toast.error(response.error, {
				toastId: "auth-modal-2-error",
				});
			} else {
				toast.success("Senha alterada com sucesso.", {
					toastId: "auth-modal-02-success",
				});
				setIsAuthModalOpen(false);
				setIsRecoveringPassword(false);
				navigate("/");
			}
		} else {
			toast.error("As senhas não conferem.", {
				toastId: "auth-modal-3-error",
			});
		}

		setIsLoading(false);
	}

	async function handleGoogleLogin() {
		let redirectUrl = window.location.origin + "/profile";

		window.location.href =
			process.env.REACT_APP_LOGIN_API +
			"googleAuthenticator?one_redirect=" +
			redirectUrl;
	}

	async function handleLinkedinLogin() {
		let redirectUrl = window.location.origin + "/profile";

		window.location.href =
			process.env.REACT_APP_LOGIN_API +
			"linkedinAuthenticator?one_redirect=" +
			redirectUrl;
	}

	isAuthModalOpen
		? (document.body.style.overflow = "hidden")
		: (document.body.style.overflow = "auto");

	if (!isRecoveringPassword) {
		return (
			<Overlay isAuthModalOpen={isAuthModalOpen}>
				<Container isAuthModalOpen={isAuthModalOpen}>
					<Content>
						<h3>
							{!recoveringPassword
								? "Faça login para continuar"
								: "Recuperação de Senha"}
							</h3>
						<form>
							<InputGroup>
								<label htmlFor="email">E-mail</label>
								<input
									type="email"
									id="email"
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
							</InputGroup>

							{!recoveringPassword ? (
								<>
									<InputGroup>
										<label htmlFor="password">Senha</label>
										<input
											type="password"
											id="password"
											value={password}
											onChange={(e) => {
												setPassword(e.target.value);
											}}
										/>
									</InputGroup>
									<Button
										onClick={handleSubmit}
										disabled={isLoading}
										secondary={false}
										isFirst
									>
										Entrar
									</Button>
									<Button
										onClick={() => {
											navigate("/register");
											setIsAuthModalOpen(false);
										}}
										disabled={isLoading}
										secondary={true}
									>
										Registre-se
									</Button>

									<p>
										Esqueceu a senha?{" "}
										<button
											onClick={() => setRecoveringPassword(true)}
											style={{ border: 0, background: "white" }}
										>
										<span>Recuperar Senha</span>
										</button>
									</p>
									<SocialLogin>
										<p>Ou faça login com rede social</p>
										<section>
											<span className="googleLogin" onClick={handleGoogleLogin}>
												<img src={logoGoogle} alt="Google Logo" />
											</span>
											<span
												className="linkedinLogin"
												onClick={handleLinkedinLogin}
											>
												<img src={logoLinkedin} alt="Linkedin Logo" />
											</span>
										</section>
									</SocialLogin>
								</>
							) : (
								<>
									<Button
										onClick={handleSubmitRecoveringPassword}
										disabled={isLoading}
										secondary={false}
									>
										Enviar E-mail de Recuperação
									</Button>
									<Button
										onClick={() => {
											setRecoveringPassword(false);
										}}
										disabled={isLoading}
										secondary={true}
									>
										Voltar
									</Button>
								</>
							)}
						</form>
					</Content>
					<div className="close" onClick={() => setIsAuthModalOpen(false)}>
						<X />
					</div>
				</Container>
			</Overlay>
		);
	} else {
		return (
			<Overlay isAuthModalOpen={isAuthModalOpen}>
				<Container isAuthModalOpen={isAuthModalOpen}>
					<Content>
						<form>
							<h3>Recuperação de Senha</h3>

							<InputGroup>
								<label htmlFor="password">Digite sua nova senha</label>
								<input
									type="password"
									id="password"
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
								/>
							</InputGroup>

							<InputGroup>
								<label htmlFor="password">Confirme sua nova senha</label>
								<input
									type="password"
									id="password"
									value={passwordConfirm}
									onChange={(e) => {
										setPasswordConfirm(e.target.value);
									}}
								/>
							</InputGroup>

							<InputGroup>
								<label htmlFor="code">Digite o código de recuperação</label>
								<input
									type="number"
									id="code"
									min={10000}
									max={99999}
									value={recoverCode}
									onChange={(e) => {
										const input = e.target.value;

										if (parseInt(input) >= 0 && parseInt(input) <= 99999) {
											setRecoverCode(input);
											return;
										}
										setRecoverCode("");
									}}
								/>
								<br />
								<Button
									onClick={finishingPasswordRecovering}
									disabled={isLoading}
									secondary={false}
									style={{ marginBottom: 10 }}
								>
									Recuperar Senha
								</Button>

								<Button
									onClick={() => {
										setIsAuthModalOpen(false);
										setIsRecoveringPassword(false);
										navigate("/");
									}}
									disabled={isLoading}
									secondary={true}
								>
									Ir para página inicial
								</Button>
							</InputGroup>
						</form>
					</Content>
					<div
						className="close"
						onClick={() => {
							setIsAuthModalOpen(false);
							setIsRecoveringPassword(false);
							navigate("/");
						}}
					>
						<X />
					</div>
				</Container>
			</Overlay>
		);
	}
}