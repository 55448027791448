import { Container, Intro, Content } from "./style";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import sendRequest from "../../services/sendRequest";
import DOMPurify from 'dompurify';
import MoonLoader from "react-spinners/MoonLoader";


export function Article() {
    const [data, setData] = useState<any>(null);
    const { articleSlug } = useParams();
    const apiUrl = process.env.REACT_APP_SYDLE_ONE_ENDPOINT;

    useEffect(() => {
        async function getContent() {
            const payload = {
                articleSlug: articleSlug?.replace(/-/g, ' ')
            };

            await sendRequest.getArticle(payload).then(articleData => {
                setData(articleData.response);
            });
        }

        getContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <MoonLoader
                color={"#ff6600"}
                loading={!data}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
                cssOverride={{
                    margin: "100px 0",
                }}
            />

            {data && (
                <>
                    <Intro>
                        <img src={apiUrl + data.articleMethodPath + data.id} alt={data.fileName} />

                        <Content>
                            <h1>{data.title}</h1>

                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        data.description
                                    )
                                }}
                            />

                            {data.sections.length > 0 && data.sections.map(
                                (section: any, index: number) => (
                                    <div key={index}>
                                        <h2>{section.title}</h2>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    section.description
                                                ),
                                            }}
                                        />
                                        {section.subsections &&
                                            section.subsections.map(
                                                (
                                                    subsection: any,
                                                    index: number
                                                ) => (
                                                    <div key={index}>
                                                        <h2>
                                                            {subsection.title}
                                                        </h2>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: DOMPurify.sanitize(
                                                                    subsection.description
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            )}
                                    </div>
                                )
                            )}
                        </Content>
                    </Intro>
                </>
            )}
        </Container>
    );
}