import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  top: 80px;
`;

export const VideoSection = styled.div`
  padding: 4rem 20rem;
  width: 100%;
  background-color: var(--light-orange);

  .iframe-container {
    overflow: hidden;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;
  }

  .iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @media (max-width: 1200px) {
    padding: 4rem 16rem;
  }

  @media (max-width: 1000px) {
    padding: 4rem 10rem;
  }

  @media (max-width: 768px) {
    padding: 4rem;
  }
`;

export const SectionDivider = styled.div`
  width: 100%;
  height: 5rem;
  background-color: var(--light-orange);
`;
