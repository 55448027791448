import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	padding-top: 80px;

	p {
		font-size: 1.2rem;
	}
`;

export const Banner = styled.div<any>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	gap: 30px;

	background-image: url(${props => props.backgroundImageURL});
	background-size: cover;
	background-position: center;
	height: 600px;
	width: 100%;
	padding: 100px;
	padding-left: 8%;

	.message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		gap: 10px;

		width: 45%;

		p {
			font-size: 1.2rem;
		}

		h2, p {
			margin: 0;
		}

		@media (max-width: 800px) {
			width: 100%;
			align-items: center;

			h2, p {
				text-align: center;
			}
		}
	}

	@media (max-width: 800px) {
		justify-content: end;
		align-items: center;
		gap: 25px;
		background-position: top;
        padding: 20px 8% 50px;
    }
`;

export const Intro = styled.div<any>`
	.box {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
		background-color: #FF7b24;
		width: 100%;
		overflow: hidden;

		.description {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: start;
			flex: 50%;
			padding: 90px 8%;

			h2, p {
				color: #fff;
			}

			h2 {
				margin-bottom: 30px !important;
			}

			p {
				font-weight: 500;
			}

			@media (max-width: 768px) {
				flex: 50%;
			}
			@media (max-width: 480px) {
				padding: 20px 0;
			}
		}

		@media (min-width: 1441px) {
			max-height: 700px;
		}
	}

	.image {
		@media (max-width: 480px) {
			flex-direction: column;
			align-items: stretch;
			justify-content: start;
			padding: 60px 8%;
		}
	}

	.video {
		@media (max-width: 769px) {
			flex-direction: column;
			align-items: stretch;
			justify-content: start;
		}
		@media (max-width: 480px) {
			padding: 60px 8%;
		}
	}
`;

export const ImageSection = styled.div<any>`
	display: flex;
	flex: 50%;

	img {
		width: 100%;
		object-fit: cover;
	}
`;

export const VideoSection = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	flex: 50%;

	.iframe-container {
		iframe {
			width: 560px;
			height: 315px;

			@media (max-width: 1024px) {
				width: 460px;
				height: 258px;
			}
			@media (max-width: 480px) {
				width: 360px;
				height: 202px;
			}
			@media (max-width: 380px) {
				width: 315px;
				height: 177px;
			}
			@media (max-width: 340px) {
				width: 270px;
				height: 150px;
			}
		}
	}

	@media (max-width: 769px) {
		padding-bottom: 8%;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	gap: 90px;
	margin: 90px 0;

	@media (max-width: 480px) {
		gap: 60px;
	}
`;

export const Section = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: start;
	gap: 60px;
	padding: 0px 8%;

	.recruitment-description {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		width: 50%;

		h2 {
			margin-bottom: 30px !important;
		}

		@media (max-width: 768px) {
			width: 100%;
		}
		@media (max-width: 480px) {
			h2 {
				font-size: 1.6rem;
				margin-bottom: 15px !important;
			}
		}
		@media (max-width: 320px) {
			h2 {
				text-align: center;
			}
		}
	}

	img {
		width: 50%;
		object-fit: cover;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: start;
		align-items: center;
	}
`;

export const RecruitmentSupport = styled.div`
	ul {
		font-size: 1.2rem;
	}

	@media (max-width: 768px) {
		img {
			order: 1;
		}

		.recruitment-description {
			order: 2;
		}
	}
`;

export const Separator = styled.div`
	width: 100%;
	padding: 0 8%;

	hr {
		background-color: #FF7B24;
		opacity: 1;
	}
`;

export const ProgramSupport = styled.div`
`;

export const Button = styled.button`
    appearance: none;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    cursor: pointer;

    padding: 15px 30px;
    background-color: #FF7B24;
    color: white;
    border-radius: 7.29px;
    border: 1.46px white solid;
    width: auto;
    font-weight: 600;

    &:hover {
        background-color: white;
        color: #FF7B24;
        border: 1.46px #FF7B24 solid;
    }
`;

export const PartnersSection = styled.div<any>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 60px;
	padding: 0 8%;

	.partners-list {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		gap: 50px;
	}

	.logo {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 20px;

		flex: 1 1 calc(25% - 50px);
    	max-width: 25%;

		img {
			width: 100%;
			max-width: 120px;

			@media (max-width: 480px) {
				max-width: 100px;
			}
		}

		@media (max-width: 480px) {
			flex: 1 1 calc(50% - 50px);
    		max-width: 50%;
		}
	}

	@media (max-width: 480px) {
		h2 {
			text-align: center;
		}
	}
`;