import styled from "styled-components";

interface ContainerProps {
  	isAuthModalOpen: boolean;
}

export const Overlay = styled.div<ContainerProps>`
	position: fixed;
	z-index: 5;
	top: 0px;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,0.5);
	visibility: ${props => props.isAuthModalOpen ? 'visible' : 'hidden'};
	overflow: auto;
`;

export const Container = styled.div<ContainerProps>`
	position: relative;
	top: ${(props) => (props.isAuthModalOpen ? 395 : -2000)}px;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	background: #ffffff;
	width: 450px;
	padding: 30px;
	box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
	transition: 0.5s;
	visibility: ${(props) => (props.isAuthModalOpen ? "visible" : "hidden")};
	.close {
		position: absolute;
		top: 30px;
		right: 30px;
		cursor: pointer;
	}

	@media (max-width: 769px) {
		width: 90%;
		padding: 50px 30px 30px;

		.close {
			top: 20px;
			right: 20px;
		}
	}
`;

export const Content = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	> h3 {
		font-size: 1.5rem;
		font-weight: 500;
		margin-bottom: 0 !important;
	}

	p {
		text-align: center;
		font-size: 1rem;
	}

	form {
		width: 100%;
		margin: 25px 0;

		> p {
			margin-top: 1rem;

			span {
				color: var(--light-orange);
				font-weight: 500;
				cursor: pointer;
			}
		}
	}
`;

export const InputGroup = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 20px;

	input {
		padding: 10px;
		border: none;
		font: inherit;
		color: #000;
		background-color: transparent;
		outline: 1px solid #000;
		margin-top: 5px;
	}

	label {
		color: #000;
	}

	// Tratativa para remover as setas que aparecem na lateral para alterar o valor no input em questão
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type="number"] {
		-moz-appearance: textfield; // Firefox
	}
`;

export const SocialLogin = styled.div`
	width: 100%;
	text-align: center;

	p {
		font-size: 1rem;
	}

	section {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;
	}

	span {
		cursor: pointer;
		transition: 0.2s ease all;
		border-radius: 4px;
	}

	.linkedinLogin {
		padding: 0.75rem 4rem;
		background-color: #0077b5;
		border: 1px solid #0077b5;

		&:hover {
			background-color: #005e8f;
		}
	}

	.googleLogin {
		padding: 0.75rem 4rem;
		border: 1px solid #777ea0;
		background-color: #fff;

		&:hover {
			background-color: #ececec;
		}
	}
`;

interface ButtonProps {
	disabled: boolean;
	secondary: boolean;
	isFirst?: boolean;
}

export const Button = styled.button<ButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 1rem 0;
	margin-bottom: ${({ isFirst }) => (isFirst ? '10px' : '0')};
	
	background-color: ${props => props.secondary ? "white" : "var(--light-orange)"};
	color: ${props => props.secondary ? "var(--light-orange)" : "white"};
	border: ${props => props.secondary ? "1px solid var(--light-orange)" : "white"};

	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;

	cursor: ${(props) => (props.disabled === true ? "not-allowed" : "pointer")};
	pointer-events: ${(props) => props.disabled === true && "none"};
`;