import React from 'react'
import { Container, Options } from './style'
import { eventsCategories } from '..';
import { FunnelSimple } from 'phosphor-react';

interface FilterProps {
  filter: string;
  setFilter: (value: string) => void;
}


export function Filter({filter, setFilter}: FilterProps) {
  return (
    <Container>
      <p>
        <FunnelSimple weight='bold' size={24} /> Filtro:
      </p>
      <Options>
        {eventsCategories.map((category, index) => {
          return ( 
            <span 
              key={index}
              id={category.key}
              className={category.key === filter ? 'active' : ''}
              onClick={() => setFilter(category.key)}
            >{category.value}</span>
          )
        })}
      </Options>
    </Container>
  );
}