import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 5px;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const StyledCheckbox = styled.span`
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid #fff;
  transition: all 0.3s ease;

  ${HiddenCheckbox}:checked + & {
    background-color: #1ab1bb;
    border-color: #fff;
  }

  ${CheckboxContainer}:hover ${HiddenCheckbox}:not(:checked) + & {
    border-color: #1ab1bb;
  }
`;

const Label = styled.span`
  color: #fff;
  margin-left: 24px;

`;



const Checkbox = ({ label, setValue, checked, ...rest }) => {
  const handleChange = (e) => {
    setValue(e.target.checked);
  };

  return (
    <CheckboxContainer>
      <HiddenCheckbox onChange={handleChange} checked={checked} {...rest} />
      <StyledCheckbox />
      <Label>{label}</Label>
    </CheckboxContainer>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  setValue: PropTypes.func.isRequired,
  checked: PropTypes.bool
};