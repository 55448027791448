import { SyButton, SyInput } from "@sydle/sydle-ui-react";
import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  top: 80px;

  main {
    margin: 4rem var(--page-lateral-padding) 2rem;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%;
      margin: 0 auto;
      padding: 2rem;

      @media (max-width: 768px) {
        width: 100%;
      }
      @media (max-width: 480px) {
        padding: 0;
      }
    }
  }
`;

export const StyledSyInput = styled(SyInput)`
  --sy-input-color: #313131;
  --sy-input-border-color: #313131;

  --sy-input-border-color-hover: #313131;

  --sy-input-border-color-focus: var(--light-orange);
  --sy-input-color-label-focus: var(--light-orange);

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

interface StyledSyButtonProps {
  background: string;
  color: string;
}

export const StyledSyButton = styled(SyButton)<StyledSyButtonProps>`
  --sy-button-padding: 15px 30px;
  --sy-button-font-weight: 500;
  --sy-button-letter-spacing: 1px;
  --sy-button-border-radius: 7.29px;

  --sy-button-background-color: #FF7B24;
  --sy-button-background-color-hover: #FF7B24;
  --sy-button-background-color-active: #FF7B24;
  --sy-button-border-color: white;
  --sy-button-border-color-hover: #FF7B24;
  --sy-button-border-color-active: #FF7B24;
  --sy-button-color: white;
  --sy-button-color-hover: white;
  --sy-button-color-active: white;

  --sy-button-loading-color: #fff;

  width: 200px;
  margin: 20px 0;

  ${(props) =>
    props.loading === true &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  svg {
    margin-right: 0.5rem;
  }
`;