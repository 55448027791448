import { useState, useEffect } from "react";
import { InputLine } from "./InputStyle";
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

Input.propTypes = {
  half: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  disable: PropTypes.bool,
  selectedLanguage: PropTypes.string,
  validateError: PropTypes.bool,
};

export default function Input({
  half = false,
  label,
  options,
  value,
  setValue,
  disable = false,
  selectedLanguage = "pt",
  validateError = false,
}) {
  const [internalValue, setInternalValue] = useState("");
  const [messageError, setMessageError] = useState("");
  const isMobile = useMediaQuery({ query: '(max-width: 426px)' })

  function validationCheck(value) {
    if (!value && validateError) {
      setMessageError(" (Obrigatório)");
    } else {
      setMessageError("");
    }
  }

  useEffect(() => {
    validationCheck(internalValue);
  }, [validateError]);

  return (
    <InputLine half={half}>
      <label className="input">
        <select
          className="input__field"
          onChange={(e) => {
            validationCheck(e.target.value);
            setValue(e.target.value);
            setInternalValue(e.target.value)
          }}
          disabled={disable}
          value={value === null ? '' : value}
        >
          <option value="" hidden>
            Selecione uma opção
          </option>
          {options &&
            options.length &&
            options.map((option) => (
              <option value={option._id} key={label + option._id}>
                {option.name}
              </option>
            ))}
        </select>
        <span className="input__label">
          {label} {!isMobile ? <span style={{ color: "red", fontSize: "0.8rem" }}>{messageError}</span> : ""} 
        </span>
      </label>
      {isMobile ? <span style={{ color: "red", fontWeight: 600, backgroundColor: "#fff", padding: 2, borderRadius: 3, fontSize: "0.8rem"}}>{messageError}</span> : ""}
    </InputLine>
  );
}
