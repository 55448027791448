import { SyButton, SyInput } from "@sydle/sydle-ui-react";
import styled from "styled-components";

export const Container = styled.div`
position: relative;
top: 80px;
  display: grid;
  grid-template-columns: 40% 60%;
  min-height: calc(100vh - 80px);
  background-color: #fff;
  color: #313131;

  p {
    margin: 1rem 0;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const RightSection = styled.section`
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const LeftSection = styled.section`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    text-align: center;
    color: var(--light-orange);
  }

  form {
    width: 80%;
    margin-bottom: 2.5rem;

    .checkGroup {
      margin-top: 2rem;

      .checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.5rem;
  
        label {
          margin-left: 0.5rem;

          a {
            color: var(--light-orange);
          }
        }
      }
    }
  }

  span {
    margin-top: 2rem;
    color: var(--light-orange);
    font-weight: 500;
    font-size: 1.125rem;
    cursor: pointer;
  }

  @media(max-width: 420px) {
    padding: 2rem 1rem;
  }
`;

export const StyledSyInput = styled(SyInput)`
  --sy-input-color: #313131;
  --sy-input-border-color: #313131;

  --sy-input-border-color-hover: #313131;

  --sy-input-border-color-focus: var(--light-orange);
  --sy-input-color-label-focus: var(--light-orange);

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const StyledSyButton = styled(SyButton)`
  --sy-button-padding: 0.75rem 2rem;
  --sy-button-background-color: var(--light-orange);
  --sy-button-border-color: var(--light-orange);
  --sy-button-color: #fff;
  --sy-button-background-color-hover: var(--light-orange);
  --sy-button-border-color-hover: var(--light-orange);
  --sy-button-color-hover: #fff;
  --sy-button-background-color-active: var(--light-orange);
  --sy-button-border-color-active: var(--light-orange);
  --sy-button-color-active: #fff;
  --sy-button-loading-color: #fff;

  &[loading="true"] {
    pointer-events: none;
  }
`;

export const SocialLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 20%;
  margin: 0 auto;

  svg {
    cursor: pointer;
  }
`;