import { useState, useEffect, useCallback } from "react";
import {
	Section,
	FormContainer,
	EbookSection,
	PromotionalText,
	EbookContainer,
	SocialMedia,
	FormLine,
	Title,
	Button,
	Person
} from "./style";
import { AiFillInstagram } from "react-icons/ai";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import FluidText from "react-fluid-text";
import { toast } from "react-toastify";
import Lottie from "lottie-react";
import DOMPurify from 'dompurify';
import InputContainer from "../../../Ebooks/components/InputContainer";
import sendRequest from "../../../../services/sendRequest";
import Checkbox from "../../../Ebooks/components/Checkbox";
import EbookAnimation from "../../assets/animation.json";
import Select from "../../../Ebooks/components/Select";
import Input from "../../../Ebooks/components/Input";
import Modal from "../../../Ebooks/components/Modal";
import Loading from "../../assets/spinner.json";

export function Section02({ 
	selectedLanguage, 
	path, 
	background, 
	featuredImageID, 
	overlayBackgroundID, 
	ebookPromotionalText, 
	ebookImages, 
	linkedinLink, 
	instagramLink, 
	ebookPath, 
	colors,
	tagID,
	workshopTitle
}) {
	const apiUrl = process.env.REACT_APP_SYDLE_ONE_ENDPOINT;
	const showPersonImage = useMediaQuery({ query: '(max-width: 1200px)' });
	const [loading, setLoading] = useState(false);
	const [validateError, setValidateError] = useState(false);

	const [name, setName] = useState("");
	const [career, setCareer] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [states, setStates] = useState([]);
	const [state, setState] = useState(null);
	const [academicLevelOptions, setAcademicLevelOptions] = useState([]);
	const [academicLevel, setAcademicLevel] = useState(null);
	const [experience, setExperience] = useState("");
	const [visible, setVisible] = useState(false);
	const [cities, setCities] = useState([]);
	const [city, setCity] = useState(null);
	const [checkOne, setCheckOne] = useState(false);
	const [checkTwo, setCheckTwo] = useState(false);

	const experienceOptions = [
		{
			_id: "1-4",
			name: "1-4 Anos",
		},
		{
			_id: "5-9",
			name: "5-9 Anos",
		},
		{
			_id: "10-14",
			name: "10-14 Anos",
		},
		{
			_id: "15+",
			name: "15+ Anos",
		},
	];

	const navigate = useNavigate();

	useEffect(() => {
		async function fetchAcademicLevelData() {
			try {
				const AcademicsResult = await sendRequest.getAcademicLevelOptions({});
				setAcademicLevelOptions(AcademicsResult);
			} catch (error) {
				toast.error(error, {
					toastId: "section-02-02-error",
				});
			}
		}

		fetchAcademicLevelData();
	}, []);

	useEffect(() => {
		async function fetchStatesAndCitiesData() {
			try {
				if (!states || (states && states.length <= 0)) {
					const statesResult = await sendRequest.getStates({});
					setStates(statesResult);
				}

				if (state) {
					const citiesResult = await sendRequest.getCities({ stateID: state });
					setCities(citiesResult);
				}
			} catch (error) {
				toast.error(error, {
					toastId: "section-02-01-error",
				});
			}
		}

		fetchStatesAndCitiesData();
	}, [state, states]);

	const handleSend = useCallback(
		async (e) => {
			e.preventDefault();
			setValidateError(true);
			setLoading(true);

			const requestData = {
				name: name.trim().replace(/\s+/g, ' '),
				academicLevel,
				career,
				experience,
				phone,
				state,
				city,
				email,
				careerBreak: checkOne,
				tagID,
				workshopTitle
			};

			if (checkTwo) {
				try {
					const response = await sendRequest.workshopRegistration(requestData);

					if (response && response.statusCode === 200) {
						toast.success(
							selectedLanguage === "pt"
								? "Sua inscrição foi aceita com sucesso."
								: "Your application has been successfully accepted.",
							{
								toastId: "section-02-01-success",
							}
						);

						window.dataLayer.push({
							event: "ebook_download",
							ebook_name: "Registro no Workshop - " + workshopTitle,
						});

						resetForm();
						setVisible(true);
					} else {
						toast.error(response.response.data.generalMessages[0].text, {
							toastId: "section-02-02-error",
						});
					}
				} catch (error) {
					toast.error(error, {
						toastId: "section-02-03-error",
					});
				}
			} else {
				toast.error(
					selectedLanguage === "pt"
						? "Você concorda com a política de privacidade da Be Back Now? Se sim, marque a respectiva caixinha 😁"
						: "Do you agree with Be Back Now's Privacy Policy? If so, please check the respective box 😁",
					{
						toastId: "section-02-04-error",
					}
				);
			}

			setLoading(false);
		},
		[
			name,
			academicLevel,
			experience,
			phone,
			career,
			email,
			state,
			city,
			checkOne,
			checkTwo,
			selectedLanguage,
			tagID,
			workshopTitle
		]
	);

	const resetForm = () => {
		setName('');
		setAcademicLevel('');
		setCareer('');
		setExperience('');
		setPhone('');
		setState('');
		setCity('');
		setEmail('');
		setCheckOne(false);
		setCheckTwo(false);
	};

	const renderButtonContent = () => {
		if (loading) {
			return <Lottie animationData={Loading} style={{ width: 20 }} />;
		}

		return selectedLanguage === "pt" ? "ENVIAR" : "SEND";
	};

	const scrollToDiv = () => {
		document.getElementById('ebooks')?.scrollIntoView({ behavior: 'smooth' });
	};

	function ModalContent() {
		return (
			<div style={{ height: "100%" }}>
				<Lottie animationData={EbookAnimation} />
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<h1 style={{ textAlign: "center" }}>Parabéns!</h1>
					<p style={{ textAlign: "justify" }}>
						Você acabou de dar mais um passo na retomada de sua carreira! Esperamos
						que o workshop enriqueça sua jornada e o inspire a explorar novos
						horizontes.
					</p>
					<Button
						onClick={() => {
							navigate("/");
						}}
						bgColor={colors.submitButtonBackground} 
						textColor={colors.submitButtonText}
						bgColorOnHover={colors.submitButtonBackgroundHover} 
						textColorOnHover={colors.submitButtonTextHover}
					>
						IR PARA PÁGINA INICIAL
					</Button>
				</div>
			</div>
		);
	}

	return (
		<Section backgroundImageUrl={apiUrl + path + background.id}>
			<FormContainer id="section02" overlayBackgroundUrl={apiUrl + path + overlayBackgroundID}>
				<FormLine onSubmit={(e) => handleSend(e)} color={colors}>
					<Title color={colors.formTitle}>
						<FluidText
							text={'Faça sua  inscrição'}
							as="h3"
							compressor={
								selectedLanguage && selectedLanguage === "pt" ? 0.83 : 1.24
							}
						/>
					</Title>

					<InputContainer>
						<Input
							label={selectedLanguage === "pt" ? "Nome e sobrenome *" : "First name and last name *"}
							value={name}
							setValue={setName}
							type="text"
							validation="name"
							validateError={validateError}
							half={true}
						/>
						<Input
							half={true}
							label="E-mail *"
							value={email}
							setValue={setEmail}
							type="email"
							validation="email"
							validateError={validateError}
						/>
					</InputContainer>
							
					<InputContainer>
						<Input
							label={selectedLanguage === "pt" ? "Telefone *" : "Phone *"}
							value = {phone}
							setValue={setPhone}
							type="tel"
							validation="tel"
							validateError={validateError}
							half={true}
						/>
						<Input
							half={true}
							label={selectedLanguage === "pt" ? "Profissão" : "Career"}
							value={career}
							setValue={setCareer}
							type="text"
							// validation="career"
							// validateError={validateError}
						/>
					</InputContainer>

					<InputContainer>
						<Select
							half={true}
							selectedLanguage={selectedLanguage}
							label={
								selectedLanguage === "pt"
									? "Tempo de Experiência *"
									: "Experience Time *"
							}
							options={experienceOptions}
							value={experience}
							setValue={setExperience}
							validateError={validateError}
						/>
						<Select
							half={true}
							label={
								selectedLanguage === "pt"
									? "Nivel Acadêmico *"
									: "Academic Level *"
							}
							options={academicLevelOptions}
							value={academicLevel}
							setValue={setAcademicLevel}
							validateError={validateError}
						/>
					</InputContainer>

					<InputContainer>
						<Select
							half={true}
							selectedLanguage={selectedLanguage}
							label={selectedLanguage === "pt" ? "Estado *" : "State *"}
							options={states}
							value={state}
							setValue={setState}
							validateError={validateError}
						/>
						<Select
							half={true}
							selectedLanguage={selectedLanguage}
							label={selectedLanguage === "pt" ? "Cidade *" : "City *"}
							options={cities}
							value={city}
							setValue={setCity}
							disable={!state}
							validateError={validateError}
						/>
					</InputContainer>

					<Checkbox
						label={
							selectedLanguage === "pt"
								? "Estou em pausa na carreira"
								: "I'm on career break"
						}
						checked={checkOne}
						setValue={setCheckOne}
						validateError={validateError}
					/>

					<Checkbox
						label={
							selectedLanguage === "pt" ? (
								<>
									Eu concordo com as{" "}
									<a
										href="/politica-de-privacidade"
										target="_blank"
										rel="noreferrer"
									>
										Políticas de Privacidade da Be Back Now
									</a>
								</>
							) : (
								<>
									I agree with{" "}
									<a href="/politica-de-privacidade" target="_blank" rel="noreferrer">
										Be Back Now's Privacy Policy
									</a>
								</>
							)
						}
						checked={checkTwo}
						setValue={setCheckTwo}
						validateError={validateError}
					/>

					<Button 
						type="submit" 
						half={true} 
						bgColor={colors.submitButtonBackground} 
						textColor={colors.submitButtonText}
						bgColorOnHover={colors.submitButtonBackgroundHover} 
						textColorOnHover={colors.submitButtonTextHover}
					>
						{renderButtonContent()}
					</Button>
				</FormLine>

				{!showPersonImage && (
					<Person>
						<img src={apiUrl + path + featuredImageID} width="100%" height="100%" alt="" />
					</Person>
				)}
			</FormContainer>

			<EbookSection>
				<PromotionalText 
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(ebookPromotionalText)
					}}
					color={colors.ebookPromotionalText}
				></PromotionalText>

				<EbookContainer id="ebooks">
					{ebookImages.length > 0 && ebookImages.map((item, index) => (
						<a href={ebookPath} target="_blank" rel="noopener noreferrer" key={index}>
							<img src={apiUrl + path + item.id} alt=""/>
						</a>
					))}
				</EbookContainer>

				<SocialMedia color={colors.socialMediaTextsAndIcons}>
					<div>
						<FaLinkedin size={25} color={colors.socialMediaTextsAndIcons} />
						<a href={linkedinLink} target="_blank" rel="noopener noreferrer">bebacknow</a>
					</div>
					<div>
						<AiFillInstagram size={25} color={colors.socialMediaTextsAndIcons} />
						<a href={instagramLink} target="_blank" rel="noopener noreferrer">bebacknow.brasil</a>
					</div>

					<div>
						<FaGlobe size={25} color={colors.socialMediaTextsAndIcons} />
						<a href="https://www.bebacknow.com/" target="_blank" rel="noopener noreferrer">bebacknow.com</a>
					</div>
				</SocialMedia>
			</EbookSection>

			<Modal
				visible={visible}
				setVisible={setVisible}
				Content={ModalContent}
				onClose={() => scrollToDiv()}
			/>
		</Section>
	);
}