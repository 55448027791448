import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Banner } from '../../components/Banner';
import sendRequest from '../../services/sendRequest';
import validate from "../../utils/validator";

import { Container, StyledSyButton, StyledSyInput } from './style'

interface IsValidProps {
  contactName: string | undefined;
  contactEmail: string | undefined;
  contactPhone: string | undefined;
  contactSector: string | undefined;
  contactJobTitle: string | undefined;
  enterpriseName: string | undefined;
  numberEmployees: string | undefined;
  howDidYouGetToUs: string | undefined;
}

interface BusinessContactDataProps {
  banner: {
    title: string;
    description: string;
    image: string;
    button: boolean;
    route: string | null;
  };
  text: string;
}

export function BusinessContact() {
  const [businessContactData, setBusinessContactData] =
    useState<BusinessContactDataProps>();

  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactSector, setContactSector] = useState("");
  const [contactJobTitle, setContactJobTitle] = useState("");
  const [enterpriseName, setEnterpriseName] = useState("");
  const [numberEmployees, setNumberEmployees] = useState("");
  const [howDidYouGetToUs, setHowDidYouGetToUs] = useState("");
  const [errors, setErrors] = useState<IsValidProps>({
    contactName: undefined,
    contactEmail: undefined,
    contactPhone: undefined,
    contactSector: undefined,
    contactJobTitle: undefined,
    enterpriseName: undefined,
    numberEmployees: undefined,
    howDidYouGetToUs: undefined
  });

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // garante que a página vá para o topo
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    async function fetchPartnersData() {
      await sendRequest
        .getBusinessContact()
        .then((response) => {
          setBusinessContactData(response);
        })
        .catch((err) => {
          console.log(`Error trying to fetch businessContactData: ${err}`);
        });
    }
    fetchPartnersData();
  }, []);

  async function handleSendForm() {
    setIsLoading(true);

    let updateErrors = { ...errors };
    updateErrors.contactName = validate.contactForm.name(contactName);
    updateErrors.contactEmail = validate.contactForm.email(contactEmail);
    updateErrors.contactPhone = validate.contactForm.phoneNumber(contactPhone);
    updateErrors.enterpriseName = validate.contactForm.enterpriseName(enterpriseName);

    if (
      updateErrors.contactName ||
      updateErrors.contactEmail ||
      updateErrors.contactPhone ||
      updateErrors.contactSector ||
      updateErrors.contactJobTitle ||
      updateErrors.enterpriseName
    ) {
      setErrors(updateErrors);
      setIsLoading(false);
      return;
    }
    // TODO testar
    // if (updateErrors.contactEmail) {
    //   setErrors(updateErrors);
    //   setIsLoading(false);
    //   return;
    // }
    // if (updateErrors.contactPhone) {
    //   setErrors(updateErrors);
    //   setIsLoading(false);
    //   return;
    // }

    await sendRequest
      .createEnterpriseContactRequest({
        contactName,
        contactEmail,
        contactPhone,
        contactSector,
        contactJobtitle: contactJobTitle,
        enterpriseName,
        numberEmployees,
        howDidYouGetToUs
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.error, {
            toastId: "bussiness-contact-error",
          });
          return;
        }
        toast.success(`Sua solicitação de contato foi registrada com sucesso!`,
        {
          toastId: "business-contact-01-success",
        });
        setContactName("");
        setContactEmail("");
        setContactPhone("");
        setContactSector("");
        setContactJobTitle("");
        setEnterpriseName("");
        setNumberEmployees("");
        setHowDidYouGetToUs("");
        navigate('/');
      })
      .catch((err) => {
        toast.error(`Não foi possível registrar sua solicitação de contato`, {
          toastId: "bussiness-contact-2-error",
        });
      });


    setIsLoading(false);
  }

  return (
    <Container>
      <Banner
        title={businessContactData?.banner.title!}
        description={businessContactData?.banner.description!}
        image={businessContactData?.banner.image!}
        button={businessContactData?.banner.button!}
        route={businessContactData?.banner.route!}
      />
      <main>
        <article>{businessContactData?.text}</article>
        <form>
          <StyledSyInput
            type="text"
            label="Nome *"
            value={contactName}
            onSyInputDidInput={(e) =>
              setContactName((e.target as HTMLInputElement).value)
            }
            error={errors.contactName}
          />
          <StyledSyInput
            type="email"
            label="Email corporativo *"
            value={contactEmail}
            onSyInputDidInput={(e) =>
              setContactEmail((e.target as HTMLInputElement).value)
            }
            error={errors.contactEmail}
          />
          <StyledSyInput
            label="Telefone corporativo *"
            value={contactPhone}
            onSyInputDidInput={(e) =>
              setContactPhone((e.target as HTMLInputElement).value)
            }
            error={errors.contactPhone}
            mask="(99) 99999-9999"
          />
          <StyledSyInput
            label="Área de atuação"
            value={contactSector}
            onSyInputDidInput={(e) =>
              setContactSector((e.target as HTMLInputElement).value)
            }
            error={errors.contactSector}
          />
          <StyledSyInput
            label="Cargo"
            value={contactJobTitle}
            onSyInputDidInput={(e) =>
              setContactJobTitle((e.target as HTMLInputElement).value)
            }
            error={errors.contactJobTitle}
          />
          <StyledSyInput
            label="Nome da Empresa *"
            value={enterpriseName}
            onSyInputDidInput={(e) =>
              setEnterpriseName((e.target as HTMLInputElement).value)
            }
            error={errors.enterpriseName}
          />
          <StyledSyInput
            label="Quantidade de funcionários"
            value={numberEmployees}
            onSyInputDidInput={(e) =>
              setNumberEmployees((e.target as HTMLInputElement).value)
            }
          />
          <StyledSyInput
            label="Como chegou até nós"
            value={howDidYouGetToUs}
            onSyInputDidInput={(e) =>
              setHowDidYouGetToUs((e.target as HTMLInputElement).value)
            }
          />
          <StyledSyButton
            background="var(--light-orange)"
            color="white"
            loading={isLoading}
            onClick={handleSendForm}
          >
            Enviar
          </StyledSyButton>
        </form>
      </main>
    </Container>
  );
}