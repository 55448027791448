import { RiMenuFill, RiLogoutCircleLine, RiUser6Fill } from "react-icons/ri";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import AuthContext from "../../contexts/Auth/AuthContext";
import { useEffect, useState, useContext } from "react";
import sendRequest from "../../services/sendRequest";
// import LanguageSelector from "./LanguageSelector";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { WhatsappLogo } from "phosphor-react";
import { Link } from 'react-router-dom';
import {
	Navbar,
	Background,
	MenuContainer,
	MenuList,
	MobileMenu,
	SubMenuContent,
	UserContainer,
	UserDropdown,
	UserDropdownItem,
	WhatsappButton
} from "./style";

const NavbarComponent = ({
	selectedLanguage,
	setSelectedLanguage,
	loading,
	setLoading,
}) => {
	const [openMenu, setOpenMenu] = useState(false);
	const [openSubMenu, setOpenSubMenu] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const [languageSelectorIsOpen, setLanguageSelectorIsOpen] = useState(false);
	const [logoURL, setLogoURL] = useState();
	const [menuItems, setMenuItems] = useState([]);
	const [submenuItems, setSubmenuItems] = useState([]);
	const [cellphone, setCellphone] = useState("31993404518"); // Estado para armazenar o número de telefone do WhatsApp
	const [message, setMessage] = useState("Olá, gostaria de mais informações sobre a Be back now."); // Estado para armazenar a mensagem do WhatsApp
	const { logout, user, setIsAuthModalOpen, updateUserPhoto, isUserPhotoUpdated } = useContext(AuthContext);
	const storageUser = localStorage.getItem("@bebacknow:user");
	const parsedUser = storageUser ? JSON.parse(storageUser) : null;
	const [userPhoto, setUserPhoto] = useState();

	// Hook de navegação do React Router
	const hideNavbar = useMediaQuery({ query: '(max-width: 1200px)' });
	const apiUrl = process.env.REACT_APP_SYDLE_ONE_ENDPOINT;
	const navigate = useNavigate();

	useEffect(() => {
		async function fetchNavbarData() {
			try {
				setLoading(true);
				const navbarResult = await sendRequest.getNavbar({personID: parsedUser?.id});
	
				if (navbarResult) {
					setLogoURL(navbarResult.logoURL);
					setMenuItems(navbarResult.menu);
					setSubmenuItems(navbarResult.submenu);
					setUserPhoto(navbarResult.photo);
				}
	
				setLoading(false);
			} catch (error) {
				console.error("Error:", error);
			}
		}

		updateUserPhoto(false); // Updating the value to make sure that, if the user updates the photo again, the effect will run to update the navbar photo
		fetchNavbarData();
	}, [user, isUserPhotoUpdated, updateUserPhoto]);

	useEffect(() => {
		async function fetchContactData() {
			try {
				setLoading(true);
				const contactResult = await sendRequest.getContactWhatsapp({});
	
				if (contactResult) {
					setCellphone(contactResult.cellphone);
					setMessage(contactResult.message);
				}
	
				setLoading(false);
			} catch (error) {
				console.error("Error:", error);
			}
		}

		fetchContactData();
	}, []);

	// Função para lidar com o logout do usuário
	const handleLogout = () => {
		logout();
		navigate("/");
		closeBothMenus();
	};

	const isForYouItem = (string) => {
		return (string.pt === "Para você" || string.en === "For you");
	}

	const closeBothMenus = () => {
		setOpenMenu(false);
		setOpenSubMenu(false);
	}

	return (
		<>
			<Navbar>
				<img className="logo" src={apiUrl + logoURL} alt="Logo" 
					onClick={() => {
						navigate("/")
						closeBothMenus()
					}} 
				/>

				{!hideNavbar && (
					<MenuContainer>
						<MenuList>
							{menuItems.map((item, index) => (
								<li key={index} className={isForYouItem(item.name) ? "dropdown" : null}>
									<Link to={item.url}>{item.name.pt}</Link>
									{(isForYouItem(item.name) && submenuItems) && (
										<>
											<MdOutlineKeyboardArrowDown color="#616161"/>
											<div className="dropdown-content">
												{submenuItems.map((subItem, index) => (
													<Link to={subItem.url} key={index}>{subItem.name.pt}</Link>
												))}
											</div>
										</>
									)}
								</li>
							))}
						</MenuList>
				

						{user ? (
							<UserContainer>
								{!userPhoto && (
									<FaUserCircle onClick={() => {
											// Quando clicar na imagem, exibir/ocultar o dropdown
											setShowDropdown(!showDropdown);
											setOpenMenu(false);
											setLanguageSelectorIsOpen(false);
										}}
										size={50}
										color={"#FF7B24"}
										className="user"
									/>
								)}
								{userPhoto && (
									<img 
										src={userPhoto} 
										alt="Profile"
										onClick={() => {
											// Quando clicar na imagem, exibir/ocultar o dropdown
											setShowDropdown(!showDropdown);
											setOpenMenu(false);
											setLanguageSelectorIsOpen(false);
										}}
										className="user"
									/>
								)}

								<UserDropdown showDropdown={showDropdown}>
									<UserDropdownItem
										onClick={() => {
											navigate("/profile");
											setShowDropdown(false);
										}}
									>
										<RiUser6Fill size={20} />
										<div style={{ flex: 1 }}>
											{selectedLanguage === "pt" ? "Perfil" : "Profile"}
										</div>
									</UserDropdownItem>
									<UserDropdownItem
										onClick={() => {
											handleLogout();
											setShowDropdown(false);
										}}
									>
										<RiLogoutCircleLine size={20} />
										<div style={{ flex: 1 }}>
											{selectedLanguage === "pt" ? "Sair" : "Logout"}
										</div>
									</UserDropdownItem>
								</UserDropdown>
							</UserContainer>
						) : (
							<>
								<button
									className="outlined-button"
									onClick={() => {
										setIsAuthModalOpen(true);
										setOpenMenu(false);
									}}
								>
									{selectedLanguage === "pt" ? "Entrar" : "Login"}
								</button>
							</>
						)}
					</MenuContainer>
				)}

				{hideNavbar && (
					<div className="buttons">
						{!user && (
							<button
								className="outlined-button"
								onClick={() => {
									setIsAuthModalOpen(true);
									closeBothMenus()
								}}
							>
								{selectedLanguage === "pt" ? "Entrar" : "Login"}
							</button>
						)}

						{user && (
							<UserContainer>
								{!userPhoto && (
									<FaUserCircle onClick={() => {
											navigate("/profile");
											closeBothMenus();
										}}
										size={50}
										color={"#FF7B24"}
										className="user"
									/>
								)}
								{userPhoto && (
									<img 
										src={userPhoto} 
										alt="Profile"
										onClick={() => {
											navigate("/profile");
											closeBothMenus();
										}}
										width={50}
										className="user"
									/>
								)}
							</UserContainer>
						)}

						<RiMenuFill
							size="2rem"
							onClick={() => {
								setOpenMenu(!openMenu)
							}}
						/>

						<MobileMenu open={openMenu}>
							<MenuList>
								{menuItems.map((item, index) => (
									<div className="item" key={index}>
										<li className={isForYouItem(item.name) ? "submenu-mobile" : null}>
											{isForYouItem(item.name) && submenuItems ? (
												<>
													<div className="submenu-item">
														<Link to={item.url} onClick={() => { setOpenSubMenu(!openSubMenu) }}>
															{item.name.pt}
														</Link>
														{!openSubMenu && (<MdOutlineKeyboardArrowDown color="#616161" onClick={() => { setOpenSubMenu(true) }}/>)}
														{openSubMenu && (<MdOutlineKeyboardArrowUp color="#616161" onClick={() => { setOpenSubMenu(false) }}/>)}
													</div>
													<SubMenuContent openSubMenu={openSubMenu}>
														{submenuItems.map((subItem, index) => (
															<Link to={subItem.url} key={index} 
																onClick={() => { closeBothMenus() }}
															>
																{subItem.name.pt}
															</Link>
														))}
													</SubMenuContent>
												</>
											) : (
												<Link to={item.url} onClick={() => { closeBothMenus() }}>{item.name.pt}</Link>
											)}
										</li>	
									</div>	  
								))}

								{user && ( 
									<>
										<li>
											<Link to={"/profile"} onClick={() => { closeBothMenus() }}>{selectedLanguage === "pt" ? "Perfil" : "Profile"}</Link>
										</li>
										<li onClick={() => { handleLogout() }}>
											<Link>{selectedLanguage === "pt" ? "Sair" : "Logout"}</Link>
										</li> 
									</>
								)}
							</MenuList>
						</MobileMenu>

						{openMenu && <Background onClick={() => { setOpenMenu(false) }}></Background>}		
					</div>
				)}
			</Navbar>

			{localStorage.getItem("acceptedPrivacyPolicy") ? (
				<WhatsappButton
					href={`https://api.whatsapp.com/send?phone=${cellphone}&text=${encodeURIComponent(
						message
					)}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<WhatsappLogo size={40} />
				</WhatsappButton>
			) : null}
		</>
	);
};

export default NavbarComponent;