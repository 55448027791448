import styled from "styled-components";

interface ImageWrapperProps {
  image: string;
  index: number;
}

interface TextProps {
  index: number;
}


export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;

    /* para a seção voltar a ter a imagem */
    /* grid-template-rows: 1fr 1fr; */
  }
`;


export const Text = styled.div<TextProps>`
  width: 100%;
  padding: ${(props) =>
    props.index % 2 ? "4rem 12rem 4rem 10rem" : "4rem 10rem 4rem 12rem"};
  grid-column: ${(props) => (props.index % 2 ? "2/3" : "1/2")};

  @media (max-width: 1200px) {
    padding: ${(props) =>
      props.index % 2 ? "4rem 10rem 4rem 4rem" : "4rem 4rem 4rem 10rem"};
  }

  @media (max-width: 1000px) {
    padding: 2rem var(--page-lateral-padding);
  }

  h2,
  p {
    color: var(--dark-grey);
  }

  p {
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }
`;


export const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  width: 100%;
  height: 100%;
  grid-column: ${(props) => (props.index % 2 ? "1/2" : "2/3")};
  grid-row: 1/2;

  .content {
    height: 100%;
    background-image: ${(props) => `url(${props.image})`};
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-image: ${(props) =>
      props.index % 2
        ? "linear-gradient(to left, white, 15%, transparent)"
        : "linear-gradient(to right, white, 15%, transparent)"};

    @media (max-width: 1000px) {
      background-image: linear-gradient(to bottom, white, transparent);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${(props) =>
      props.index % 2
        ? "linear-gradient(225deg, transparent, var(--light-orange))"
        : "linear-gradient(135deg, transparent, var(--light-orange))"};
    opacity: 0.3;
    z-index: 1;
    mix-blend-mode: screen;
  }
`;