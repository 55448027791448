import React, { useEffect, useState } from "react";
import { Banner } from "../../components/Banner";
import { AboutBanner } from "./AboutBanner";
import { Container, SectionDivider, VideoSection } from "./style";
import sendRequest from "../../services/sendRequest";
import { useLocation } from "react-router-dom";

interface AboutDataProps {
  banner: {
    title: string;
    description: string;
    image: string;
    button: boolean;
    route: string | null;
  };
  videoLink: string;
  aboutBanners: {
    title: string;
    description: string;
    image: string;
  }[];
}


export function About() {
  const [aboutData, setAboutData] = useState<AboutDataProps>();

  // garante que a página vá para o topo
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    async function fetchAboutData() {
      await sendRequest
        .getAbout({})
        .then((response) => {
          setAboutData(response);
        })
        .catch((err) => {
          console.log(`Error trying to fetch aboutData: ${err}`);
        });
    }
    fetchAboutData();
  }, []);

  return (
    <Container>
      <Banner
        title={aboutData?.banner.title!}
        description={aboutData?.banner.description!}
        image={aboutData?.banner.image!}
        button={aboutData?.banner.button!}
        route={aboutData?.banner.route!}
      />
      {aboutData?.videoLink !== null ? (
        <VideoSection>
          <div className="iframe-container">
            <iframe src={aboutData?.videoLink} title="Home Video"></iframe>
          </div>
        </VideoSection>
      ) : (
        <SectionDivider />
      )}
      {aboutData?.aboutBanners.map((aboutBanner, index) => {
        return (
          <AboutBanner
            key={index}
            index={index}
            title={aboutBanner.title}
            description={aboutBanner.description}
            image={aboutBanner.image}
          />
        );
      })}
      {/* <OurTeam>
        <h2>Nosso Time</h2>
        <Divider color="orange" />
        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
      </OurTeam> */}
    </Container>
  );
}
