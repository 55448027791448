import { SyAccordion } from '@sydle/sydle-ui-react';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import AuthContext from '../../contexts/Auth/AuthContext';
import sendRequest from '../../services/sendRequest';
import { ContentBanner } from './ContentBanner';
import reactStringReplace from "react-string-replace";
import { Content, Image, StyledButton, StyledSyAccordionItem } from "./style";
import { Link } from 'react-router-dom';


type AccordionDetail = {
  component: "p" | "ul";
  header: string;
  description: string[];
};

type Detail = {
  component: "p" | "accordion" | "ul";
  header: string;
  description: string[];
  accordionDetails?: AccordionDetail[];
};

type Link = {
  linkType: "internal" | "external";
  linkText: string;
  linkIdentifier: string;
  externalLinkUrl: string;
  internalContentId: string;
}

type Content = {
  image: string;
  links: Link[];
  details: Detail[];
}

interface ContentDataProps {
  name: string;
  banner: string;
  contentTo: 'candidate' | 'enterprise';
  content: Content[];
}

export function ContentPage() {
  const [contentData, setContentData] = useState<ContentDataProps>();
  const [links, setLinks] = useState<Link[]>([]);

  const { contentId } = useParams();

  const auth = useContext(AuthContext);

  // garante que a página vá para o topo
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    async function fetchAboutData() {
      await sendRequest
        .getContentPage({ id: contentId! })
        .then((res) => {
          setContentData(res);

          res.content.map((hit: Content) => {
            hit.links.map((link: Link) => {
              setLinks([...links, link]);
            });
          });
        })
        .catch((err) => {
          console.log(`Error trying to fetch contentData: ${err}`);
        });
    }
    fetchAboutData();
  }, []);

  function replacementFunction(content: string) {
    const replaceIndex = links.findIndex((link) => {
      return content.includes(link.linkIdentifier);
    });

    if (replaceIndex !== -1) {
      let contentReplaced;

      if (links[replaceIndex].linkType === "internal") {
        contentReplaced = reactStringReplace(
          content,
          `{{${links[replaceIndex].linkIdentifier}}}`,
          () => {
            return (
              <a href={`/content/${links[replaceIndex].internalContentId}`}>
                {links[replaceIndex].linkText}
              </a>
            );
          }
        );
      } else {
        contentReplaced = reactStringReplace(
          content,
          `{{${links[replaceIndex].linkIdentifier}}}`,
          () => {
            return (
              <a
                href={links[replaceIndex].externalLinkUrl}
                target="_blank"
                rel="noreferrer"
              >
                {links[replaceIndex].linkText}
              </a>
            );
          }
        );
      }

      return contentReplaced;
    }
    return content;
  }

  return (
    <>
      <ContentBanner
        title={contentData?.name!}
        description=""
        image={contentData?.banner!}
        button={false}
      />
      <article>
        {contentData?.content.map((content) => {
          return (
            <>
              {content.image ? (
                <Content image>
                  <Image src={content.image} />
                  {content.details.map((detail) => {
                    switch (detail.component) {
                      case "ul":
                        return (
                          <article>
                            <h3>{detail.header}</h3>
                            <ul>
                              {detail.description.map((hit) => (
                                <li>{replacementFunction(hit)}</li>
                              ))}
                            </ul>
                          </article>
                        );

                      case "p":
                        return (
                          <article>
                            <h3>{detail.header}</h3>
                            {detail.description.map((hit) => (
                              <p
                                style={{
                                  marginBottom: "0",
                                }}
                              >
                                {replacementFunction(hit)}
                              </p>
                            ))}
                          </article>
                        );

                      case "accordion":
                        return (
                          <article>
                            <SyAccordion>
                              <h3>{detail.header}</h3>
                              {detail.accordionDetails?.map(
                                (accordionDetail) => {
                                  return (
                                    <StyledSyAccordionItem
                                      label={accordionDetail.header}
                                    >
                                      {accordionDetail.component === "p" ? (
                                        accordionDetail.description.map(
                                          (hit) => (
                                            <p>{replacementFunction(hit)}</p>
                                          )
                                        )
                                      ) : (
                                        <ul>
                                          {accordionDetail.description.map(
                                            (hit) => (
                                              <li>
                                                {replacementFunction(hit)}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </StyledSyAccordionItem>
                                  );
                                }
                              )}
                            </SyAccordion>
                          </article>
                        );
                    }
                  })}
                </Content>
              ) : (
                <Content>
                  {content.details.map((detail) => {
                    switch (detail.component) {
                      case "ul":
                        return (
                          <article>
                            <h3>{detail.header}</h3>
                            <ul>
                              {detail.description.map((hit) => (
                                <li>{replacementFunction(hit)}</li>
                              ))}
                            </ul>
                          </article>
                        );

                      case "p":
                        return (
                          <article>
                            <h3>{detail.header}</h3>
                            {detail?.description?.map((hit) => (
                              <p style={{ marginBottom: "0" }}>
                                {replacementFunction(hit)}
                              </p>
                            ))}
                          </article>
                        );

                      case "accordion":
                        return (
                          <article>
                            <SyAccordion>
                              <h3>{detail.header}</h3>
                              {detail.accordionDetails?.map(
                                (accordionDetail) => {
                                  return (
                                    <StyledSyAccordionItem
                                      label={accordionDetail.header}
                                    >
                                      {accordionDetail.component === "p" ? (
                                        accordionDetail.description.map(
                                          (hit) => (
                                            <p>{replacementFunction(hit)}</p>
                                          )
                                        )
                                      ) : (
                                        <ul>
                                          {accordionDetail.description.map(
                                            (hit) => (
                                              <li>
                                                {replacementFunction(hit)}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </StyledSyAccordionItem>
                                  );
                                }
                              )}
                            </SyAccordion>
                          </article>
                        );
                    }
                  })}
                </Content>
              )}
            </>
          );
        })}
        {contentData?.contentTo === "candidate" ? (
          !auth.user && (
            <StyledButton to="/register">Cadastre-se</StyledButton>
          )
        ) : (
          <StyledButton to="/business-contact">
            Cadastre sua empresa
          </StyledButton>
        )}
      </article>
    </>
  );
}