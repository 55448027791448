import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Logo from "./bbn-logoo.png";
import Lottie from "lottie-react";
import Instagram from "./icons8-instagram-60.png";
import SydleIMG from "../../assets/logo-sydle-black.svg";
import LinkedinAnimation from "./linkedin-bounce.json";
import FacebookAnimation from "./icon-social-facebook.json";
import {
	FooterContainer,
	FooterContent,
	FooterResourceLinks,
	FooterEmailContainer,
	CircleContainer,
	Circle,
	Title,
	Columns,
	FormContainer,
	Button,
	Sydle,
	FooterResources,
	FooterContact
} from "./style";
import validate from "../../utils/validator";
import sendRequest from "../../services/sendRequest";
import Input from "../../pages/Ebooks/components/Input";

interface IsValidProps {
	name: string | undefined;
	email: string | undefined;
}

interface PropsInterface {
	loading: boolean;
	selectedLanguage: string;
}

export const Footer = ({ loading, selectedLanguage }: PropsInterface) => {
	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [errors, setErrors] = useState<IsValidProps>({
		name: undefined,
		email: undefined,
	});

	const [logo, setLogo] = useState("");
	const [description, setDescription] = useState<any>({});
	const [talents, setTalents] = useState([]);
	const [others, setOthers] = useState([]);
	const [contactEmail, setContactEmail] = useState("");
	const [copyright, setCopyright] = useState<any>({});
	const [socialMedia, setSocialMedia] = useState<any>([]);
	const [data, setData] = useState();

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);

		const updateErrors: IsValidProps = {
			name: validate.register.name(name),
			email: validate.register.email(email),
		};

		if (updateErrors.name || updateErrors.email) {
			toast.error("E-mail Inválido", {
				toastId: "footer-error",
			});
			setErrors(updateErrors);
			setIsLoading(false);
			return;
		}

		try {
			const response = await sendRequest.registerNewsletter({ name, email });
			if (response) {
				toast.success(
					`Usuário ${response.person.name} cadastrado nas newsletters com sucesso!`,
					{
						toastId: "footer-01-success",
					}
				);
			}
		} catch (err) {
			console.log(`Erro: ${err}`);
			toast.error(
				"Não foi possível cadastrar esse endereço de email nas newsletters",
				{
					toastId: "footer-2-error",
				}
			);
		}

		setErrors(updateErrors);
		setIsLoading(false);
	};

	const renderSocialLinks = () => {
		let components: any = [];

		if (socialMedia && socialMedia.length > 0) {
			socialMedia.forEach((hit: any, index: number) => {
				if (hit.name && hit.name.pt && hit.name.pt === "Instagram") {
					components.push(
						<Circle
							target="_blank"
							rel="noreferrer"
							href={hit.url}
							aria-label="Instagram"
							key={"social" + index}
						>
							<img
								src={Instagram}
								alt="Instagram"
								width={30}
								aria-hidden="true"
							/>
						</Circle>
					);
				} else if (hit.name && hit.name.pt && hit.name.pt === "LinkedIn") {
					components.push(
						<Circle
							target="_blank"
							rel="noreferrer"
							href={hit.url}
							aria-label="LinkedIn"
							key={"social" + index}
						>
							<Lottie
								animationData={LinkedinAnimation}
								style={{ width: 30 }}
								aria-hidden="true"
							/>
						</Circle>
					);
				} else if (hit.name && hit.name.pt && hit.name.pt === "Facebook") {
					components.push(
						<Circle
							target="_blank"
							rel="noreferrer"
							href={hit.url}
							aria-label="Facebook"
							key={"social" + index}
						>
							<Lottie
								animationData={FacebookAnimation}
								style={{ width: 50 }}
								aria-hidden="true"
							/>
						</Circle>
					);
				}
			});
		}

		if (components && components.length > 0)
			return <CircleContainer>{components}</CircleContainer>;

		return null;
	};

	useEffect(() => {
		setIsLoading(true);

		async function getFooterData() {
			try {
				const response = await sendRequest.getFooter({});
				if (response) {
					let {
						logo,
						description,
						talents,
						other,
						contactEmail,
						copyrightPhrase,
						socialMedia,
					} = response;
					setData(response);
					setLogo(logo);
					setDescription(description);
					setTalents(talents);
					setOthers(other);
					setContactEmail(contactEmail);
					setCopyright(copyrightPhrase);
					setSocialMedia(socialMedia);
				}
			} catch (err) {
				console.log(`Erro: ${err}`);
			}

			setIsLoading(false);
		}

		getFooterData();
	}, []);

	return (loading && !data) ? (
		<></>
	) : (
		<footer>
			<FooterContainer>
				<FooterResources>
					<FooterContent>
						<img src={logo ? logo : Logo} alt="Logo da BBN" />
						<FooterEmailContainer>
							<Title>
								{selectedLanguage === "pt" ? description.pt : description.en}
							</Title>
							<FormContainer>
								<form onSubmit={handleSubmit}>
									<Input
										label={
											selectedLanguage === "pt" ? "Nome Completo" : "Full Name"
										}
										type="text"
										setValue={setName}
										disable={isLoading}
									/>
									<Input
										type="email"
										label="E-mail"
										setValue={setEmail}
										disable={isLoading}
									/>
									<Button type="submit" disabled={isLoading}>
										{selectedLanguage === "pt" ? "Enviar" : "Send"}
									</Button>
								</form>
							</FormContainer>
						</FooterEmailContainer>
					</FooterContent>
					<FooterResourceLinks>
						<Columns>
							{talents &&
								talents.length > 0 &&
								talents.map((hit: any, index: number) => (
									<a href={hit.url} key={"talent" + index}>
										{selectedLanguage === "pt" ? hit.name.pt : hit.name.en}
									</a>
								))}
						</Columns>
						<Columns>
							{others &&
								others.length > 0 &&
								others.map((hit: any, index: number) => (
									<a href={hit.url} key={"other" + index}>
										{selectedLanguage === "pt" ? hit.name.pt : hit.name.en}
									</a>
								))}
						</Columns>
					</FooterResourceLinks>
				</FooterResources>
				<FooterContact>
					{renderSocialLinks()}

					<a
						href={`mailto:${contactEmail}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<span>{contactEmail}</span>
					</a>
				</FooterContact>
			</FooterContainer>

			<Sydle>
				<span>Powered by</span> <img src={SydleIMG} alt="" />
			</Sydle>
		</footer>
	);
};