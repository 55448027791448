import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: center;
  background-color: #f1d0c7;
  border-radius: 4px;
  margin-top: 1.5rem;

  span {
    margin-left: 1rem;
  }

  .trashIcon {
    margin-left: auto;
    cursor: pointer;
  }
`;