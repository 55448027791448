import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.policy ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.5)"};
  z-index: 9999;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 30px;
`;

const ModalContainer = styled.div`
  width: 90%;
  max-width: 500px;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: left;

  @media (min-width: 768px) {
    padding: 30px;
  }
`;

const ContentContainer = styled.div`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  text-align: justify;

  @media (min-width: 768px) {
    margin-bottom: 15px;
  }
`;

const Link = styled.a`
  color: ${(props) => (props.emphasis ? "#ff6600" : "#666")};
  text-decoration: underline;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #ff6600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const PrivacyPolicyModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [policy, setPolicy] = useState(false);

  useEffect(() => {
    const hasAcceptedPrivacyPolicy = localStorage.getItem(
      "acceptedPrivacyPolicy"
    );

    if (!hasAcceptedPrivacyPolicy) {
      setShowModal(true);
      document.body.style.overflow = "hidden";
    }

    if (window.location.href.includes("politica-de-privacidade")) {
      setPolicy(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("acceptedPrivacyPolicy", "true");
    setShowModal(false);
    document.body.style.overflow = "auto";
  };

  if (!showModal) {
    return null;
  }

  return (
    <ModalOverlay policy={policy}>
      <ModalContainer>
        <ContentContainer>
          <Title>Política de Privacidade</Title>
          <Text>
            Nós valorizamos sua privacidade e queremos garantir que suas
            informações estejam protegidas. Por isso, solicitamos que você leia
            e aceite nossa política de privacidade antes de prosseguir.{" "}
            <Link href="/politica-de-privacidade" emphasis>
              {" "}
              Clique aqui{" "}
            </Link>
            para ler a política completa.
          </Text>
        </ContentContainer>
        <Button onClick={handleAccept}>Aceitar</Button>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default PrivacyPolicyModal;
