import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Banner } from '../../components/Banner'
import { Divider } from '../../components/Divider';
import sendRequest from '../../services/sendRequest';
import { Card } from './Card';
import { InspiringBanner } from './InspiringBanner';
import { InspiringCard } from './InspiringCard';
import { CardsSection, Container, Content, TextSection } from './style'

interface TalentsDataProps {
  banner: {
    image: string;
    title: string;
    description: string;
    button: boolean;
    route: string | null;
  };
  title: string;
  description: string;
  highlight: string | null;
  inspiringBanner: {
    image: string;
    title: string;
    description: string;
  };
  inspiringCards: {
    picture: string;
    name: string;
    role: string;
    story: string;
    route: string;
  }[];
  headerCards: {
    image: string;
    title: string;
    route: string;
  }[];
}

export function Talents() {
  const [talentsData, setTalentsData] = useState<TalentsDataProps>();

  // garante que a página vá para o topo
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    async function fetchHomeData() {
      await sendRequest
        .getTalents({})
        .then((response) => {
          setTalentsData(response);
        })
        .catch((err) => {
          console.log(`Error trying to fetch talentsData: ${err}`);
        });
    }
    fetchHomeData();
  }, []);

  return (
    <Container>
      <Banner
        title={talentsData?.banner.title!}
        description={talentsData?.banner.description!}
        image={talentsData?.banner.image!}
        button={talentsData?.banner.button!}
        route={talentsData?.banner.route!}
      />
      <Content>
        <TextSection>
          <h3>{talentsData?.title}</h3>
          <Divider color="orange" />
          <p>{talentsData?.description}</p>
          <p>{talentsData?.highlight}</p>
        </TextSection>
        <CardsSection>
          {talentsData?.headerCards.map((card, index) => {
            if (card.route !== null) {
              return (
                <Card
                  key={index}
                  title={card.title}
                  route={card.route}
                  image={card.image}
                />
              );
            } else return null;
          })}
        </CardsSection> 
        <InspiringBanner
          title={talentsData?.inspiringBanner.title!}
          description={talentsData?.inspiringBanner.description!}
          image={talentsData?.inspiringBanner.image!}
          num={talentsData?.inspiringCards.length!}
        />

        {talentsData?.inspiringCards.map((card, index) => {
          return (
            <InspiringCard
              key={index}
              picture={card.picture}
              name={card.name}
              role={card.role}
              story={card.story}
              route={card.route}
            />
          );
        })}
      </Content>
    </Container>
  );
}