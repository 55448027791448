import { SyAccordion, SyDivider } from '@sydle/sydle-ui-react';
import { Buildings, Circle, House, MapPin, Money } from 'phosphor-react';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { AuthModal } from '../../components/AuthModal';
import AuthContext from '../../contexts/Auth/AuthContext';
import sendRequest from '../../services/sendRequest';
import { Container, LeftSection, RightSection, StyledSyAccordionItem, StyledSyButton } from './style';

interface OpportunityProps {
  detailsOpportunity: {
    description: string;
    jobDetails: {  // TODO completar a tipagem
      component: "p" | "accordion" | "ul";
      header: string;
      description: string[];
      accordionDetails?: {
        component: "p" | "ul";
        header: string;
        description: string[];
      }[];
    }[];
  };
  infoOpportunity: {
    id: string;
    name: string;
    quantity: number;
    location: string;
    company: string;
    isRemote: boolean;
  }
}

export function Opportunity() {
  const [opportunityData, setOpportunityData] = useState<OpportunityProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setIsAuthModalOpen } = useContext(AuthContext);

  const { opportunityId } = useParams();

  // garante que a página vá para o topo
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setIsLoading(true);
    async function fetchOpportunityData() {
      await sendRequest
        .getOpportunity({ opportunityId: opportunityId })
        .then((response) => {
          setOpportunityData(response);
        })
        .catch((err) => {
          console.log(`Error trying to fetch Opportunity Data: ${err}`);
        });
    }
    fetchOpportunityData();
    setIsLoading(false);
  }, []);

  async function handleSubscription() {
    // * Verifica se o usuário está logado
    // * Se estiver logado, realizar inscrição
    // * Caso não esteja, descer o modal de autenticação
    const accessToken = localStorage.getItem("@bebacknow:accessToken") || false;
    // TODO Validar o token do usuário

    if (!accessToken) {
      setIsAuthModalOpen(true);
    } else {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem("@bebacknow:user")!);
      await sendRequest
        .registerNewApplication({id: user.id, opportunityId: opportunityId})
        .then((res) => {
          if(res.type === "error") {
            toast.error(res.message, {
              toastId: "opportunity-error"
            });
            return
          }
          toast.success(`Sua inscrição foi realizada no processo seletivo ${opportunityData?.infoOpportunity.name}!`, {
            toastId: "opportunity-01-success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftSection>
        <header>
          <h1>
            {opportunityData?.infoOpportunity.name}{" "}
            <span>
              <Circle weight="fill" width="5px" />
              {opportunityData?.infoOpportunity.quantity === 1
                ? `${opportunityData?.infoOpportunity.quantity} vaga`
                : `${opportunityData?.infoOpportunity.quantity} vagas`}
            </span>
          </h1>
          <div className="infos">
            <span>
              <Buildings color="#FC3F0E" /> {opportunityData?.infoOpportunity.company}
            </span>
            <span>
              <MapPin color="#FC3F0E" /> {opportunityData?.infoOpportunity.location}
            </span>
            <span>
              <House color="#FC3F0E" /> {opportunityData?.infoOpportunity.isRemote === true ? "Remoto" : "Presencial"}
            </span>
          </div>
        </header>
        <SyDivider />

        {opportunityData?.detailsOpportunity.description && (
          <>
            <h2>Sobre a Vaga</h2>
            <p>{opportunityData?.detailsOpportunity.description}</p>
          </>
        )}
        {opportunityData?.detailsOpportunity.jobDetails.map(jobDetail => {
          switch (jobDetail.component) {
            case "ul":
              return(
                <article>
                  <h2>{jobDetail.header}</h2>
                  <ul>
                    {jobDetail.description.map(hit => <li>{hit}</li>)}
                  </ul>
                </article>
              )

            case "p":
              return (
                <article>
                  <h2>{jobDetail.header}</h2>
                  {jobDetail.description.map(hit => <p style={{marginBottom: '0'}}>{hit}</p>)}
                </article>
              );

            case "accordion":
              return (
                <article>
                  <SyAccordion>
                    <h2>{jobDetail.header}</h2>
                    {jobDetail.accordionDetails?.map(accordionDetail => {
                      return (
                        <StyledSyAccordionItem label={accordionDetail.header}>
                          {accordionDetail.component === "p" ? (
                            accordionDetail.description.map(hit => <p>{hit}</p>)
                          ) : (
                            <ul>
                              {accordionDetail.description.map(hit => <li>{hit}</li>)}
                            </ul>
                          )}
                        </StyledSyAccordionItem>
                      )
                    })}
                  </SyAccordion>
                </article>
              );
          
            default:
              break;
          }
        })}
      </LeftSection>

      <RightSection>
        <h2>Envie seu currículo</h2>
        <p>
          Buscamos pessoas incríveis para fazer coisas incríveis. <br />
          Candidate-se para esta oportunidade.
        </p>
        <StyledSyButton content="text-only" onClick={handleSubscription}>
          Inscreva-se
        </StyledSyButton>
      </RightSection>

      <AuthModal
        route={`/opportunity/${opportunityId}`}
      />
    </Container>
  );
}