import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import App from './App';
import GlobalStyle from './GlobalStyle';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/Auth/AuthContext';
import { makeServer } from "./mirage/index";
import { ToastContainer } from "react-toastify";

// const environment = process.env.NODE_ENV;
// * Para testar as requisições de produção (estando em dev), comentar a linha de cima e descomentar
// * a linha de baixo
const environment = 'production';

if (environment !== 'production') {
  makeServer();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <GlobalStyle />
        <App />
        <ToastContainer autoClose={3000} />
      </Router>
    </AuthProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
