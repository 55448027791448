import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Banner } from '../../components/Banner';
import AuthContext from '../../contexts/Auth/AuthContext';
import sendRequest from '../../services/sendRequest';
import { Coach } from './Coach'
import { MessageModal } from './MessageModal';
import { Container, Content } from './style'

// TODO
type Coach = {
  id: string;
  name: string;
  photo: string;
  professionalProfile: string;
}

interface CoachesProps {
  banner: {
    title: string;
    description: string;
    image: string;
    button: boolean;
    route: string | null;
  };
  coaches: Coach[];
}


export function Coaches() {
  const [coachesData, setCoachesData] = useState<CoachesProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState<boolean>(false);
  const [selectedCoachId, setSelectedCoachId] = useState<string>("");
  const [selectedCoachName, setSelectedCoachName] = useState<string>("");
  
  const { setIsAuthModalOpen } = useContext(AuthContext);
  
  // garante que a página vá para o topo
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  useEffect(() => {
    setIsLoading(true);
    async function fetchCoachesData() {
      await sendRequest
        .getCoaches({})
        .then((response) => {
          setCoachesData(response);
        })
        .catch((err) => {
          console.log(`Error trying to fetch Coaches Data: ${err}`);
        });
      }
      fetchCoachesData();
      setIsLoading(false);
    }, []);
    
  return (
    <Container>
      <Banner
        title={coachesData?.banner.title!}
        description={coachesData?.banner.description!}
        image={coachesData?.banner.image!}
        button={coachesData?.banner.button!}
        route={coachesData?.banner.route!}
      />
      <Content>
        {coachesData?.coaches.map((coach) => (
          <Coach
            key={coach.id}
            id={coach.id}
            name={coach.name}
            photo={coach.photo}
            professionalProfile={coach.professionalProfile}
            setIsAuthModalOpen={setIsAuthModalOpen}
            setIsMessageModalOpen={setIsMessageModalOpen}
            setSelectedCoachId={setSelectedCoachId}
            setSelectedCoachName={setSelectedCoachName}
          />
        ))}
      </Content>

      <MessageModal
        isMessageModalOpen={isMessageModalOpen}
        setIsMessageModalOpen={setIsMessageModalOpen}
        selectedCoachId={selectedCoachId}
        selectedCoachName={selectedCoachName}
      />
    </Container>
  );
}