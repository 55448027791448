import { FileText, Trash } from 'phosphor-react';
import React, { Dispatch, SetStateAction } from 'react'
import { Container } from './style';

interface FileProps {
  file: string | ArrayBuffer | null;
  fileName: string | null;
  disabled: boolean | null;
  onRemoveFile: () => void;
}


function File({ file, fileName, disabled, onRemoveFile }: FileProps) {
  return (
    <>
      {(file || file === "") && (
        <Container>
          <FileText color="#F55D31" weight="fill" size={28} />
          <span>{fileName}</span>
          {
            !disabled ? <Trash color="#F55D31" weight="fill" size={28} className="trashIcon" onClick={onRemoveFile} />
              : null
          }
        </Container>
      )}
    </>
  );
}

export default File