import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthModal } from "../../components/AuthModal";
import { Banner } from "../../components/Banner";
import AuthContext from "../../contexts/Auth/AuthContext";
import sendRequest from "../../services/sendRequest";
import { ConfirmationModal } from "./ConfirmationModal";
import { EventCard } from "./EventCard";
import { Filter } from "./Filter";
import { Container, EventsSection } from "./style";


export const eventsCategories = [
  {
    key: "all",
    value: "todos"
  },
  {
    key: "webinar",
    value: "webinário"
  },
  {
    key: "seminary",
    value: "presencial"
  } 
]
interface EventsDataProps {
  banner: {
    title: string;
    description: string;
    image: string;
    button: boolean;
    route: string | null;
  };
  events: {
    id: string;
    title: string;
    description: string;
    date: string;
    time: string;
    location: string;
    soon: boolean;
    type: string;
  }[];
}

export function Events() {
  const [eventsData, setEventsData] = useState<EventsDataProps>();
  const [filter, setFilter] = useState<string>("all");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [selectedEventId, setSelectedEventId] = useState<string>("");
  const [selectedEventName, setSelectedEventName] = useState<string>("");
  const [selectedEventDate, setSelectedEventDate] = useState<string>("");

  const { setIsAuthModalOpen } = useContext(AuthContext);

  // garante que a página vá para o topo
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    async function fetchEventsData() {
      await sendRequest
        .getEvents({})
        .then((response) => {
          setEventsData(response);
        })
        .catch((err) => {
          console.log(`Error trying to fetch eventsData: ${err}`);
        });
    }
    fetchEventsData();
  }, []);

  return (
    <Container>
      <Banner
        title={eventsData?.banner.title!}
        description={eventsData?.banner.description!}
        image={eventsData?.banner.image!}
        button={eventsData?.banner.button!}
        route={eventsData?.banner.route!}
      />
      <Filter filter={filter} setFilter={setFilter} />
      <EventsSection>
        {eventsData?.events
          .filter((event) => filter === "all" || event.type === filter)
          .map((filteredEvent) => {
            return (
              <EventCard
                key={filteredEvent.id}
                type={filteredEvent.type}
                title={filteredEvent.title}
                description={filteredEvent.description}
                date={filteredEvent.date}
                time={filteredEvent.time}
                location={filteredEvent.location}
                soon={filteredEvent.soon}
                id={filteredEvent.id}
                setIsAuthModalOpen={setIsAuthModalOpen}
                setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                setSelectedEventId={setSelectedEventId}
                setSelectedEventName={setSelectedEventName}
                setSelectedEventDate={setSelectedEventDate}
              />
            );
          })}
      </EventsSection>

      <AuthModal route="/events" />
      <ConfirmationModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        selectedEventId={selectedEventId}
        selectedEventName={selectedEventName}
        selectedEventDate={selectedEventDate}
      />
    </Container>
  );
}
