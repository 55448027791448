import { SyButton, SyInput, SySelect, SySwitch } from "@sydle/sydle-ui-react";
import styled, { css } from "styled-components";

export const Container = styled.div`
	position: relative;
	top: 80px;

	padding: 0rem var(--page-lateral-padding) 4rem;
	position: relative;
	background-color: var(--light-orange);
	background-color: #FF7B24;
	background-image: linear-gradient(180deg, #FF7B24 0%, #ec9f05 150%);

	/* .background {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 40vh;

		background-image: url("https://images.pexels.com/photos/3183183/pexels-photo-3183183.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		z-index: 2;
  	} */
`;

export const Info = styled.div`
	width: 100%;
	background-color: white;
	border: 1px solid black;
	border-top: 0px;

	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);

	text-align: center;
	font-size: 22px;
	font-weight: 600;

	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;

	padding: 10px;
	margin-bottom: 100px;

	@media (max-width: 520px) {
		font-size: 16px;
	}
`;

export const Loading = styled.div`
	display: flex;
	position: fixed;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 9999;

	@-webkit-keyframes spin {
		from {
			-webkit-transform: rotate(0deg);
		}
		to {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		from {
		transform: rotate(0deg);
		}
		to {
		transform: rotate(360deg);
		}
	}

	::after {
		content: "";
		display: block;
		position: absolute;
		left: 48%;
		top: 40%;
		width: 40px;
		height: 40px;
		border-style: solid;
		border-color: var(--light-orange);
		border-top-color: transparent;
		border-width: 4px;
		border-radius: 50%;
		-webkit-animation: spin 0.8s linear infinite;
		animation: spin 0.8s linear infinite;
	}
`;

export const Content = styled.main`
	background-color: #fff;
	border-radius: 5px;
	padding: 2rem 4rem;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);

	.header {
		position: relative;
		display: flex;
		flex-direction: column;
    	gap: 10px;

		.isEditing {
			box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.3),
			inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
			cursor: pointer;

			.profilePictureLayer:nth-of-type(1)  {
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				color: white;
				position: absolute;
				top: 50%;
				left: 30%;
				transform: translateX(-50%) translateY(-50%);
				cursor: pointer;
			}

			.profilePictureLayer:nth-of-type(2)  {
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				color: white;
				position: absolute;
				top: 50%;
				left: 70%;
				transform: translateX(-50%) translateY(-50%);
				cursor: pointer;
			}
		}
	}

	.body {
		margin-top: 3rem;

		sy-input:not(:last-child) {
			margin-bottom: 1rem;
		}

		sy-select:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	@media (max-width: 520px) {
		padding: 2rem;
	}
`;

interface PhotoContainerProps {
  isEditing: boolean;
  src?: string;
}

export const PhotoContainer = styled.div<PhotoContainerProps>`
	position: absolute;
	top: calc(-2rem - 75px);
	left: 50%;
	transform: translateX(-50%);
	width: 150px;
	height: 150px;
	border: 5px solid #FF7B24;
	border-radius: 99px;
	box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.3);
	background-color: #FFFFFF;
	background-image: ${(props) => `url(${props.src})`};
	background-position: center;
	background-size: cover;
	transition: 0.2s;
	display: flex;
	justify-content: center;
	align-items: center;

	.userIcon {
		background-color: #FFFFFF;
		width: 150px;
		height: 150px;
		border-radius: 99px;

		svg {
			width: 150px; 
			height: 150px;
		}
	}

	input,
	.profilePictureLayer {
		display: none;
	}

	.spinner {
		color: #FF6600;
	}
`;

export const InputContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
`;

export const SocialMediaContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
	width: 100%;

	background-color: #edf2f7;

	margin-top: 30px;
	margin-bottom: 5px;
	padding: 10px;
	border: 2px dashed rgb(203, 213, 224);
`;

export const StyledSyInput = styled(SyInput)`
	width: 48%;
	margin-top: 20px;

	@media (max-width: 700px) {
		width: 100%;
	}

	--sy-input-color: #313131;
	--sy-input-border-color: #313131;

	--sy-input-border-color-hover: #313131;

	--sy-input-border-color-focus: var(--light-orange);
	--sy-input-color-label-focus: var(--light-orange);

	cursor: pointer;
`;

export const StyledSyButton = styled(SyButton)`
	margin-left: auto;
	min-width: 150px;

	--sy-button-padding: 0.5rem 1rem;
	--sy-button-font-weight: 500;
	--sy-button-letter-spacing: 1px;
	--sy-button-border-radius: 4px;
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

	--sy-button-background-color: var(--light-orange);
	--sy-button-background-color-hover: var(--light-orange);
	--sy-button-background-color-active: var(--light-orange);
	--sy-button-border-color: var(--light-orange);
	--sy-button-border-color-hover: var(--light-orange);
	--sy-button-border-color-active: var(--light-orange);
	--sy-button-color: #fff;
	--sy-button-color-hover: #fff;
	--sy-button-color-active: #fff;

	--sy-button-loading-color: #fff;

	&[loading="true"] {
		cursor: default;
		pointer-events: none;
	}

	&:nth-of-type(2) {
		--sy-button-background-color: #FFF;
		--sy-button-background-color: #FFF;
		--sy-button-background-color-hover: #FFF;
		--sy-button-background-color-active: #FFF;
		--sy-button-border-color: #FFF;
		--sy-button-border-color-hover: #FFF;
		--sy-button-border-color-active: #FFF;
		--sy-button-color: var(--light-orange);
		--sy-button-color-hover: var(--light-orange);
		--sy-button-color-active: var(--light-orange);

		--sy-button-loading-color: var(--light-orange);

		@media (max-width: 690px) {
			margin-top: 0;
		}
	}

	svg {
		margin-right: 0.5rem !important;
	}

	@media (max-width: 690px) {
		margin-top: 4rem;
	}

	@media (max-width: 520px) {
		width: 100%;
	}
`;

export const StyledSySwitch = styled(SySwitch)`
	margin-top: 2rem;
	--sy-switch-input-background-color-checked: var(--light-orange);
	--sy-switch-background-color-active: var(--light-orange);
	--sy-switch-background-color: var(--light-orange);
	--sy-switch-background-color-active-checked: var(--light-orange);
	--sy-switch-background-color-hover-checked: var(--light-orange);
`;

export const StyledSySelect = styled(SySelect)`
	width: 48%;
	margin-top: 20px;

	@media (max-width: 700px) {
		width: 100%;
	}
	--sy-select-border-color: #313131;
	--sy-select-border-color-focus: var(--light-orange);
	--sy-select-color-label-focus: var(--light-orange);
	--sy-select-dropdown-search-highlight-color: var(--light-orange);

	--sy-select-item-background-color: transparent;
	--sy-select-item-background-color-hover: rgba(255, 102, 0, 0.1);

	cursor: pointer;

	&[disabled] {
		opacity: 1;
		cursor: default;
	}
`;