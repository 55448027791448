import React, { useState } from "react";

import { X } from "phosphor-react";
import { Container, Content, Footer, Overlay, StyledSyButton } from "./style";
import sendRequest from "../../../services/sendRequest";
import { toast } from "react-toastify";

interface MessageModalProps {
  isMessageModalOpen: boolean;
  setIsMessageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCoachId: string;
  selectedCoachName: string;
}

export function MessageModal({
  isMessageModalOpen,
  setIsMessageModalOpen,
  selectedCoachId,
  selectedCoachName,
}: MessageModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  async function handleConfirmMessage() {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("@bebacknow:user")!);
    await sendRequest
      .createCoachRequest({
        personId: user.id,
        coachId: selectedCoachId,
        message,
      }) // TODO realizar a requisição ao endpoint correto
      .then((res) => {
        toast.success(
          `Sua mensagem foi enviada ao coach ${selectedCoachName} com sucesso!`,
          {
            toastId: "message-modal-01-success",
          }
        );
        setMessage("");
      })
      .catch((err) => {
        toast.error(`Não foi possível enviar a sua mensagem no momento`, {
          toastId: "message-modal-error",
        });
      });
    setIsLoading(false);
    setIsMessageModalOpen(false);
  }

  isMessageModalOpen
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  return (
    <Overlay isAuthModalOpen={isMessageModalOpen}>
      <Container isAuthModalOpen={isMessageModalOpen}>
        <div
          className="close"
          onClick={() => {
            setIsMessageModalOpen(false);
            setMessage("");
          }}
        >
          <X />
        </div>
        <Content>
          <h3>Enviar mensagem</h3>
          <main>
            <p>Para: {selectedCoachName}</p>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </main>
          <Footer>
            <StyledSyButton
              background="var(--light-orange)"
              color="#fff"
              loading={isLoading}
              onClick={handleConfirmMessage}
            >
              Confirmar
            </StyledSyButton>
            <StyledSyButton
              background="#a0a0a0"
              color="#fff"
              onClick={() => setIsMessageModalOpen(false)}
            >
              Cancelar
            </StyledSyButton>
          </Footer>
        </Content>
      </Container>
    </Overlay>
  );
}
