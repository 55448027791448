import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContext from "../../contexts/Auth/AuthContext";
import sendRequest from "../../services/sendRequest";
import validate from "../../utils/validator";
import { EmailModal } from "./EmailModal";
import { Container, LeftSection, RightSection, StyledSyButton, StyledSyInput } from "./style";
import BackgroundImage from "../Register/assets/bg.png";

interface IsValidProps {
  email: string | undefined;
  token: string | undefined;
}

export function ValidateToken() {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);

  const [errors, setErrors] = useState<IsValidProps>({
    email: undefined,
    token: undefined,
  });
  const [isLoading, setIsLoading] = useState(false)

  const authContext = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { setIsAuthModalOpen } = useContext(AuthContext);

  // garante que a página vá para o topo
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    handleValidateToken();
  }, [])

  async function handleValidateToken() {
    let tokenp = String(searchParams.get("token"));
    let emailp = String(searchParams.get("email"));

    if (tokenp !== "null") { setToken(tokenp) };
    if (emailp !== "null") { setEmail(emailp) };

    if ((tokenp !== "null" && emailp !== "null") || (token && email)) {
      setIsLoading(true);
      let updateErrors = { ...errors };
      updateErrors.email = validate.validateToken.email(email ? email : emailp);
      updateErrors.token = validate.validateToken.token(token ? token : tokenp);

      if (updateErrors.email) {
        setErrors(updateErrors);
        setIsLoading(false);
        return;
      }

      if (updateErrors.token) {
        setErrors(updateErrors);
        setIsLoading(false);
        return;
      }

      if ((email && token) || (emailp && tokenp)) {
        const response = await sendRequest
          .validateEmail({
            email: email ? email : emailp,
            emailToken: token ? token : tokenp,
          })
          .then((res) => {
            toast.success(`Usuário validado com sucesso. Realize o login na plataforma.`, {
              toastId: "validate-token-01-success",
            });
            return res;
          })
          .catch((err) => {
            toast.error('Não foi possível validar o usuário.', {
              toastId: "validate-token-error"
            });
            return;
          });

        if (response) {
          setIsAuthModalOpen(true);
          removeQueryParams();
        }
      }

      setIsLoading(false);
    }
  }

  const removeQueryParams = () => {
    const param = searchParams.get('token');
    const param2 = searchParams.get('email');

    if (param) {
      //delete each query param
      searchParams.delete('token');
    }

    if (param2) {
      //delete each query param
      searchParams.delete('email');
    }

  };

  return (
    <Container>
      <LeftSection>
        <h3>
          Valide o seu login inserindo o código de verificação enviado para o
          seu email
        </h3>
        <form>
          <StyledSyInput
            type="email"
            value={email}
            onSyInputDidInput={(e) =>
              setEmail((e.target as HTMLInputElement).value)
            }
            label="E-mail"
            error={errors.email}
          ></StyledSyInput>
          <StyledSyInput
            type="text"
            value={token}
            onSyInputDidInput={(e) =>
              setToken((e.target as HTMLInputElement).value)
            }
            label="Código"
            error={errors.token}
          ></StyledSyInput>
        </form>
        <StyledSyButton onClick={handleValidateToken} loading={isLoading}>
          Validar
        </StyledSyButton>
        <span onClick={() => setIsEmailModalOpen(true)} >Não recebi meu código</span>
      </LeftSection>
      <RightSection style={{ backgroundImage: `url(${BackgroundImage})` }}></RightSection>
      <EmailModal isEmailModalOpen={isEmailModalOpen} setIsEmailModalOpen={setIsEmailModalOpen} />
    </Container>
  );
}