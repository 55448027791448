import styled from "styled-components";


export const Container = styled.article`
  margin-top: 2rem;

  header {
    display: flex;
    align-items: flex-end;

    @media (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
    };
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;


interface ImageContainerProps {
  image: string;
}

export const ImageContainer = styled.div<ImageContainerProps>`
  height: 150px;
  width: 150px;
  border-radius: 99px;
  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    height: 100px;
    width: 100px;
  }
`;


export const Info = styled.div`
  margin-left: 2rem;

  .name {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .role {
    font-size: 1.5rem;
    font-weight: 700;
    font-style: italic;  
  }

  @media(max-width: 550px) {
    margin-left: 0;
    margin-top: 1rem;
  }
`;


export const Button = styled.span`
  margin-top: 1rem;
  display: flex;
  gap: 0.25rem;
  width: max-content;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background-color: var(--light-orange);
  color: #fff;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
`;