import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

export default function Modal({visible, setVisible, Content, onClose}) {

  const show = () => {
    setVisible(true);
  }

  const hide = () => {
    onClose();
    setVisible(false);
  }

  const customStyles = {
    maxWidth: "400px",
    width: "100%"
  };

  return (
    <div>
      <Rodal visible={visible} onClose={hide} height={650} customStyles={customStyles}>
        <Content />
      </Rodal>
    </div>
  );
}
