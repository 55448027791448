import { PhoneCall } from 'phosphor-react';
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Divider } from '../../../components/Divider';
import { Button, Container, ImageContainer, Info } from './style';


interface CoachProps {
  id: string;
  name: string;
  photo: string;
  professionalProfile: string;
  setIsAuthModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsMessageModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedCoachId: Dispatch<SetStateAction<string>>;
  setSelectedCoachName: Dispatch<SetStateAction<string>>;
}

export function Coach({
  id,
  name,
  photo,
  professionalProfile,
  setIsAuthModalOpen,
  setIsMessageModalOpen,
  setSelectedCoachId,
  setSelectedCoachName,
}: CoachProps) {
  function handleCoachSelect() {
    // * Verifica se o usuário está logado
    // * Se estiver logado, realizar inscrição
    // * Caso não esteja, descer o modal de autenticação
    // TODO Validar o token do usuário caso esteja logado
    const accessToken = localStorage.getItem("@bebacknow:accessToken") || false;

    if (!accessToken) {
      setIsAuthModalOpen(true);
    } else {
      setSelectedCoachId(id);
      setSelectedCoachName(name);
      setIsMessageModalOpen(true);
    }
  }

  return (
    <Container>
      <header>
        <ImageContainer image={photo}></ImageContainer>
        <Info>
          <p className="name">{name}</p>
        </Info>
      </header>
      <Divider color="orange" />
      <main>
        <p>{professionalProfile}</p>
        <Button onClick={handleCoachSelect}>
          Entrar em Contato <PhoneCall size={24} />
        </Button>
      </main>
    </Container>
  );
}