const register = {
  name: (name: string) => {
    if (name.length === 0) {
      return "Insira um nome";
    }
    return undefined;
  },

  email: (email: string) => {
    if (email.length === 0) {
      return "Insira um endereço de email";
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return "Insira um endereço de email válido";
    }
    return undefined;
  },

  phone: (phone: string) => {
    if (phone && phone.replace(/[^a-zA-Z0-9]/g, "").length < 11) {
      return "Insira um celular válido";
    }
    return undefined;
  },
  
  country: (country: string | string[]) => {
    if (country.length === 0) {
      return "Selecione um país";
    }
    return undefined;
  },

  brazilianState: (brazilianState: string | string[]) => {
    if (brazilianState.length === 0) {
      return "Selecione um estado";
    }
    return undefined;
  },

  city: (city: string | string[]) => {
    if (city.length === 0) {
      return "Selecione uma cidade";
    }
    return undefined;
  },
  
  profession: (profession: string) => {
    if (profession.length === 0) {
      return "Insira uma profissão";
    }
    return undefined;
  },

  yearsOfExperience: (yearsOfExperience: string | string[]) => {
    if (yearsOfExperience.length === 0) {
      return "Selecione quantos anos de experiência possui";
    }
    return undefined;
  },

  yearsOfCareerBreak: (yearsOfCareerBreak: string | string[]) => {
    if (yearsOfCareerBreak.length === 0) {
      return "Selecione a resposta para a pausa na carreira";
    }
    return undefined;
  },

  whereDidYouFindUs: (whereDidYouFindUs: string | string[]) => {
    if (whereDidYouFindUs.length === 0) {
      return "Selecione onde nos encontrou";
    }
    return undefined;
  },

  highestEducationalQualification: (highestEducationalQualification: string | string[]) => {
    if (highestEducationalQualification.length === 0) {
      return "Selecione sua maior qualificação educacional";
    }
    return undefined;
  },

  reasonForCareerBreak: (reasonForCareerBreak: string | string[]) => {
    if (reasonForCareerBreak.length === 0) {
      return "Selecione o motivo para seu intervalo de carreira";
    }
    return undefined;
  },

  password: (password: string) => {
    if (password.length === 0) {
      return "Insira uma senha";
    }
    if (password.length < 8) {
      return "A sua senha deve ter mais de 8 caracteres";
    }
    return undefined;
  }
};

const login = {
  name: (name: string) => {
    if (name.length === 0) {
      return "Insira um nome";
    }
    return undefined;
  },

  email: (email: string) => {
    if (email.length === 0) {
      return "Insira um endereço de email";
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return "Insira um endereço de email válido";
    }
    return undefined;
  },

  password: (password: string) => {
    if (password.length === 0) {
      return "Insira uma senha";
    }
    if (password.length < 8) {
      return "A senha inserida deve ter mais de 8 caracteres";
    }
    return undefined;
  },
};

const updateProfile = {
  name: (name: string) => {
    if (name.length === 0) {
      return "Insira um nome";
    }
    return undefined;
  },

  email: (email: string) => {
    if (email.length === 0) {
      return "Insira um endereço de email";
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return "Insira um endereço de email válido";
    }
    return undefined;
  },
};

const contactForm = {
  name: (name: string) => {
    if (name.length === 0) {
      return "Insira um nome";
    }
    return undefined;
  },

  email: (email: string) => {
    if (email.length === 0) {
      return "Insira um endereço de email";
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return "Insira um endereço de email válido";
    }
    return undefined;
  },

  phoneNumber: (phoneNumber: string) => {
    phoneNumber = phoneNumber.replaceAll('(', '');
    phoneNumber = phoneNumber.replaceAll(')', '');
    phoneNumber = phoneNumber.replaceAll(' ', '');
    if (phoneNumber.length === 0) {
      return "Insira um número de telefone";
    }
    if (!(/^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/gm).test(phoneNumber)) {
      return "Insira um número de telefone válido";
    }
    return undefined;
  },

  enterpriseName: (enterpriseName: string) => {
    if (enterpriseName.length === 0) {
      return "Insira o nome da empresa";
    }
    return undefined;
  },

  cnpj: (cnpj: string) => {
    if (cnpj.length === 0) {
      return "Insira o cnpj da empresa";
    }

    // Esta função retira os caracteres . / - da string do cnpj, deixando apenas os números 
    var strCNPJ = cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');

    // Testa as sequencias que possuem todos os dígitos iguais e se o cnpj não tem 14 dígitos, retonando falso e exibindo uma msg de erro
    if (strCNPJ === '00000000000000' || strCNPJ === '11111111111111' || strCNPJ === '22222222222222' || strCNPJ === '33333333333333' ||
      strCNPJ === '44444444444444' || strCNPJ === '55555555555555' || strCNPJ === '66666666666666' || strCNPJ === '77777777777777' ||
      strCNPJ === '88888888888888' || strCNPJ === '99999999999999' || strCNPJ.length !== 14) {
      return "Insira um cnpj válido!";
    }

    // A variável numeros pega o bloco com os números sem o DV, a variavel digitos pega apenas os dois ultimos numeros (Digito Verificador).
    var tamanho = strCNPJ.length - 2;
    var numeros = strCNPJ.substring(0, tamanho);
    var digitos = strCNPJ.substring(tamanho); 
    var soma = 0;
    var pos = tamanho - 7;

    // Os quatro blocos seguintes de funções irá reaizar a validação do CNPJ propriamente dito, conferindo se o DV bate. Caso alguma das funções não consiga verificar
    // o DV corretamente, mostrará uma mensagem de erro ao usuário e retornará falso, para que o usário posso digitar novamente um número 
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(0))) {
      return "Insira um cnpj válido!";
    }

    tamanho = tamanho + 1;
    numeros = strCNPJ.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let k = tamanho; k >= 1; k--) {
      soma += parseInt(numeros.charAt(tamanho - k)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(1))) {
      return "Insira um cnpj válido!";
    }

    return undefined;
  },
};

const validateToken = {
  email: (email: string) => {
    if (email.length === 0) {
      return "Insira um endereço de email";
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return "Insira um endereço de email válido";
    }
    return undefined;
  },

  token: (token: string) => {
    if (token.length === 0) {
      return "Insira o código enviado por email";
    }
    return undefined;
  },
};

export default { register, login, updateProfile, contactForm, validateToken };