import styled, { keyframes } from "styled-components";

export const Navbar = styled.nav`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	
	width: 100%;
	height: 80px;
	padding: 0rem 10rem;
	background-color: #FFF;
	position: fixed;
    z-index: 9999;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

	.logo {
		cursor: pointer;
	}

	a {
		color: #616161 !important;
    	font-weight: 500;
	}

	.outlined-button {
		padding: 0.5em 1em;
		width: 80px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #FF7B24;
		transition: background-color 0.3s, color 0.3s;
		cursor: pointer;
		
		color: #FF7B24;
		background-color: transparent;

		&:hover {
			color: #FFF;
			border-color: #FFF;
			background-color: #FF7B24;
		}
	}

	.buttons {
		display: flex;
		align-items: center;
		justify-content: end;
		width: 100%;
		gap: 20px;

		.outlined-button {	
			color: #FFF;
			border-color: #FFF;
			background-color: #FF7B24;
		}

		@media (max-width: 320px) {
			gap: 10px;
		}
	}

	@media (max-width: 1200px) {
		padding: 0rem 2rem;
	}
	@media (max-width: 320px) {
		padding: 5px;
		gap: 0;
	}
`;

export const Background = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const MenuContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	
	width: 100%;
	height: 80px;
	background-color: #FFF;

	@media (max-width: 480px) {
		width: auto;
	}
`;

export const MenuList = styled.ul`
	display: flex;
	flex-direction: row;
	gap: 5px;

	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;

	li {
		border-top: 2px solid transparent;
		transition: border-color 0.3s ease;

		a {
			text-align: center;
			padding: 14px 16px;
			text-decoration: none;
		}
	}

	li:hover {
		border-top-color: #FF7B24;
	}

	li.dropdown {
		display: inline-block;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #F9F9F9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
	}
		
	.dropdown-content a {
		display: block;
		padding: 12px 16px;
		text-decoration: none;
		text-align: left;
	}
		
	.dropdown-content a:hover { 
		color: #FFF !important;
		background-color: #FF7B24;
	}
		
	.dropdown:hover .dropdown-content {
		display: block;
	}

	svg {
		margin-left: -10px;
	}
`;

const slideIn = keyframes`
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
`;

const slideOut = keyframes`
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(100%);
	}
`;

export const MobileMenu = styled.div`
	display: ${({ open }) => (open ? 'flex' : 'none')};
	flex-direction: column;
	align-items: center;
	justify-content: start;

	width: 70vw;
	height: calc(100vh - 80px);
	position: absolute;
	z-index: 9999;
	top: 80px;
	right: 0;
	padding: 3rem;
	border-radius: 0 0 0 20px;
	background-color: #FFF;
	animation: ${({ open }) => (open ? slideIn : slideOut)} 0.3s ease forwards;

	${MenuList} {
		flex-direction: column;
		align-items: start;
		justify-content: start;
		gap: 15px;
		width: 100%;
		overflow-y: auto;

		.item {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.submenu-item {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		li {
			border: none;
		}

		a {
			display: inline-block;
			font-size: 20px;
		}
	}

	.submenu-mobile {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: center;

		a {
			padding: 14px 16px;
		}
	}

	@media (max-width: 1200px) {
        width: 40vw;
    }
	@media (max-width: 1024px) {
        width: 50vw;
    }
	@media (max-width: 480px) {
        width: 85vw;
		padding: 2rem;
    }
`;

export const SubMenuContent = styled.div`
	display: ${({ openSubMenu }) => (openSubMenu ? 'flex' : 'none')};
	flex-direction: column;
	align-items: start;
	justify-content: center;
	margin: 0 0 0 15px;
`;

export const UserContainer = styled.div`
	.user {
		cursor: pointer;
		border-radius: 25px;
		width: 50px;
		height: 50px;
		object-fit: cover;
	}
`;

export const UserDropdown = styled.ul`
	position: absolute;
	background-color: #FFF;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	display: ${(props) => (props.showDropdown ? "block" : "none")};

	list-style: none;
	padding-left: 0;

	margin-left: -35px;

	&:last-child {
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
`;

export const UserDropdownItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #616161;
	text-decoration: none;
	font-size: 1rem;
	cursor: pointer;
	width: 120px;

	&:hover {
		background-color: #fd6a07;
		color: #FFF;
	}

	&:last-child {
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	svg {
		margin: 10px;
	}
`;

export const WhatsappButton = styled.a`
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: #25d366;
	color: #fff;
	border-radius: 50px;
	text-align: center;
	font-size: 30px;
	box-shadow: 2px 2px 3px #999;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
`;