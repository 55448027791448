import axios from "axios";
import { api } from "./api";

const sendRequest = {
  getHomeOLD: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getHomeOLD", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getHomeOLD: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getHome: async () => {
    const result = await api
      .post("/site/siteFront/getHome")
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getHome: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getStates: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getStates", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getStates: ${err}`);
        return err;
      });

    return result;
  },

  getCities: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getCities", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getCities: ${err}`);
        return err;
      });

    return result;
  },

  getAcademicLevelOptions: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getAcademicLevel", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getAcademicLevelOptions:: ${err}`);
        return err;
      });

    return result;
  },
  
  // getExperienceOptions: async (params: {}) => {
  //   const result = await api
  //     .post("/site/siteFront/getExperience", params)
  //     .then((response) => {
  //       if (response.status === 200 || response.status === 201) {
  //         return response.data;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(`Erro ao realizar requisição getExperienceOptions: ${err}`);
  //       return err;
  //     });

  //   return result;
  // },
  // getCarrerTimeoutOptions: async (params: {}) => {
  //   const result = await api
  //     .post("/site/siteFront/getCareerBreak", params)
  //     .then((response) => {
  //       if (response.status === 200 || response.status === 201) {
  //         return response.data;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(`Erro ao realizar requisição getCarrerTimeoutOptions: ${err}`);
  //       return err;
  //     });

  //   return result;
  // },

  getArticle: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getArticle", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getArticle: ${err}`);
        return err;
      });

    return result;
  },

  getAcademyContent: async () => {
    const result = await api
      .post("/site/siteFront/getAcademyContent")
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getAcademyContent: ${err}`);
        return err;
      });

    return result;
  },

  getWorkshopRecording: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getWorkshopRecording", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getWorkshopRecording: ${err}`);
        return err;
      });

    return result;
  },

  getWorkshopRegistrationPage: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getWorkshopRegistrationPage", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getWorkshopRegistrationPage: ${err}`);
        return err;
      });

    return result;
  },

  workshopRegistration: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/workshopRegistration", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição workshopRegistration: ${err}`);
        return err;
      });

    return result;
  },

  getEmailPreferences: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getEmailPreferences", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getEmailPreferences: ${err}`);
        return err;
      });

    return result;
  },

  updateEmailPreferences: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/updateEmailPreferences", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição updateEmailPreferences: ${err}`);
        return err;
      });

    return result;
  },

  downloadEbook: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/downloadEbook", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.error(`Erro ao realizar requisição downloadEbook: ${err}`);
        throw err;
      });

    return result;
  },

  createPersonOfEbook: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/createPersonOfEbook", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição createPersonOfEbook: ${err}`);
        return err;
      });

    return result;
  },

  getEbookPage: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getEbookPage", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getEbookPage: ${err}`);
        return err;
      });

    return result;
  },

  getEbookPage2: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getEbookPage2", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getEbookPage2: ${err}`);
        return err;
      });

    return result;
  },

  getNavbar: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getNavbar", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getEbookPage: ${err}`);
        return err;
      });

    return result;
  },

  getFooter: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getFooter", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getFooter: ${err}`);
        return err;
      });

    return result;
  },

  getContactWhatsapp: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getContactWhatsapp", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getContactWhatsapp: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  sendPasswordRecoveryEmail: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/sendPasswordRecoveryEmail", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(
          `Erro ao realizar requisição sendPasswordRecoveryEmail: ${err}`
        );
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  finishingPasswordRecovering: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/finishingPasswordRecovering", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(
          `Erro ao realizar requisição finishingPasswordRecovering: ${err}`
        );
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getTestimonials: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getTestimonials", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getTestimonials: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getAbout: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getAbout", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getAbout: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getEvents: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getEvents", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getEvents: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getPartners: async () => {
    const result = await api
      .post("/site/siteFront/getPartners")
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getPartners: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getPartnersOLD: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getPartners", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getPartners: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getTalents: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getTalents", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getTalents: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getOpportunities: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getOpportunities", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getOpportunities: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getProfile: async (params: { id: string }) => {
    const result = await api
      .post("/site/siteFront/getProfile", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getProfile: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getBusinessContact: async () => {
    const result = await api
      .post("/site/siteFront/getBusinessContact", {})
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getBusinessContact: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getContentPage: async (params: { id: string }) => {
    const result = await api
      .post("/site/siteFront/getContentPage", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getBusinessContact: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  updateProfile: async (params: {
    id: string;
    email: string;
    name: string;
    country: string | string[] | null;
    brazilianState?: string | string[] | null;
    city?: string | string[] | null;
    region?: string;
    profession: string | null;
    yearsOfExperience: string | string[] | null;
    yearsOfCareerBreak: string | string[] | null;
    whereDidYouFindUs: string | string[] | null;
    highestEducationalQualification: string | string[] | null;
    reasonForCareerBreak: string | string[] | null;
    receiveNewsletter: boolean;
    resume: {
      base64: string | ArrayBuffer | null;
      fileName: string | null;
    };
    profilePic: {
      base64: string | ArrayBuffer | null;
      fileName: string | null;
    };
  }) => {
    const result = await api
      .post("/site/siteFront/updateProfile", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição updateProfile: ${err}`);
        return err;
      });

    return result;
  },

  getOpportunitiesArray: async (params: {
    enterprises: object;
    jobs: object;
    locations: object;
    currentPage: number;
    itemsPerPage: number;
  }) => {
    const result = await api
      .post("/site/siteFront/getOpportunitiesArray", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(
          `Erro ao realizar requisição getOpportunitiesArray: ${err}`
        );
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getOpportunity: async (params: { opportunityId: string | undefined }) => {
    const result = await api
      .post("/site/siteFront/getOpportunity", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getOpportunity: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getCoaches: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getCoaches", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getCoaches: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getCountries: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getCountries", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getCountries: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  getEducationLevels: async (params: {}) => {
    const result = await api
      .post("/site/siteFront/getEducationLevels", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getEducationLevels: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  createCoachRequest: async (params: {
    personId: string;
    coachId: string;
    message: string;
  }) => {
    const result = await api
      .post("/site/siteFront/createCoachRequest", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição createCoachRequest: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  createEnterpriseContactRequest: async (params: {
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    contactSector: string;
    contactJobtitle: string;
    enterpriseName: string;
    numberEmployees: string;
    howDidYouGetToUs: string;
  }) => {
    const result = await api
      .post("/site/siteFront/createEnterpriseContactRequest", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição createCoachRequest: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return {
          error: JSON.parse(err.request.responseText).generalMessages[0].text,
        };
      });

    return result;
  },

  registerNewApplication: async (params: {
    id: string;
    opportunityId: string | undefined;
  }) => {
    const result = await api
      .post("/site/siteFront/registerNewApplication", params)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição getOpportunity: ${err}`);
        return {
          type: "error",
          message: JSON.parse(err.request.responseText).generalMessages[0].text,
        };
      });

    return result;
  },

  registerNewUser: async (params: {
    email: string;
    phone: string;
    name: string;
    country: string | string[] | null;
    brazilianState: string | string[] | null;
    city: string | string[] | null;
    region: string;
    profession: string | null;
    yearsOfExperience: string | string[] | null;
    yearsOfCareerBreak: string | string[] | null;
    whereDidYouFindUs: string | string[] | null;
    highestEducationalQualification: string | string[] | null;
    reasonForCareerBreak: string | string[] | null;
    password: string | null;
    url: string | null;
    receiveNewsletter: boolean;
  }) => {
    const result = await api
      .post("/site/siteFront/registerNewUser", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(err);
        return err.response.data._messages[0].text;
      });

    return result;
  },

  validateEmail: async (params: { email: string; emailToken: string }) => {
    const result = await api
      .post("/site/siteFront/validateEmail", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((err) => {
        return err.response.data.contextMessages[0].messages[0].text;
      });

    return result;
  },

  sendNewEmail: async (params: { email: string }) => {
    const result = await api
      .post("/site/siteFront/sendNewEmail", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(err);
        return err.response.data.contextMessages[0].messages[0].text;
      });

    return result;
  },

  registerNewsletter: async (params: { name: string; email: string }) => {
    const result = await api
      .post("/site/siteFront/registerNewsletter", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(err);
        return err.response.data.contextMessages[0].messages[0].text;
      });

    return result;
  },

  login: async (email: string, password: string) => {
    const result = await axios
      .get(process.env.REACT_APP_SYDLE_ONE_ENDPOINT + "site/sys/auth/signIn", {
        auth: {
          username: email,
          password: password,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return {
            status: response.status,
            user: {
              id: response.data.code,
              name: response.data.name,
              login: response.data.login,
            },
            accessToken: response.data.accessToken,
          };
        }
      })
      .catch((err) => {
        return err;
      });

    return result;
  },

  validateToken: async (token: string) => {
    // O projeto não possui a implementação dessa função até então, para contornar essa limitação de forma temporária, utilizaremos a informação salva do usuário no localStorage, ao invés de utilizar a informação estática utilizada anteriormente
    const storageUser = localStorage.getItem("@bebacknow:user");
    const parsedUser = storageUser ? JSON.parse(storageUser) : null;

    return {
      user: parsedUser ? parsedUser : null
    }


    // TODO mandar o token e recebe o usuário
    // return {
    //   user: { id: 3, name: "José", email: "jose@email.com" },
    // };
    const result = await api
      .post("/site/siteFront/validateToken", token)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição validateToken: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });
    return result;
  },

  logout: async () => {
    return { status: true };
    const result = await api
      .post("/site/siteFront/logout")
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((err) => {
        console.log(`Erro ao realizar requisição logout: ${err}`);
        console.log(
          JSON.parse(err.request.responseText).generalMessages[0].text
        );
        return err;
      });

    return result;
  },

  registerNewEventSubscription: async (userId: string, eventId: string) => {
    const result = await api
      .post("/site/siteFront/registerNewEventSubscription", { userId, eventId })
      .then((response) => {
        if (response.status === 200) {
          return response.data.data;
        }
      })
      .catch((err) => {
        return err;
      });

    return result;
  },
};

export default sendRequest;