import styled from "styled-components";

export const Section = styled.section`
  	display: flex;
  	align-items: flex-start;
  	justify-content: space-around;
	padding: 60px;

	background-image: ${(props) => `url(${props.backgroundImageUrl})`};
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;

	@media (max-width: 768px) {
		padding: 20px;
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const Image = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 60%;
	}

	@media (max-width: 768px) {
		img {
			max-width: 75%;
		}
	}
	@media (max-width: 480px) {
		img {
			max-width: 100%;
		}
	}
`;