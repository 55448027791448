import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 370px;
  margin: 1rem;
  border-radius: 0 0 10px 10px;
  cursor: pointer;

  img {
    flex-shrink: 1;
    filter: grayscale(1);
    transition: all 0.3s;
  }

  &:hover img {
    filter: grayscale(0);
  }

  &:hover div::before {
    transform: scaleY(1);
  }

  &:hover div p,
  &:hover div svg {
    color: #fff;
  }

  @media (max-width: 1420px) {
    width: 300px;
    height: 330px;
  }
`;


export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  flex-grow: 1;
  border-radius: 0 0 10px 10px;
  background-color: #c2d1e6;
  color: var(--dark-grey);
  transition: all 0.3s;
  overflow: hidden;

  p,
  svg {
    font-size: 1.5rem;
    font-weight: bold;
    position: relative;
    z-index: 2;
    transition: all 0.3s;
    margin-bottom: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(0);
    transform-origin: top;
    transition-timing-function: linear;
    transition: all 0.3s;
    background-color: var(--light-orange);
  }
`;