import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { SyProgressBar } from "@sydle/sydle-ui-react";
import { FileUploader } from "./FileUploader";
import {
  Container,
  Content,
  Info,
  PhotoContainer,
  StyledSyButton,
  StyledSyInput,
  StyledSySelect,
  StyledSySwitch,
  InputContainer,
  SocialMediaContainer,
} from "./style";
import File from "./File";
import sendRequest from "../../services/sendRequest";
import validate from "../../utils/validator";
import CircularProgress from '@mui/material/CircularProgress';
import { MdClose, MdEdit, MdSave, MdDelete } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import AuthContext from "../../contexts/Auth/AuthContext";

type Object = {
  id: string;
  name: string;
};

interface formOptions {
  countries: Object[];
  brazilianStates: Object[];
  cities: Object[];
  yearsOfExperience: string[];
  yearsOfCareerBreak: string[];
  whereDidYouFindUs: Object[];
  highestEducationalQualification: Object[];
  reasonForCareerBreak: Object[];
}

interface formOptions2 {
  cities: Object[];
}

interface IsValidProps {
  name: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  country: string | undefined;
  brazilianState: string | undefined;
  city: string | undefined;
  profession: string | undefined;
  yearsOfExperience: string | undefined;
  yearsOfCareerBreak: string | undefined;
  whereDidYouFindUs: string | undefined;
  highestEducationalQualification: string | undefined;
  reasonForCareerBreak: string | undefined;
  profilePicture: string | undefined;
}

interface highestEducationalQualificationList {
  id: string | undefined;
  name: string | undefined;
  order: string | undefined;
}

export function Profile() {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [country, setCountry] = useState<string | string[]>("");
  const [brazilianState, setBrazilianState] = useState<string | string[]>("");
  const [city, setCity] = useState<string | string[]>("");
  const [region, setRegion] = useState<string>("");
  const [profession, setProfession] = useState<string>("");
  const [yearsOfExperience, setYearsOfExperience] = useState<string | string[]>(
    ""
  );
  const [yearsOfCareerBreak, setYearsOfCareerBreak] = useState<
    string | string[]
  >("");
  const [whereDidYouFindUs, setWhereDidYouFindUs] = useState<string | string[]>(
    ""
  );
  const [highestEducationalQualification, setHighestEducationalQualification] =
    useState<string | string[]>("");
  const [reasonForCareerBreak, setReasonForCareerBreak] = useState<
    string | string[]
  >("");

  const [facebook, setFacebook] = useState<string>("");
  const [facebookFocus, setFacebookFocus] = useState<boolean>(false);

  const [instagram, setInstagram] = useState<string>("");
  const [instagramFocus, setInstagramFocus] = useState<boolean>(false);

  const [googlePlus, setGooglePlus] = useState<string>("");
  const [googlePlusFocus, setGooglePlusFocus] = useState<boolean>(false);

  const [linkedin, setLinkedin] = useState<string>("");
  const [linkedinFocus, setLinkedinFocus] = useState<boolean>(false);

  const [formOptions, setFormOptions] = useState<formOptions>({
    countries: [],
    brazilianStates: [],
    cities: [],
    yearsOfExperience: ["1-4", "5-9", "10-14", "15+"],
    yearsOfCareerBreak: ["Não", "Sim"],
    whereDidYouFindUs: [],
    highestEducationalQualification: [],
    reasonForCareerBreak: [],
  });

  const [
    highestEducationalQualificationList,
    setHighestEducationalQualificationList,
  ] = useState<highestEducationalQualificationList[]>([]);

  const [formOptions2, setFormOptions2] = useState<formOptions2>({
    cities: [],
  });

  const [file, setFile] = useState<string | ArrayBuffer | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [profilePicture, setProfilePicture] = useState("");
  const [profilePictureBackup, setProfilePictureBackup] = useState("");
  const authContext = useContext(AuthContext);
  const [formBackup, setFormBackup] = useState<any>();
  const [receiveNewsletter, setReceiveNewsletter] = useState(false);

  const [errors, setErrors] = useState<IsValidProps>({
    name: undefined,
    email: undefined,
    phone: undefined,
    country: undefined,
    brazilianState: undefined,
    city: undefined,
    profession: undefined,
    yearsOfExperience: undefined,
    yearsOfCareerBreak: undefined,
    whereDidYouFindUs: undefined,
    highestEducationalQualification: undefined,
    reasonForCareerBreak: undefined,
    profilePicture: undefined,
  });

  // garante que a página vá para o topo
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    async function fetchProfileData() {
      const user = JSON.parse(localStorage.getItem("@bebacknow:user")!);
      setUserId(user.id);
      setIsLoading(false);

      getEducationLevels();

      await sendRequest
        .getCountries({ stateId: brazilianState })
        .then((response) => {
          setFormOptions({
            ...formOptions,
            countries: response.countries.sort((a: Object, b: Object) =>
              a.name.localeCompare(b.name)
            ),
            brazilianStates: response.brazilianStates.sort(
              (a: Object, b: Object) => a.name.localeCompare(b.name)
            ),
            whereDidYouFindUs: response.whereDidYouFindUs,
            reasonForCareerBreak: response.reasonForCareerBreak,
          });
        });

      await sendRequest
        .getProfile({ id: user.id })
        .then((response) => {
          setName(response.name);
          setEmail(response.email);
          setPhone(response.phone);
          setCountry(response.country);
          setBrazilianState(response.brazilianState);
          setCity(response.city);
          setRegion(response.region);
          setProfession(response.profession);
          setYearsOfExperience(response.yearsOfExperience);
          setYearsOfCareerBreak(response.yearsOfCareerBreak);
          setWhereDidYouFindUs(response.whereDidYouFindUs);
          setHighestEducationalQualification(response.highestEducationalQualification);
          setReasonForCareerBreak(response.reasonForCareerBreak);
          setProfilePicture(response.profilePic?.base64);
          setReceiveNewsletter(response.receiveNewsletter);
          setFile(response.resume?.base64);
          setFileName(response.resume?.fileName);

          if (
            response &&
            response.socialNetworks &&
            response.socialNetworks.length > 0
          ) {
            response.socialNetworks.forEach((hit: any) => {
              if (hit.socialNetwork._id === "5c5c7a7562edb929fe76375b")
                setFacebook(hit.url);
              if (hit.socialNetwork._id === "5c5c7a8c62edb929fe765618")
                setInstagram(hit.url);
              if (hit.socialNetwork._id === "5c64714c62edb9ae21470cc5")
                setLinkedin(hit.url);
              if (hit.socialNetwork._id === "643e8a3bb95cb14760d6fed1")
                setGooglePlus(hit.url);
            });
          }
        })
        .catch((err) => {
          console.log(`Error trying to fetch profileData: ${err}`);
        });
    }

    fetchProfileData();
    setIsLoading(true);
  }, []);

  useEffect(() => {
    async function fetchCities() {
      await sendRequest
        .getCountries({ stateId: brazilianState })
        .then((res) => {
          setFormOptions2({ ...formOptions2, cities: res.cities });
          setIsLoading(false);
        });
    }

    if (brazilianState !== "") {
      setIsLoading(true);
      fetchCities();
    }
  }, [brazilianState]);

  async function getEducationLevels() {
    await sendRequest.getEducationLevels({}).then((response) => {
      setHighestEducationalQualificationList(response);
    });
  }

  function verifyFields() {
    let errorArray = [];

    if (!email) errorArray.push("Email");
    if (!name) errorArray.push("Nome");
    if (!country) errorArray.push("País")
    if (!brazilianState) errorArray.push("Estado");
    if (!city) errorArray.push("Cidade");
    if (!profession) errorArray.push("Profissão");
    if (!highestEducationalQualification) errorArray.push("Maior qualificação educacional");
    if (!whereDidYouFindUs) errorArray.push("Onde nos encontrou");

    if (errorArray.length > 0) {
      const errorMessage = `Preencha o(s) campo(s): ${errorArray.join(', ')}`;
      
      toast.error(errorMessage, {
        toastId: "profile-04-error",
      });

      return false;
    }

    return true;
  }

  async function handleSaveInfo() {
    const fieldStatus = verifyFields();
    if (!fieldStatus) return;

    setIsLoading(true);
    setProfilePictureBackup("");
    setFormBackup(null);

    let updateErrors = { ...errors };

    updateErrors.email = validate.register.email(email);
    updateErrors.name = validate.register.name(name);
    updateErrors.country = validate.register.country(country);
    updateErrors.brazilianState = validate.register.brazilianState(brazilianState);
    updateErrors.city = validate.register.city(city);
    updateErrors.profession = validate.register.profession(profession);
    updateErrors.whereDidYouFindUs = validate.register.whereDidYouFindUs(whereDidYouFindUs);
    updateErrors.highestEducationalQualification = validate.register.highestEducationalQualification(highestEducationalQualification);

    if (
      updateErrors.email ||
      updateErrors.name ||
      updateErrors.country ||
      updateErrors.profession ||
      updateErrors.whereDidYouFindUs ||
      updateErrors.highestEducationalQualification
    ) {
      setErrors(updateErrors);
      setIsLoading(false);
      return;
    } else {
      setErrors(updateErrors);
    }

    let socialNetworks = [];

    if (facebook)
      socialNetworks.push({
        socialNetwork: "5c5c7a7562edb929fe76375b",
        url: facebook,
      });
    if (instagram)
      socialNetworks.push({
        socialNetwork: "5c5c7a8c62edb929fe765618",
        url: instagram,
      });
    if (linkedin)
      socialNetworks.push({
        socialNetwork: "5c64714c62edb9ae21470cc5",
        url: linkedin,
      });
    if (googlePlus)
      socialNetworks.push({
        socialNetwork: "643e8a3bb95cb14760d6fed1",
        url: googlePlus,
      });

    let obj = {
      id: userId,
      email,
      phone,
      name,
      country,
      brazilianState,
      city,
      region,
      profession,
      yearsOfExperience,
      yearsOfCareerBreak,
      whereDidYouFindUs,
      highestEducationalQualification,
      reasonForCareerBreak,
      receiveNewsletter,
      resume: {
        base64: file ? file : null,
        fileName: fileName ? fileName : null,
      },
      profilePic: {
        base64: profilePicture ? profilePicture : null,
        fileName: "profilePicture.png",
      },
      socialNetworks: socialNetworks,
    };

    await toast
      .promise(sendRequest.updateProfile(obj), {
        pending: "Salvando informações",
      })
      .then((response) => {
        if (response.status === 400) {
          toast.error("Não foi possível atualizar as informações", {
            toastId: "profile-01-error",
          });
          toast.error(response.response.data.generalMessages[0].text, {
            toastId: "profile-02-error",
          });
          return;
        }
        if (response.data.error) {
          toast.error(response.data.error, {
            toastId: "profile-03-error",
          });
          return;
        }
        authContext.updateUserPhoto(true);
        toast.success("Informações atualizadas com sucesso!", {
          toastId: "profile-01-success",
        });
      })
      .catch((err) => {
        toast.error(
          "Não foi possível atualizar as informações do perfil: " + String(err),
          {
            toastId: "profile-04-error",
          }
        );
      });

    setIsLoading(false);
    setIsEditing((prevState) => !prevState);
  }

  function handleSelectFile(
    fileAsBase64: string | ArrayBuffer | null,
    fileName: string
  ) {
    setFile(fileAsBase64);
    setFileName(fileName);
  }

  function handleRemoveFile() {
    setFile(null);
  }

  function handleProfilePictureSelect(file: FileList | null) {
    setProfilePictureBackup(profilePictureBackup ? profilePictureBackup : profilePicture);

    const imageToConvert = file![0];

    if (imageToConvert) {
      // FileReader instance
      const fileReader = new FileReader();

      fileReader.onload = function (fileLoadedEvent) {
        const fileAsBase64 = fileLoadedEvent.target!.result;
        if (typeof fileAsBase64 === "string") {
          setProfilePicture(fileAsBase64);
        }
      };

      // convert to base64
      fileReader.readAsDataURL(imageToConvert);
    }
  }

  function createFormBackup() {
    const obj = {
      userId,
      email,
      phone,
      name,
      country,
      brazilianState,
      city,
      region,
      profession,
      yearsOfExperience,
      yearsOfCareerBreak,
      whereDidYouFindUs,
      highestEducationalQualification,
      reasonForCareerBreak,
      receiveNewsletter,
      file,
      fileName,
      facebook,
      instagram,
      googlePlus,
      linkedin
    };

    setFormBackup(obj);
  }

  function applyFormBackup(obj: any) {
		setUserId(obj.userId);
		setEmail(obj.email);
		setPhone(obj.phone);
		setName(obj.name);
		setCountry(obj.country);
		setBrazilianState(obj.brazilianState);
		setCity(obj.city);
		setRegion(obj.region);
		setProfession(obj.profession);
		setYearsOfExperience(obj.yearsOfExperience);
		setYearsOfCareerBreak(obj.yearsOfCareerBreak);
		setWhereDidYouFindUs(obj.whereDidYouFindUs);
		setHighestEducationalQualification(obj.highestEducationalQualification);
		setReasonForCareerBreak(obj.reasonForCareerBreak);
		setReceiveNewsletter(obj.receiveNewsletter);
		setFile(obj.file);
		setFileName(obj.fileName);
		setFacebook(obj.facebook);
		setInstagram(obj.instagram);
		setGooglePlus(obj.googlePlus);
		setLinkedin(obj.linkedin);
	};

  return (
    <>
      <Container>
        <Info>
          Informações completas e precisas são fundamentais para o seu sucesso
        </Info>

        <Content>
          <div className="header">
            {isLoading ? (
              <PhotoContainer isEditing={isEditing}>
                <CircularProgress className="spinner" size={50}/>
              </PhotoContainer>
            ) : (
              <PhotoContainer isEditing={isEditing} src={profilePicture} className={isEditing ? "isEditing" : ""}>
                {(!profilePicture && !isEditing) && (
                  <div className="userIcon">
                    <FaUserCircle
                        color={"#FF7B24"}
                    />
                  </div>
                )}

                <label
                  className="profilePictureLayer"
                  htmlFor="profilePictureInput"
                >
                  <MdEdit size={25}/>
                </label>
                <input
                  id="profilePictureInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    handleProfilePictureSelect(e.target.files);
                    e.target.value = "";
                  }}
                />
                <label
                  className="profilePictureLayer"
                  htmlFor="profilePictureDelete"
                >
                  <MdDelete size={25} />
                </label>
                <input
                  id="profilePictureDelete"
                  onClick={() => {
                    setProfilePictureBackup(profilePictureBackup ? profilePictureBackup : profilePicture);
                    setProfilePicture("");
                  }}
                />
              </PhotoContainer>
            )}

            {isEditing && !isLoading ? (
              <>
                <StyledSyButton onClick={handleSaveInfo}>
                  <MdSave size={20} /> Salvar
                </StyledSyButton>

                <StyledSyButton onClick={() => {
                  setIsEditing((prevState) => !prevState);
                  setProfilePicture(profilePictureBackup ? profilePictureBackup : "");
                  applyFormBackup(formBackup);
                }}>
                  <MdClose size={20} /> Cancelar
                </StyledSyButton>
              </>
            ) : !isLoading ? (
              <StyledSyButton
                onClick={() => {
                  setIsEditing((prevState) => !prevState);
                  setProfilePictureBackup(profilePictureBackup ? profilePictureBackup : profilePicture);
                  createFormBackup();
                }}
              >
                <MdEdit size={20} /> Editar
              </StyledSyButton>
            ) : (
              <>
                <br />
              </>
            )}
          </div>

          <InputContainer>
            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <StyledSyInput
                id="name"
                label="Nome *"
                type="text"
                icon="single-man"
                icon-position="left"
                style={{ marginTop: name ? 20 : 5 }}
                readonly={isEditing ? undefined : true}
                value={name}
                onSyInputDidInput={(e) =>
                  setName((e.target as HTMLInputElement).value!)
                }
                error={errors.name}
              />
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <StyledSyInput
                id="email"
                label="Email *"
                type="email"
                icon="read-email-at"
                icon-position="left"
                readonly={isEditing ? undefined : true}
                value={email}
                style={{ marginTop: email ? 20 : 5 }}
                onSyInputDidInput={(e) =>
                  setEmail((e.target as HTMLInputElement).value!)
                }
                error={errors.email}
              />
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <StyledSyInput
                type="text"
                style={{ marginTop: phone ? 20 : 5 }}
                value={phone}
                icon="mobile-phone-2"
                icon-position="left"
                readonly={isEditing ? undefined : true}
                onSyInputDidInput={(e) => {
                  setPhone((e.target as HTMLInputElement).value);
                }}
                label="Celular"
                mask="(99) 99999-9999"
                minimum-fraction-digits="11"
                error={errors.phone}
              ></StyledSyInput>
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <StyledSyInput
                label="Profissão *"
                icon="tool-box"
                icon-position="left"
                readonly={isEditing ? undefined : true}
                value={profession}
                style={{ marginTop: profession ? 20 : 5 }}
                onSyInputDidInput={(e) =>
                  setProfession((e.target as HTMLInputElement).value!)
                }
                error={errors.profession} // TODO
              />
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <StyledSySelect
                name="country"
                label="País *"
                enableSearch
                disabled={isEditing ? undefined : true}
                value={country}
                onSySelectDidChange={(e) => {
                  setCountry((e.target as HTMLSySelectElement).value!);
                }}
                error={errors.country}
                size={formOptions.countries.length}
              >
                {formOptions.countries.map((hit) => {
                  return <option key={hit.id} value={hit.id}>{hit.name}</option>;
                })}
              </StyledSySelect>
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : country === "590b71c2f5ca53091c89ea4e" ? ( // brazilian id
              <>
                <StyledSySelect
                  label="Estado *"
                  disabled={isEditing ? undefined : true}
                  enableSearch
                  value={brazilianState}
                  onSySelectDidChange={(e) => {
                    setBrazilianState((e.target as HTMLSySelectElement).value!);
                  }}
                  error={errors.brazilianState}
                  size={formOptions.brazilianStates.length}
                >
                  {formOptions.brazilianStates.map((hit) => {
                    return <option key={hit.id} value={hit.id}>{hit.name}</option>;
                  })}
                </StyledSySelect>
                {brazilianState !== "" && (
                  <StyledSySelect
                    label="Cidade *"
                    enableSearch
                    disabled={isEditing ? undefined : true}
                    value={city}
                    onSySelectDidChange={(e) => {
                      setCity((e.target as HTMLSySelectElement).value!);
                    }}
                    error={errors.city}
                  >
                    {formOptions2.cities?.map((hit) => {
                      return <option key={hit.id} value={hit.id}>{hit.name}</option>;
                    })}
                  </StyledSySelect>
                )}
              </>
            ) : (
              <StyledSyInput
                label="Região"
                value={region}
                readonly={isEditing ? undefined : true}
                onSyInputDidInput={(e) =>
                  setRegion((e.target as HTMLInputElement).value)
                }
              />
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <StyledSySelect
                label="Maior qualificação educacional *"
                disabled={isEditing ? undefined : true}
                value={highestEducationalQualification}
                onSySelectDidChange={(e) => {
                  setHighestEducationalQualification(
                    (e.target as HTMLSySelectElement).value!
                  );
                }}
                error={errors.highestEducationalQualification}
              >
                {highestEducationalQualificationList &&
                highestEducationalQualificationList.length > 0
                  ? highestEducationalQualificationList.map((hit) => {
                      return (
                        <option key={hit.id} value={hit.id}>
                          {hit.name}
                        </option>
                      );
                    })
                  : null}
              </StyledSySelect>
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <StyledSySelect
                label="Está em pausa na carreira?"
                disabled={isEditing ? undefined : true}
                onSySelectDidChange={(e) => {
                  setYearsOfCareerBreak((e.target as HTMLSySelectElement).value! === "Sim" ? "Sim" : "Não");
                }}
                value={yearsOfCareerBreak}
                error={errors.yearsOfCareerBreak}
              >
                {formOptions.yearsOfCareerBreak.map((hit, index) => {
                  return (
                    <option key={index} value={hit}>
                      {" "}
                      {hit}
                    </option>
                  );
                })}
              </StyledSySelect>
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : yearsOfCareerBreak === "Sim" && (
              <StyledSySelect
                label="Anos de experiência antes do intervalo"
                disabled={isEditing ? undefined : true}
                onSySelectDidChange={(e) => {
                  setYearsOfExperience(
                    (e.target as HTMLSySelectElement).value!
                  );
                }}
                value={yearsOfExperience}
                error={errors.yearsOfExperience}
              >
                {formOptions.yearsOfExperience.map((hit, index) => {
                  return (
                    <option key={index} value={hit}>
                      {" "}
                      {hit}
                    </option>
                  );
                })}
              </StyledSySelect>
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : yearsOfCareerBreak === "Sim" && (
              <StyledSySelect
                disabled={isEditing ? undefined : true}
                label="Motivo para intervalo de carreira"
                value={reasonForCareerBreak}
                onSySelectDidChange={(e) => {
                  setReasonForCareerBreak(
                    (e.target as HTMLSySelectElement).value!
                  );
                }}
                error={errors.reasonForCareerBreak}
              >
                {formOptions.reasonForCareerBreak.map((hit) => {
                  return (
                    <option key={hit.id} value={hit.id}>
                      {hit.name}
                    </option>
                  );
                })}
              </StyledSySelect>
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <StyledSySelect
                label="Onde nos encontrou *"
                disabled={isEditing ? undefined : true}
                value={whereDidYouFindUs}
                onSySelectDidChange={(e) => {
                  setWhereDidYouFindUs(
                    (e.target as HTMLSySelectElement).value!
                  );
                }}
                error={errors.whereDidYouFindUs}
              >
                {formOptions.whereDidYouFindUs.map((hit) => {
                  return (
                    <option key={hit.id} value={hit.id}>
                      {hit.name}
                    </option>
                  );
                })}
              </StyledSySelect>
            )}

            <SocialMediaContainer>
              {isLoading ? (
                <SyProgressBar
                  value={25}
                  has-background=""
                  style={{
                    width: "48%",
                    height: "30px",
                    borderRadius: 0,
                    marginTop: 40,
                  }}
                  stroke-width={5}
                  type="indeterminate"
                  class="hydrated"
                />
              ) : (
                <StyledSyInput
                  label="Facebook"
                  type="text"
                  icon="social-media-facebook"
                  icon-position="left"
                  style={{
                    marginTop:
                      (facebookFocus || facebook || instagram) && !isLoading
                        ? 0
                        : -20,
                    transition: "margin-top 0.25s",
                  }}
                  onSyInputDidBlur={(e) => {
                    setFacebookFocus(false);
                    setInstagramFocus(false);
                  }}
                  onSyInputDidFocus={(e) => {
                    setFacebookFocus(true);
                    setInstagramFocus(true);
                  }}
                  readonly={isEditing ? undefined : true}
                  value={facebook}
                  onSyInputDidInput={(e) =>
                    setFacebook((e.target as HTMLInputElement).value!)
                  }
                  error={errors.name}
                />
              )}

              {isLoading ? (
                <SyProgressBar
                  value={25}
                  has-background=""
                  style={{
                    width: "48%",
                    height: "30px",
                    borderRadius: 0,
                    marginTop: 40,
                  }}
                  stroke-width={5}
                  type="indeterminate"
                  class="hydrated"
                />
              ) : (
                <StyledSyInput
                  label="Instagram"
                  type="text"
                  icon="social-instagram"
                  icon-position="left"
                  readonly={isEditing ? undefined : true}
                  value={instagram}
                  style={{
                    marginTop:
                      (instagramFocus || instagram || facebook) && !isLoading
                        ? 0
                        : -20,
                    transition: "margin-top 0.25s",
                  }}
                  onSyInputDidBlur={(e) => {
                    setFacebookFocus(false);
                    setInstagramFocus(false);
                  }}
                  onSyInputDidFocus={(e) => {
                    setFacebookFocus(true);
                    setInstagramFocus(true);
                  }}
                  onSyInputDidInput={(e) =>
                    setInstagram((e.target as HTMLInputElement).value!)
                  }
                  error={errors.name}
                />
              )}

              {isLoading ? (
                <SyProgressBar
                  value={25}
                  has-background=""
                  style={{
                    width: "48%",
                    height: "30px",
                    borderRadius: 0,
                    marginTop: 40,
                  }}
                  stroke-width={5}
                  type="indeterminate"
                  class="hydrated"
                />
              ) : (
                <StyledSyInput
                  label="Linkedin"
                  type="text"
                  icon="professional-network-linkedin"
                  icon-position="left"
                  readonly={isEditing ? undefined : true}
                  value={linkedin}
                  style={{
                    marginTop:
                      (linkedinFocus || linkedin || googlePlus) && !isLoading
                        ? 10
                        : -5,
                    transition: "margin-top 0.25s",
                  }}
                  onSyInputDidBlur={(e) => {
                    setLinkedinFocus(false);
                    setGooglePlusFocus(false);
                  }}
                  onSyInputDidFocus={(e) => {
                    setLinkedinFocus(true);
                    setGooglePlusFocus(true);
                  }}
                  onSyInputDidInput={(e) =>
                    setLinkedin((e.target as HTMLInputElement).value!)
                  }
                  error={errors.name}
                />
              )}

              {isLoading ? (
                <SyProgressBar
                  value={25}
                  has-background=""
                  style={{
                    width: "48%",
                    height: "30px",
                    borderRadius: 0,
                    marginTop: 40,
                  }}
                  stroke-width={5}
                  type="indeterminate"
                  class="hydrated"
                />
              ) : (
                <StyledSyInput
                  label="Google +"
                  type="text"
                  icon="social-media-google-plus-2"
                  icon-position="left"
                  readonly={isEditing ? undefined : true}
                  value={googlePlus}
                  style={{
                    marginTop:
                      (googlePlusFocus || linkedin || googlePlus) && !isLoading
                        ? 10
                        : -5,
                    transition: "margin-top 0.25s",
                  }}
                  onSyInputDidBlur={(e) => {
                    setLinkedinFocus(false);
                    setGooglePlusFocus(false);
                  }}
                  onSyInputDidFocus={(e) => {
                    setLinkedinFocus(true);
                    setGooglePlusFocus(true);
                  }}
                  onSyInputDidInput={(e) =>
                    setGooglePlus((e.target as HTMLInputElement).value!)
                  }
                  error={errors.name}
                />
              )}
            </SocialMediaContainer>

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <FileUploader
                file={file}
                onFileSelect={handleSelectFile}
                isEditing={isEditing}
              />
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <File
                file={file}
                fileName={fileName}
                onRemoveFile={handleRemoveFile}
                disabled={!isEditing}
              />
            )}

            {isLoading ? (
              <SyProgressBar
                value={25}
                has-background=""
                style={{
                  width: "48%",
                  height: "30px",
                  borderRadius: 0,
                  marginTop: 40,
                }}
                stroke-width={5}
                type="indeterminate"
                class="hydrated"
              />
            ) : (
              <StyledSySwitch
                checked={receiveNewsletter ? true : undefined}
                label="Receber Newsletter"
                disabled={isEditing ? undefined : true}
                onSySwitchDidChange={() => {
                  setReceiveNewsletter((prevState) => !prevState);
                }}
              />
            )}
          </InputContainer>
        </Content>
      </Container>
    </>
  );
}