import styled from "styled-components";

export const Section = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: ${(props) => `url(${props.backgroundImageUrl})`};
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	overflow: hidden;

	.promotional-text {
		color: #FFF;

		.highlighted {
			color: #FF6600;
		}
	}
`;

export const EbookSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 30px;
	padding: 0 80px 80px;

	@media (max-width: 480px) {
        padding: 0 40px 40px;
    }
`;

export const PromotionalText = styled.div`
	h2 {
		text-align: center;
		font-family: Poppins, sans-serif;
    	font-weight: 600;
		color: ${(props) => props.color};
	}
	
	@media (max-width: 768px) {
		h2 {
            font-size: 1.8rem;
        }
	}
	@media (max-width: 480px) {
        h2 {
            font-size: 1.4rem;
        }
    }
`;

export const EbookContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;

	a {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	@media (max-width: 768px) {
		img {
			width: 250px;
		}
	}
	@media (max-width: 480px) {
		flex-direction: column;

		img {
			width: 70%;
		}
	}
`;

export const SocialMedia = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 40px;

	div {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 2px;

		a {
			font-size: 1.5rem;
			color: ${(props) => props.color};
		}
	}

	@media (max-width: 480px) {
		flex-direction: column;
		gap: 10px;
	}
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: end;
	width: 70%;
	margin: 50px;
	border-radius: 30px;
	overflow: hidden;
	
	background-image: ${(props) => `url(${props.overlayBackgroundUrl})`};
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 110%;

	@media (max-width: 1025px) {
		padding: 20px;
		width: 80%;
	}
	@media (max-width: 480px) {
		margin: 50px 0;
	}
`;

export const FormLine = styled.form`
	display: flex;
	flex: 55%;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 20px;

	a {
		color: ${(props) => props.color.checkboxTexts};
		text-decoration: underline !important;
	}

	label > span {
		color: ${(props) => props.color.checkboxTexts};
		font-weight: 500;
	}

	@media (max-width: 480px) {
		padding: 0;
	}
`;

export const Person = styled.div`
	display: flex;
	flex: 45%;
	justify-content: center;
	align-items: center;
	margin-bottom: -50px;

	img {
		width: 100%;
	}

	@media (min-width: 2000px) {
		img {
			width: 50%;
		}
	}
	@media (min-width: 1500px) and (max-width: 1999px) {
		img {
			width: 70%;
		}
	}
`;

export const Title = styled.div`
	width: 100%;
	
	h3 {
		text-align: center;
		color: ${(props) => props.color};
		text-transform: uppercase;
		font-family: "Poppins", sans-serif;
		font-weight: 700;
		font-size: 3.0rem !important;
	}

	@media (max-width: 480px) {
		h3 {
			font-size: 2.7rem !important;
		}
	}
`;

export const Button = styled.button`
	display: flex;
	justify-content: center;
	margin-bottom: 5%;
	width: ${props => (props.half ? "49" : "100")}%;

	padding: 1rem 1.5rem;
	margin-top: 1rem;
	border-radius: 3px;
	border: 0;
	background: ${(props) => props.bgColor};
	color: ${(props) => props.textColor};
	font-weight: bold;
	font-family: "Poppins", sans-serif;

	cursor: pointer;
	transition: background-color 0.5s;

	&:hover {
		background: ${(props) => props.bgColorOnHover};
		color: ${(props) => props.textColorOnHover};
	}
`;