import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  top: 80px;
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem var(--page-lateral-padding);
`;


export const TextSection = styled.div`
  max-width: 50%;

  p + p {
    color: var(--light-orange);
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  
  @media (max-width: 1024px) {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    margin-top: 4rem;
  }
`;


export const CardsSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 4rem 0;

  width: 100%;
`;