import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 80px;

    .carousel-root {
        width: 100%;

        div {
            cursor: pointer;
        }

        .carousel-item {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            background-size: cover;
            background-position: center;
            height: 600px;
            width: 100%;

            .text-container {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                gap: 25px;
                padding-left: 8%;
                width: 45%;

                h2, p {
                    text-align: left;
                    margin: 0 !important;
                }

                p {
                    font-size: 1.3rem;
                }

                @media (max-width: 768px) {
                    margin: 60px 0;
                }
                @media (max-width: 480px) {
                    align-items: center;
                    padding: 20px;
                    width: 100%;

                    h2, p {
                        text-align: center;
                    }
                }
            }

            @media (max-width: 480px) {
                align-items: end;
            }
        }

        .carousel .control-dots {
            .dot.selected {
                background-color: #ff7b24;
            }

            .dot {
                width: 12px;
                height: 12px;
            }
        }
    }

    .item {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
    }

    p {
        margin-bottom: 0 !important;
    }
`;

export const IntroSection = styled.section`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 90px 110px;
    background-color: #FF7b24;

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: stretch;
        justify-content: start;
    }
    @media (max-width: 1000px) {
        padding: 90px 60px;
    }
    @media (max-width: 480px) {
        padding: 90px 40px;
    }
    @media (max-width: 330px) {
        padding: 90px 20px;
    }
`;

export const IntroImage = styled.img`
    flex: 50%;
    width: 50%;
    border-radius: 148px 0 0 0;
    object-fit: cover;

    @media (max-width: 1200px) {
        width: 100%;
        height: 300px;
        flex: unset;
    }
    @media (max-width: 800px) {
        border-radius: 74px 0 0 0;
        height: 250px;
    }
`;

export const Button = styled.button`
    appearance: none;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    cursor: pointer;

    padding: 15px 30px;
    background-color: #FF7B24;
    color: white;
    border-radius: 7.29px;
    border: 1.46px white solid;
    width: 200px;
    font-weight: 600;

    &:hover {
        background-color: white;
        color: #FF7B24;
        border: 1.46px #FF7B24 solid;
    }
`;

export const IntroTextDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    gap: 20px;
    flex: 50%;
    padding: 40px;
    border-radius: 0 0 148px 0;
    background-color: #FFF;
    
    h3 {
        font-size: 1.7rem !important;
        margin: 0 !important;
    }

    p {
        font-size: 1.2rem !important;
    }

    @media (max-width: 1200px) {
        flex: unset;

        h3 {
            font-size: 1.5rem !important;
        }
    }
    @media (max-width: 480px) {
        border-radius: 0 0 74px 0;
    }
`;

export const VideoSection = styled.section`
    width: 100%;
    padding: 90px 360px;
    background-color: #ff7b24;

    .iframe-container {
        overflow: hidden;
        /* 16:9 aspect ratio */
        padding-top: 56.25%;
        position: relative;
    }

    .iframe-container iframe {
        border: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    h2 {
        text-align: center;
        color: #fff;
        margin-bottom: 2rem !important;
    }
    
    @media (max-width: 1200px) {
        padding: 90px 160px;
    }
    @media (max-width: 768px) {
        padding: 90px 80px;
    }
    @media (max-width: 480px) {
        padding: 90px 40px;
    }
`;

export const CardSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(33.33% - 20px);
    align-items: start;
    justify-content: start;
    max-width: 33%;
    background: white;
    box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.02);
    }

    img {
        width: 100%;
        height: 100%;
        max-height: 250px;
        object-fit: cover;
        border-radius: 15px 15px 0 0;
    }

    div {
        display: inline-flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        gap: 19px;
        padding: 36px;

        p:first-child {
            color: #303030;
            font-size: 1.1rem;
            font-family: Inter;
            font-weight: 600;
            text-transform: uppercase;
            word-wrap: break-word;
        }

        p:nth-child(2) {
            color: #303030;
            font-size: 1rem;
            font-family: Inter;
            font-weight: 400;
            line-height: 30px;
            word-wrap: break-word;
        }
    }

    span {
        color: #FF7b24;
        font-weight: bold;
    }

    @media (max-width: 1000px) {
        flex: 1 1 calc(50% - 20px);
        max-width: 50%;
    }
    @media (max-width: 700px) {
        flex-basis: 100%;
        max-width: unset;
    }
`;

export const EbookSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    gap: 20px;
    padding: 90px 60px;

    ${Card} {
        flex: 1 1 calc(33% - 20px);
        max-width: 33%;

        img {
            height: 250px;
        }

        @media (max-width: 769px) {
            flex: 1 1 calc(50% - 20px);
            max-width: 50%;
        }
        @media (max-width: 600px) {
            flex-basis: 100%;
            max-width: unset;
        }
    }

    h2 {
        color: #FF7B24;
        text-align: center;
    }

    @media (max-width: 1200px) {
        width: 100%;
    }
    @media (max-width: 768px) {
        padding: 90px 40px;
    }
    @media (max-width: 330px) {
        padding: 90px 20px;
    }
`;

export const TestimonialSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 90px 160px;
    width: 100%;

    .carousel-root {
        .carousel-slider {
            border: solid 3px #FF7B24;
            border-radius: 148px 0 148px 0;
            padding: 50px;

            .slide {
                padding: 20px;
            }
    
            @media (max-width: 480px) {
                padding: 20px 40px;
                margin: 0 auto;
                border-radius: 74px 0;
            }
        }
    }

    q {
        font-style: italic;
        font-size: 1rem;
    }

    .author {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 10px;
        margin-top: 1rem;   

        img {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 99px;
            object-fit: cover;
            border: solid 3px #FF7B24;
        }

        .info {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;

            @media (max-width: 480px) {
                flex-direction: column;
                align-items: center;
            }
        }

        @media (max-width: 480px) {
            flex-direction: column;
        }
    }

    @media (max-width: 1200px) {
        padding: 90px 60px;
    }
    @media (max-width: 770px) {
        padding: 90px 40px;
    }
    @media (max-width: 480px) {
        padding: 90px 20px;
    }
    @media (max-width: 330px) {
        padding: 90px 10px;
    }
`;