import { useContext, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import AuthContext from "./contexts/Auth/AuthContext";
import { About } from "./pages/About";
import { Events } from "./pages/Events";
import { Home } from "./pages/HomeNew";
import { Register } from "./pages/Register";
import { Opportunities } from "./pages/Opportunities";
import { Opportunity } from "./pages/Opportunity";
import { Partners } from "./pages/Partners";
import { Talents } from "./pages/Talents";
import { Profile } from "./pages/Profile";
import { Coaches } from "./pages/Coaches";
import { ContentPage } from "./pages/ContentPage";
import { BusinessContact } from "./pages/BusinessContact";
import { ValidateToken } from "./pages/ValidateToken";
// import { Ebook } from "./pages/Ebooks/Ebook";
// import { Ebook2 } from "./pages/Ebooks/Ebook2";
import { Workshop } from "./pages/Workshop";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Academy } from "./pages/Academy";
import { Article } from "./pages/Article";
import { WorkshopRecording } from "./pages/WorkshopRecording";
import { CommunicationOptions } from "./pages/CommunicationOptions";
import { NotFoundPage } from "./pages/NotFoundPage";

interface RoutesProps {
  selectedLanguage: string;
}

export function RoutesComponent({ selectedLanguage }: RoutesProps) {
  const authContext = useContext(AuthContext);
  const storageUser = localStorage.getItem("@bebacknow:user");
  const parsedUser = storageUser ? JSON.parse(storageUser) : null;

  const ExternalRedirect = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
      window.open("https://www.bebacknow.com/blog", "_blank");
      navigate("/");
    }, []);

    return null; // This component doesn't render anything, it just redirects
  };

  return (
    <>
      <Routes>
        {/* <Route path="/" element={<Home language={selectedLanguage} />} /> */}
        <Route path="/" element={<Home />} />
        <Route
          path="/password-recovery"
          element={<Home />}
        />
        <Route path="/talents" element={<Talents />} />
        <Route path="/opportunities" element={<Opportunities />} />
        <Route path="/opportunity/:opportunityId" element={<Opportunity />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/business-contact" element={<BusinessContact />} />
        <Route path="/events" element={<Events />} />
        <Route path="/about" element={<About />} />
        <Route path="/coaches" element={<Coaches />} />
        <Route path="/validate-token" element={<ValidateToken />} />

        <Route path="/content/:contentId" element={<ContentPage />} />

        <Route
          path="/register"
          element={authContext.user ? <Navigate to="/" /> : <Register />}
        />
        <Route
          path="/profile"
          element={authContext.user ? <Profile /> : <Register />}
        />

        {/* <Route path="/ebook2" element={<Ebook2 />} /> */}
        {/* <Route path="/ebook" element={<Ebook />} /> */}
        <Route path="/workshop" element={<Workshop />} />
        <Route path="/academy" element={<Academy />} />
        <Route path="/artigo/:articleSlug" element={parsedUser ? <Article /> : <Academy displayModal={true} />} />
        <Route path="/workshop/:workshopSlug" element={parsedUser ? <WorkshopRecording /> : <Academy displayModal={true} />} />
        <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
        <Route path="/preferencias-de-email" element={<CommunicationOptions />} />
        <Route path="/blog" element={<ExternalRedirect />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}