import { Container, Content, VideoSection, Description } from "./style";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import sendRequest from "../../services/sendRequest";
import MoonLoader from "react-spinners/MoonLoader";

export function WorkshopRecording() {
    const [data, setData] = useState<any>(null);
    const { workshopSlug } = useParams();

    useEffect(() => {
        async function getContent() {
            const payload = {
                workshopSlug: workshopSlug?.replace(/-/g, ' ')
            };

            await sendRequest.getWorkshopRecording(payload).then(workshopData => {
                setData(workshopData.response);
            });
        }

        getContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Content>
                <MoonLoader
                    color={"#ff6600"}
                    loading={!data}
                    size={80}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    cssOverride={{
                        margin: "120px 0",
                    }}
                />
                
                {data && (
                    <>
                        <Description>
                            <h1>Workshop: {data.title}</h1>
                            <div>
                                <span>Realizado em: {data.date}</span>
                            </div>
                            <div>
                                <p>{data.pageDescription}</p>
                            </div>
                        </Description>

                        <VideoSection>
                            <div className="iframe-container">
                                <iframe
                                    src={data.recording}
                                    title="Workshop Video"
                                    allowFullScreen
                                    loading="lazy"
                                    width="100%"
                                    height="100%"
                                ></iframe>
                            </div>
                        </VideoSection>
                    </>
                )}
            </Content>
        </Container>
    );
}