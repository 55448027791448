import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

const GlobalStyle = createGlobalStyle`
  :root {
    --light-orange: #FF7B24;
    --dark-orange: #FF6600;
    --light-grey: #AFAFAF;
    --dark-grey: #313131;

    --sy-accent: #dce0e6;

    --card-unhovered-orange: #FFEEE2;
    --card-hovered-orange: #FFA641;
    --card-unhovered-blue: #BFD4F1;
    --card-hovered-blue: #004980;
    --card-unhovered-violet: #F2CEFF;
    --card-hovered-violet: #B948E1;

    --size-bezel: .5rem;
    --size-radius: 4px;

    --page-lateral-padding: 10rem;
    /* @media(max-width: 1200px) {
      
    } */
    @media(max-width: 768px) {
      --page-lateral-padding: 4rem;
    }

    @media(max-width: 420px) {
      --page-lateral-padding: 2rem;
    }
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }

  // font-size: 16px (Desktop)
  html {
    @media(max-width: 1080px) {
      font-size: 93.75%; // 15px
    }

    @media(max-width: 720px) {
      font-size: 87.5%; // 14px
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-weight: 400;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  p, h2, h3, h4 {
    color: var(--dark-grey);
  }

  a {
    text-decoration: none !important;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }

sy-progress-spinner{
  --sy-accent: #FE5100;
}

/* Estilizando a barra de rolagem */
::-webkit-scrollbar {
  width: 5px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Cor de fundo da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background-color: var(--dark-orange); /* Cor da barra de rolagem */
}

::-webkit-scrollbar-thumb:hover {
  background-color:  var(--light-orange); /* Cor da barra de rolagem ao passar o mouse */
}


`;

export default GlobalStyle;
