import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import sendRequest from "../../services/sendRequest";
import { User } from "../../types/User";


// interface GoogleLoginProps {
//   clientId: string;
//   credential: string;
//   select_by: string;
// }

interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<boolean>;
  googleLogin: (accessToken: string) => Promise<boolean>;
  logout: () => void;
  isAuthModalOpen: boolean;
  setIsAuthModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isUserPhotoUpdated: boolean | null;
  updateUserPhoto: (value: boolean) => void;
}


const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isUserPhotoUpdated, setIsUserPhotoUpdated] = useState<boolean | null>(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);

  useEffect(() => {
    async function validateToken() {
      const storageData = localStorage.getItem('@bebacknow:accessToken');
      if(storageData) {
        const data = await sendRequest.validateToken(storageData);
        if(data.user) {
          setUser(data.user.login);
        }
      }
    }
    validateToken();
  }, []);

  async function login(email: string, password: string) {
    // TODO verificar se o usuário já está logado por meio do accessToken
    // * user usa a funcação "validateToken". Refazer
    if(user) {
      toast.warning(`Você já está logado`);
      return false;
    }
    // TODO -------------------------------------

    const response = await sendRequest.login(email, password);

    if(response.status === 200) {
      toast.success(`Bem vindo(a), ${response.user.name}!`, {
        toastId: "auth-01-success",
      });
      setUser(response.user.login);
      localStorage.setItem("@bebacknow:user", JSON.stringify(response.user));
      setToken(JSON.stringify(response.accessToken));
      return true;
    }

    toast.error("O login não pôde ser concluído. Verifique seu email e senha e tente novamente.", {
      toastId: "auth-1-error",
    });
    return false;
  }
  
  async function googleLogin(accessToken: string) {
    axios.get(process.env.REACT_APP_SYDLE_ONE_ENDPOINT + "site/sys/auth/renewToken", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
        setUser({
          id: response.data.code,
          name: response.data.name,
          email: response.data.login,
        });

        localStorage.setItem("@bebacknow:user", JSON.stringify({
          id: response.data.code,
          login: response.data.login,
          name: response.data.name,
        }));

        setToken(JSON.stringify(response.data.accessToken));
        toast.success(`Bem vindo(a), ${response.data.name}!`, {
          toastId: "profile-02-success",
        });
        return true; 
      })
      .catch(err => {
        toast.error("Não foi possível realizar o login", {
          toastId: "auth-2-error",
        });
        return false;
      })

    return true;
  }

  async function logout() {
    // LOGOUT
    await sendRequest.logout();

    setUser(null);
    setToken('');
    localStorage.setItem("@bebacknow:user", '');
  }

  function setToken(token: string) {
    localStorage.setItem("@bebacknow:accessToken", token);
  }

  function updateUserPhoto(value: boolean) {
    setIsUserPhotoUpdated(value);
  }

  return (
    <AuthContext.Provider value={{ 
      user, 
      login, 
      googleLogin, 
      logout, 
      isAuthModalOpen, 
      setIsAuthModalOpen,
      isUserPhotoUpdated,
      updateUserPhoto
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;