import { SyButton } from "@sydle/sydle-ui-react";
import styled, { css } from "styled-components";

interface ContainerProps {
  isAuthModalOpen: boolean;
}


export const Overlay = styled.div<ContainerProps>`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  visibility: ${props => props.isAuthModalOpen ? 'visible' : 'hidden'};
`;


export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: ${(props) => (props.isAuthModalOpen ? "50%" : "-100%")};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: #ffffff;
  width: 450px;
  padding: 50px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
  transition: 0.5s;

  visibility: ${(props) => (props.isAuthModalOpen ? "visible" : "hidden")};

  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 350px;
    padding: 50px 30px 30px;

    .close {
      top: 20px;
      right: 20px;
    }
  }

  @media(max-width: 420px) {
    width: 300px;
  }
`;


export const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > h3 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0 !important;
    color: var(--light-orange);
  }

  main {
    margin: 2rem 0;
    width: 100%;

    p {
      text-align: start;
      font-size: 1.25rem;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    textarea {
      resize: none;
      width: 100%;
      min-height: 150px;
      padding: 1rem;
      outline: none;
    }
  }
`;


export const Footer = styled.footer`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 0.5rem;
`;

interface StyledSyButtonProps {
  background: string;
  color: string;
}

export const StyledSyButton = styled(SyButton)<StyledSyButtonProps>`
  --sy-button-padding: 1rem;
  --sy-button-font-weight: 500;
  --sy-button-letter-spacing: 1px;
  --sy-button-border-radius: 4px;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

  --sy-button-background-color: ${props => props.background};
  --sy-button-background-color-hover: ${props => props.background};
  --sy-button-background-color-active: ${props => props.background};
  --sy-button-border-color: ${props => props.background};
  --sy-button-border-color-hover: ${props => props.background};
  --sy-button-border-color-active: ${props => props.background};
  --sy-button-color: ${props => props.color};
  --sy-button-color-hover: ${props => props.color};
  --sy-button-color-active: ${props => props.color};

  --sy-button-loading-color: #fff;

  ${(props) =>
    props.loading === true &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  svg {
    margin-right: 0.5rem;
  }
`;