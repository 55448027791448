import { useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import { RoutesComponent } from "./RoutesComponent";
import { Footer } from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { AuthModal } from "./components/AuthModal";

function App() {
  const { pathname } = useLocation();
  const excludeNavbarRoutes = ["/workshop"];
  const excludeFooterRoutes = [...excludeNavbarRoutes, "/register", "/validate-token"];

  const [selectedLanguage, setSelectedLanguage] = useState("pt");
  const [loading, setLoading] = useState(true);

  return (
    <>
      {!excludeNavbarRoutes.includes(pathname) ? (
        <Navbar
          loading={loading}
          setLoading={setLoading}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      ) : null}

      <RoutesComponent selectedLanguage={selectedLanguage} />

      {!excludeFooterRoutes.includes(pathname) ? (
        <Footer loading={loading} selectedLanguage={selectedLanguage} />
      ) : null}

      <AuthModal route="/" />
      <PrivacyPolicy />
    </>
  );
}

export default App;