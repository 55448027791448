import React, { useState } from "react";

import { X } from "phosphor-react";
import { Container, Content, Footer, Overlay, StyledSyButton } from "./style";
import sendRequest from "../../../services/sendRequest";
import { toast } from "react-toastify";

interface ConfirmationModalProps {
  isConfirmationModalOpen: boolean;
  setIsConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEventId: string;
  selectedEventName: string;
  selectedEventDate: string;
}

export function ConfirmationModal({
  isConfirmationModalOpen,
  setIsConfirmationModalOpen,
  selectedEventId,
  selectedEventName,
  selectedEventDate,
}: ConfirmationModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleConfirmSubscription() {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("@bebacknow:user")!);
    await sendRequest
      .registerNewEventSubscription(user.id, selectedEventId)
      .then((res) => {
        console.log(res);
        if (res.response && res.response.status === 400) {
          toast.error(res.response.data.generalMessages[0].text, {
            toastId: "confirmation-modal-01-error",
          });
        } else {
          toast.success(
            // `Sua inscrição foi realizada no evento ${res.event.title}!`
            `Sua inscrição foi realizada!`
            , {
              toastId: "confirmation-modal-01-success",
            });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Você já está inscrito neste evento`, {
          toastId: "confirmation-modal-02-error",
        });
      });
    setIsLoading(false);
    setIsConfirmationModalOpen(false);
  }

  isConfirmationModalOpen
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  return (
    <Overlay isAuthModalOpen={isConfirmationModalOpen}>
      <Container isAuthModalOpen={isConfirmationModalOpen}>
        <div
          className="close"
          onClick={() => setIsConfirmationModalOpen(false)}
        >
          <X />
        </div>
        <Content>
          <h3>Confirmar Inscrição</h3>
          <main>
            <p>{selectedEventName}</p>
            <p>{selectedEventDate}</p>
          </main>
          <Footer>
            <StyledSyButton
              background="var(--light-orange)"
              color="#fff"
              loading={isLoading}
              onClick={handleConfirmSubscription}
            >
              Confirmar
            </StyledSyButton>
            <StyledSyButton
              background="#a0a0a0"
              color="#fff"
              onClick={() => setIsConfirmationModalOpen(false)}
            >
              Cancelar
            </StyledSyButton>
          </Footer>
        </Content>
      </Container>
    </Overlay>
  );
}
