import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 400px;
  background-color: #fff;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    min-height: 200px;
    height: auto;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem 0 4rem var(--page-lateral-padding);

  h2,
  p {
    color: var(--dark-grey);

    @media (max-width: 768px) {
      color: #fff;
    }
  }

  p {
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }

  button {
    grid-column: 2;
    padding: 1rem 1.75rem;
    margin-top: 2rem;
    width: max-content;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 1px;
    border: none;
    color: #fff;
    background-color: var(--light-orange);
    cursor: pointer;
    justify-self: flex-end;

    @media (max-width: 768px) {
      background-color: #fff;
      color: var(--dark-orange);
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    padding: 2rem var(--page-lateral-padding);
    background-color: var(--card-hovered-orange);
  }
`;

interface ImageWrapperProps {
  image: string;
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  width: 100%;
  height: 400px;
  z-index: 1;

  .content {
    height: 100%;
    background-image: ${(props) => `url(${props.image})`};
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, white, 15%, transparent);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(225deg, transparent, var(--light-orange));
    /* : "linear-gradient(135deg, transparent, var(--light-orange))"}; */
    opacity: 0.3;
    z-index: 1;
    mix-blend-mode: screen;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
