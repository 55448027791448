import styled from 'styled-components';

export const Container = styled.div`
  background-color: #edf2f7;
  border: 2px dashed #cbd5e0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
  width: 100%;

  .fileInputs {
    position: relative;
    margin-bottom: 1.5rem;

    input {
      position: relative;
      max-width: 200px;
      height: 50px;
      z-index: 2;
      cursor: pointer;
      opacity: 0;
    }

    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      color: #fff;
      background-color: var(--light-orange);
      font-size: 1.1rem;
      border-radius: 4px;
      border: none;
      outline: none;
      transition: background-color 0.4s;
      box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
    }

    &:hover {
      button {
        background-color: var(--dark-orange);
      }
    }
  }
  .info {
    font-size: 12px
  }
`;