import { SyButton } from "@sydle/sydle-ui-react";
import styled, { css } from "styled-components";

export const Container = styled.div`
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 4rem 10rem;
  background-color: var(--dark-grey);

  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    padding: 4rem 6rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 2rem;
  }
`;

export const NewsletterForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  p {
    color: #fff;
    font-size: 2rem;
  }

  form {
    width: 100%;
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    .input-group {
      input {
        padding: 0.5rem 0.75rem;
        outline: none;
        border: none;
        width: 100%;
        border-radius: 4px;
      }

      .error {
        font-size: 1rem;
        color: red;
      }
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
  }
`;

export const StyledSyButton = styled(SyButton)`
  --sy-button-padding: 1rem 2rem;
  --sy-button-font-weight: 500;
  --sy-button-letter-spacing: 1px;
  --sy-button-border-radius: 5px;

  --sy-button-background-color: var(--light-orange);
  --sy-button-background-color-hover: var(--light-orange);
  --sy-button-background-color-active: var(--light-orange);
  --sy-button-border-color: var(--light-orange);
  --sy-button-border-color-hover: var(--light-orange);
  --sy-button-border-color-active: var(--light-orange);
  --sy-button-color: #fff;
  --sy-button-color-hover: #fff;
  --sy-button-color-active: #fff;

  --sy-button-loading-color: #fff;

  grid-column: 1 / 3;
  width: fit-content;
  margin: 0 auto;

  @media (max-width: 900px) {
    grid-column: 1 / 2;
  }

  &:hover {
    opacity: 0.85;
  }

  &[loading="true"] {
    pointer-events: none;
  }
`;

export const Logo = styled.div`
  font-size: 4rem;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 364px) {
    font-size: 2.5rem;
  }
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 40%;
  gap: 1rem;
  max-width: 50%;
  color: #fff;

  b {
    font-size: 20px;
  }

  p {
    color: #fff;
    margin: 0.5rem 0;
  }

  section {
    min-width: 220px;
  }

  @media (max-width: 1200px) {
    display: flex;
    align-items: flex-start;
    max-width: 100%;

    section {
      flex: 1;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    section {
      flex: 1;
    }
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
  flex: 1;
  margin: 0.5rem 0;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 999px;

  svg {
    color: var(--dark-grey);
    height: 120%;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  position: relative;
  top: 80px;

  width: 100%;
  padding: 50px;
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: var(--dark-grey);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const FooterContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    letter-spacing: 0.8px;
    color: #eeeff3;
    text-decoration: none;
    margin-bottom: 10px;
  }
`;

export const FooterResources = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;

  width: 48%;

  @media (min-width: 2560px) {
    width: 48%;

    img {
      width: 400px;
    }
  }

  @media (min-width: 1000px) {
    width: 48%;

    img {
      width: 400px;
    }
  }

  @media (min-width: 700px) {
    width: 80%;
    margin-bottom: 50px;

    img {
      width: 400px;
    }
  }

  @media (max-width: 699px) {
    width: 100%;
    margin-bottom: 50px;

    img {
      width: 100%;
    }
  }
`;

export const FooterEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 48%;

  @media (min-width: 801px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 80%;
  }
`;

export const Title = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 18px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #eeeff3;
  text-decoration: none;
  text-align: center;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const SubTitle = styled.h4`
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.8px;
  color: #eeeff3;
  text-decoration: none;
  font-weight: 600;

  &:after {
    position: absolute;
    content: "";
    width: 39px;
    height: 2px;
    background: #df5e07;
    left: 0;
    bottom: -6px;
  }
`;

export const FooterResourceLinks = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 10px;

  width: 48%;

  @media (min-width: 2560px) {
    width: 48%;
  }

  @media (min-width: 1000px) {
    width: 48%;
  }

  @media (min-width: 450px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 800px) {
    width: 100%;
    justify-content: center;
    margin: 0 0 20px 30px;
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 200px;

  a {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    letter-spacing: 0.8px;
    color: #eeeff3;
    text-decoration: none;
    margin-bottom: 10px;
  }

  a:hover {
    color: #FF6600
  }

  &:last-child {
    margin-left: 50px;
  }
`;

export const Circle = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid black;
  margin: 0 10px 10px 0;
  text-decoration: none;
`;

export const CircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 450px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
`;

export const InputContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

export const Label = styled.label<{ filled: boolean }>`
  position: absolute;
  top: ${({ filled }) => (filled ? "-16px" : "50%")};
  left: 12px;
  color: ${({ filled }) => (filled ? "#ff6600" : "#666")};
  font-size: ${({ filled }) => (filled ? "12px" : "16px")};
  font-weight: bold;
  background-color: #fff;
  padding: 0 4px;
  transition: all 0.3s ease;
  transform: ${({ filled }) =>
    filled ? "translateY(-100%) scale(0.8)" : "translateY(-50%)"};
`;

export const Input = styled.input`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #ff6600;
  }
`;

export const Button = styled.button`
  width: 100%;
  background-color: #ff6600;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e65c00;
  }
`;

export const Sydle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f2f7ff;
  span {
    margin-right: 10px;
  }
  img {
    height: 10px;
  }
`;
