import styled from "styled-components";

export const InputLine = styled.div`
  width: ${(props) => (props.half ? 49 : 100)}%;

  margin-bottom: 20px;

  .input {
    position: relative;
    width: 100%;
    background: #fff;
    border-radius: 3px;
    font-family: "Poppins", sans-serif;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
      margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: #fff;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
      font-family: "Poppins", sans-serif;
      color: #323238;
    }

    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;

      border: ${(props) => (props.error ? "2px solid #AA0000" : 0)};

      padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
      color: #323238;
      background: transparent;
      border-radius: var(--size-radius);
      font-family: "Poppins", sans-serif;
      outline-style: none;
      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.25rem, -65%) scale(0.8);
          color: #ff6600;
        }
      }
    }
  }

  @media (max-width: 426px) {
    .input {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 376px) {
    .input {
      font-size: 0.8rem;
    }
  }
`;
