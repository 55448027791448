import FormControlLabel from '@mui/material/FormControlLabel';
import { useLocation, useNavigate  } from "react-router-dom";
import { Container, Form, MessageContainer } from "./style";
import sendRequest from "../../services/sendRequest";
import FormControl from '@mui/material/FormControl';
import MoonLoader from "react-spinners/MoonLoader";
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

interface ONEResponse {
    message: string;
    success: boolean;
}

export function CommunicationOptions() {
    const [selectedOption, setSelectedOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<ONEResponse>();
    const [formSent, setFormSent] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userID = queryParams.get('user');

    if (!userID) {
        navigate("/");
    }

    useEffect(() => {
        setIsLoading(true);

        const getPreferences = async() => {
            const payload = {
                id: userID
            }
            const response = await sendRequest.getEmailPreferences(payload);
            
            if (response) {
                setSelectedOption(response.general ? "general" : "specific");
            }

            setIsLoading(false);
        }

        getPreferences();
    }, [userID])

    const handleOptionChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        setFormSent(true);

        const payload = {
            id: userID,
            option: selectedOption
        }
        const response = await sendRequest.updateEmailPreferences(payload);

        if (response.success) {
            setIsLoading(false);
            setData(response)

            return;
        }

        setIsLoading(false);
        setData({
            message: "Não foi possível atualizar as preferências de e-mail. Tente novamente mais tarde.",
            success: false
        })
    };
  
    return (
        <Container>
            <MoonLoader
                color={"#FF7b24"}
                loading={isLoading}
                size={80}
                aria-label="Loading Spinner"
                data-testid="loader"
                cssOverride={{
                    margin: "160px 0",
                }}
            />
            {selectedOption && (
                <>
                    {!formSent && (
                        <Form onSubmit={handleSubmit}>
                            <FormControl className="form">
                                <FormLabel className="radio-buttons-label">Escolha como podemos nos comunicar com você</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        <FormControlLabel 
                                            value="general" 
                                            control={<Radio />} 
                                            label="Receber informações sobre todos os workshops (incluindo aqueles que você não se cadastrou)"
                                            className="radio-button"
                                        />
                                        <FormControlLabel 
                                            value="specific" 
                                            control={<Radio />} 
                                            label="Receber informações apenas sobre os workshops em que você se cadastrou"
                                            className="radio-button"
                                        />
                                    </RadioGroup>
                
                                    <button 
                                        className={selectedOption ? "btn btn-active" : "btn"} 
                                        disabled={selectedOption ? false : true}
                                        type="submit"
                                    >
                                        Enviar
                                    </button>
                            </FormControl>
                        </Form>
                    )}

                    {(formSent && data) && (
                        <MessageContainer>
                            <span>{data.message}</span>
                            <Link
                                to={"/"}
                            >
                                Ir para a página principal
                            </Link>
                        </MessageContainer>
                    )}
                </>
            )}
        </Container>
    );
}