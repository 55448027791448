import React from 'react'
import { Divider } from '../../../components/Divider';
import { Container, ImageWrapper, Text } from './style'

interface AboutBannerProps {
  index: number;
  title: string;
  description: string;
  image: string;
}


export function AboutBanner({index, title, description, image}: AboutBannerProps) {
  return (
    <Container>
      <Text index={index}>
        <h2>{title}</h2>
        <Divider color="orange" />
        <p>{description}</p>
      </Text>
      <ImageWrapper index={index} image={image}>
        <div className="content"></div>
      </ImageWrapper>
    </Container>
  );
}