import { SyAccordionItem } from '@sydle/sydle-ui-react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';


interface ContentProps {
  image?: boolean;
}

export const Content = styled.article<ContentProps>`
  padding: 2rem var(--page-lateral-padding);

  display: flex;
  flex-direction: column;

  h3 {
    margin-top: 1rem !important;
  }

  ${(props) =>
    props.image &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.5rem;
      background-color: var(--card-hovered-orange);

      /* h1, h2, h3, p, span, ul, li {
        color: #f5f5f5;
      } */


      @media(max-width: 1024px) {
        display: flex;
        flex-direction: column;
      }
    `}
`;

interface ImageProps {
  src: string;
}

export const Image = styled.div<ImageProps>`
  background-image: ${(props) => `url(${props.src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 0;
  border-radius: 5px;
  padding-top: 66.64%; /* (img-height / img-width * container-width) */
  /* (853 / 1280 * 100) */
  @media (max-width: 1024px) {
    order: 1;
  }
`;

export const StyledSyAccordionItem = styled(SyAccordionItem)`
  --sy-accordion-item-content-padding: 1rem;
  --sy-accordion-item-header-background-color: var(--card-unhovered-orange);
  --sy-accordion-item-header-background-color-hover: var(--card-hovered-orange);
  --sy-accordion-item-header-background-color-active: var(
    --card-unhovered-orange
  );

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const StyledButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  margin: 0 1.5rem;
  background-color: var(--light-orange);
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  width: 50%;
  margin: 0 auto 4rem;
`;